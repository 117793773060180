/*****************************
  List
*****************************/
ul.list {
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 15px;
    display: flex;
    align-items: start;

    i{
      margin-right: 10px;
      color: $white;
      background-color: $primary;
      border-radius: $border-radius-sm;
      height: 10px;
      width: 10px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

}

ul.list.list-circle {
  li {
    i{
      font-size: 6px;
    }
  }
}
