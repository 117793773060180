
/*Social Icon*/
.social-icon {
  ul {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: inline-flex;
      list-style-type: none;
      float: left;

      &:last-child {
        a {
          margin-right: 0;
        }

      }

      a {
        display: flex;
        font-size: 18px;
        color: $gray-2;
        margin-right: 25px;
        transition: all 0.5s ease;

        &:hover {
          color: $primary;
        }

      }

    }

  }

}

.social-icon.space-right {
  ul {
    li {
      a {
        font-size: 20px;
        margin-right: 50px;
      }

    }

  }

}

/* Social Bg Color */
.social-bg-hover {
  position: relative;
  color: $white;

  &:hover {
    color: $white;
  }
  span {
    position: relative;
  }

}

.btn.facebook-bg{
  &:hover{
    background-color: #5870a3;
    color: $white;
  }
}

.btn.twitter-bg{
  &:hover{
    background-color:#5ec2f7;
    color: $white;
  }
}

.btn.google-bg{
  &:hover{
    background-color: #f64e57;
    color: $white;
  }
}

.btn.instagram-bg{
  &:hover{
    background-color: #f93a90;
    color: $white;
  }
}

.btn.linkedin-bg{
  &:hover{
    background-color: #038fca;
    color: $white;
  }
}

.btn.pinterest-bg{
  &:hover{
    background-color: #fa4b65;
    color: $white;
  }
}

.facebook-bg {
  background-color: #445c8e;
}

.twitter-bg {
  background-color: #43afe9;
}

.google-bg {
  background-color: #dc0510;
}

.instagram-bg {
  background-color: #DD2A7B;
}

.linkedin-bg {
  background-color: #007eb3;
}

.pinterest-bg {
  background-color: #E60023;
}

.social-icon.social-rounded {
  ul {
    li {
      a {
        color: $white;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
        border: 1px solid $white;
        border-radius: $border-radius-lg;
        margin-right: 30px;

        &:hover {
          background: $primary;
          border: 1px solid $primary;
        }

      }

    }

  }

}


/*Section Title*/
.section-title {
  .title {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .description {
    margin-bottom: 35px;
  }

}

.call-box {
  display: flex;
  align-items: center;

  i {
    background-color: $white;
    padding: 15px;
    color: $secondary;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow;
  }

  .call-box-content {
    margin-left: 10px;

    p {
      margin-bottom: 5px;
    }

    span {
      font-weight: 600;
      font-size: 20px;
      font-family: $font-hedding;
    }

  }

}

/*Home Search 02*/
.home-search-02 {
  .input-group.date {
    .input-group-append {
      .input-group-text {
        border-radius: 0;
        border: inherit;
        color: $body-color;
      }

    }

    .form-control {
      border-right: inherit;
      height: 60px;
    }

  }
.select2-container--default {
  .select2-selection--single {
    .select2-selection__arrow{
      &:before{
        color: $body-color;
      }
     }
   }
 }
}

/*Inner Header*/
.header-inner-menu {
  background-position: center;
  background-size: cover;

  .header-inner-title {
    margin: 200px 0px;

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sub-title {
        padding-bottom: 0px;
        margin-bottom: 0;
        span {
          text-transform: capitalize;
          color: $white;
        }
        .breadcrumb {
          margin-bottom: 0;
          .breadcrumb-item+.breadcrumb-item {
            &:before {
              color: $white;
            }
          }
          .breadcrumb-item{
            a{
              color: $white;
              i{
                color:$yellow;
              }
              &:hover{
                color:$yellow;
              }
            }
          }
        }

        &:after {
          background: $white;
        }
      }
    }

    .title {
      font-size: 48px;
      margin-bottom: 0;
    }

  }

}


.perfect-tour .title-border {
  border-bottom: 1px solid $border-color;
  padding-bottom: 40px;
}

.contant-404 {
  h2 {
    font-size: 55px;
    color: $primary;
    font-weight: bold;
  }

}


.breadcrumb-item.active{
  color: $yellow;
}

/* Form */
.form-control {
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  border-color: $border-color;
  transition: all 0.3s ease-in-out;
  color: $body-color;
  border-radius:$border-radius-md;

  &:focus {
    box-shadow: none;
    border-color: $border-color;
  }

}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: $white;
  border: 1px solid $gray-2;
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0;
  border: 2px solid $gray-2;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=!string!width=!string!height=!string!viewBox=!string!%3e%3cpath fill=!string!d=!string!/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=!string!width=!string!height=!string!viewBox=!string!%3e%3ccircle r=!string!fill=!string!/%3e%3c/svg%3e");
}

textarea.form-control {
  height: auto;
  border-radius:20px;
}

.custom-control-input {
  &:checked {
    ~ {
      .custom-control-label {
        &:before {
          background: $primary;
          border-color: $primary;
        }

      }

    }

  }

}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: $primary;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: $primary;
  border-color: $primary;
}



/*Home 03*/
.travel-section{
  margin-top: -110px;
  padding-top: 180px;
  padding-bottom: 100px;
}
.video-section{
  padding-bottom: 180px;
  .video-box{
    display: flex;
    justify-content: center;
    .play-btn{
      position: relative;
      i{
        position: relative;
        display: flex;
        flex: 0 0 90px;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 90px;
        border-radius: $border-radius-lg;
        background-color: $white;
        color: $primary;
        font-size: 25px;
        transition: all 0.3s ease-in-out;
        z-index: 99;

        &:after{
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 90px;
          height: 90px;
          background: $white;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite;
        }
      }
    }
  }

  .video-section-conten{
    position: relative;
    margin-top: 50px;
    text-align: center;
    p{
      font-size: 18px;
      color: $white;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-family:$font-hedding;
    }
    h2{
      font-size: 50px;
      font-weight: 600;
      color: $white;
    }
  }
}

.our-destination{
  padding-bottom: 180px;
}

.testimonial-section{
  margin-top: -90px;
  padding-bottom: 100px;
  .testimonial-box{
    background-color: $white;
    box-shadow: $box-shadow;
    padding-top:0px;
    padding-bottom: 0px;
    border-radius: $border-radius;
    position: relative;
    z-index: 9;
    .testimonial.testimonial-style-2{
      background-color: inherit;
      box-shadow: inherit;
      margin-bottom: 0px;
    }
  }
  .owl-carousel{
    .owl-nav{
      position: absolute;
      right: 40px;
      width: 100px;
      display: flex;
      bottom: 40px;
      button{
        flex:0 0 50px;
      }
    }
  }
  
}

.blog-section{
  padding-top: 100px;
  padding-bottom: 70px;
}

/*About us*/
 .about-02{
  background-repeat: no-repeat;
  background-size: cover;
 }
.about-img {
  position: relative;
  display: block;
  padding: 0px 40px 140px 120px;

.title-travel {
    text-transform: uppercase;
    font-weight: 600;
    transform: rotate(-90deg);
    display: inline-block;
    position: absolute;
    opacity: 0.30;
    left: 0px;
    top: 100px;
    font-size: 34px;
    color: $secondary;
  }

  .img-01 {
    border-radius: $border-radius;
    background-color: $white;
    padding: 12px;
    box-shadow: $box-shadow;

    img {
      border-radius: 0;
    }

  }

  .img-02 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-radius: $border-radius;
    background-color: $white;
    padding: 12px;
    box-shadow: $box-shadow;
  }

  .about-video{
    position: absolute;
    width: 150px;
    right: 80px;
    bottom: 20px;
    border-radius: $border-radius;
    background-color: $white;
    padding: 5px;
    box-shadow: $box-shadow;

    a {
      position: relative;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      
      i{
        position: absolute;
        display: flex;
        flex: 0 0 40px;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: $border-radius-lg;
        background-color: $white;
        transition: all 0.3s ease-in-out;
        z-index: 99;

        &:after{
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 50px;
          height: 50px;
          background: $white;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite;
        }

      }
    }
  }



  .feature-categories-2 {
    width: 160px;
    box-shadow: $box-shadow;
    box-shadow: $box-shadow-inset;
    border-radius: 0;
    position: absolute;
    background-color: $white;
    right: 60px;
    bottom: -75px;
    padding: 15px;
    margin: 0 auto;
    text-align: center;

    .feature-categories {
      padding: 0px 0px 10px;

      .title {
        font-size: 16px;
      }

    }

  }

  .owl-carousel {
    .owl-dots {
      margin-top: 0;
      text-align: center;

      button.owl-dot {
        span {
          width: 6px;
          height: 6px;
          border-radius: 0;
          margin: 0 5px;
        }

      }

    }

  }

}

.about {
  position: relative;
  text-align: center;

  .img-02 {
    position: absolute;
    left: 20px;
    bottom: -40px;
  }

  .shape {
    position: absolute;
    right: 40px;
    bottom: -65px;
    z-index: -1;
  }

}

.map-bg-img {
  position: absolute;
  right: 100px;
  top: 0;
  width: 100%;
  height: 100%;
}

/*contact us*/
.social-info {
  h6 {
    padding: 30px 0;
    font-weight: 600;
  }

  .contact-info {
    ul {
      li {
        padding-bottom: 30px;

        img {
          height: 36px;
          width: 36px;
          color: $white;
        }

      }

    }

  }

  .social-icon {
    margin-top: 10px;

    ul {
      li {
        a {
          color: $white;

          &:hover {
            color: $gray-2;
          }

        }

      }

    }

  }

}

.message-info {
  padding: 40px 30px;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  .title{
    font-weight: 600;
  }

  .social-icon {
    margin-top: 10px;

    ul {
      li {
        a{
          margin-right: 10px;
          height: 40px;
          width: 40px;
          background-color: $primary;
          box-shadow: $box-shadow;
          border-radius: $border-radius-lg;
          transition: all 0.3s ease;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $white;
          
         &:hover{
            background-color: $secondary;
            color: $yellow;
          }
        }

      }

    }

  }
  
}

.map{
  height: 650px;
}

.contact-form {
  border-top-right-radius:inherit !important;
  border-bottom-right-radius:inherit !important;
}


  .form-inline {
    position: relative;

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 14px 40px 14px;
      height: 59px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

  }

.search {
  ul {
    li {
      a {
        color: $black;
        display: flex;
        &:hover {
          color: $primary;
        }
        .icon{
          z-index: -1;
        }
      }
    }
  }
}

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $gray-1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999;

}

#search.open {
  opacity: 1;
  visibility:visible ;
}

#search input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 50%;
  color: $gray-2;
  background: $gray-1;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  border: $border-color;
  border-radius: 0;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  text-transform: capitalize;
}

#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
}

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: $secondary;
  color: $white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  font-size: 24px;
  border-radius: $border-radius-lg;
  border:transparent;
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color:$primary;
  }
}

/*Map Listing*/

.map-listing {
  height: 680px;
  position: relative;

  .contant {
    .map-img {
      width: 160px;
      height: auto;
      background-color: $white;
      padding: 10px;
      box-shadow: $box-shadow;
      margin-bottom: 15px;
      border-radius: 0;
      position: relative;
      z-index: 9;

      img{
        border-radius: 0;
      }
    }

    i {
      font-size: 24px;
      color: $secondary;
    }
    .icon {
      justify-content: center;
      text-align: center;
      cursor: pointer;
      &:hover {
        .map-img {
          opacity: 1;
        }
      }
      .map-img {
        opacity: 0;
        transition: all 0.3s ease-out 0s;
        cursor: pointer;
      }
    }
  }
  .map-icon.icon-01 {
    position: absolute;
    top: -15%;
    left: 10%;
    
  }
  .map-icon.icon-02 {
    position: absolute;
    top: 27%;
    left: 47%;
  }
  .map-icon.icon-03 {
    position: absolute;
    top: 32%;
    left: 28%;
  }
  .map-icon.icon-04 {
    position: absolute;
    top: 12%;
    left: 61%;
  }
  .map-icon.icon-05 {
    position: absolute;
    top: 43%;
    right: 6%;
  }

  .map-icon.icon-06 {
    position: absolute;
    top: 8%;
    right: 15%;
  }

}

/*Blockquote*/
blockquote{
  box-shadow: $box-shadow;
  background: $white;
  border-radius: 0;
  padding: 25px 30px;
  position: relative;
  z-index: 2;
  margin-bottom: 24px;
  i{
    position: absolute;
    z-index: -1;
    width: 70px;
    opacity: 0.2;
    top: 0;
  }
}


@media (max-width:1199px) {
  .header-inner-menu{
    .header-inner-title {
      margin: 150px 0px;
    }
  } 
  .social-icon.space-right {
    ul {
      li {
        a {
          margin-right: 30px;
        }
      }
    }
  }

  .about-img {
    .feature-categories-2 {
      right: 0;
    }
  }

  .about {
    .shape {
      right: 0;
    }
    .img-02 {
      left: 0;
    }
  }

  .map-listing {
    height: 540px;
    .map-icon.icon-01 {
      top: -25%;
      left: 7%;
    }
    .map-icon.icon-02 {
      top: 20%;
      left: 47%;
    }
    .map-icon.icon-03 {
      top: 27%;
      left: 25%;
    }
    .map-icon.icon-04 {
      top: 12%;
      left: 62%;
    }
    .map-icon.icon-05 {
      top: 37%;
      right: 5%;
    }
    .map-icon.icon-06 {
      top: 5%;
      right: 14%;
    }

    .contant{
      .map-img{
        width: 140px;
      }
    }
  }

  .contant-404 {
    h2 {
      font-size: 48px;
    }
  }

  .destination-section {
    padding-bottom: 80px;
  }

}

@media (max-width:991px) {

  .header-inner-menu{
    .header-inner-title{
      margin: 100px 0px;
      .section-title{
        display: block;
        text-align: center;
        .title{
          margin-bottom: 20px;
        }
        .sub-title{
          justify-content: center;
          &:before{
            left:50%;
            transform: translateX(-50%);
          }
          &:after{
            left:50%;
            transform: translateX(-50%);
          }

          .breadcrumb{
            justify-content: center;
          }
        }
      }
    }
  }  

  .about-img {
    margin-top: 50px;

    .about-video{
      width: 250px;
    }
  }

  .about {
    margin-bottom: 65px;
  }

    .map-listing {
     height: 400px;
      .contant {
        .map-img{
           width: 100px;
           p{
            display: none;
           }
        }
      }
      .map-icon.icon-01 {
        top: -18%;
        left: 10%;
      }
      
      .map-icon.icon-03 {
        top: 30%;
      }
      .map-icon.icon-04 {
        top: 17%;
        left: 62%;
      }
      .map-icon.icon-05 {
        top: 43%;
      }

      .map-icon.icon-06 {
        top: -2%;
        right: 10%;
      }
  }


  .contact-form{
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;
  }

  .perfect-tour{

    .slider{
      margin-right: 0;
    }

  }

  .blog-section{
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .events-content{
    .map{
      height: 300px !important;
    }
  }

  .our-destination {
    padding-bottom: 150px;
  }

  .destination-section {
    padding-bottom: 60px;
  }

  .travel-section{
    padding-bottom: 60px;
    margin-top: -260px;
    padding-top: 320px;
  }

  .video-section{
    .video-section-conten{
      h2{
        font-size: 40px;
      }
    }
  } 

  .map{
    height: 450px !important;
  }


   

}

@media (max-width:767px) {

    .map-listing {
     height: 300px;
      .contant {
        .map-img{
           width: 80px;
           padding: 5px;
           margin-bottom: 10px;
           h6{
            font-size: 16px;
           }
           p{
            display: none;
           }
        }
      }
      .map-icon.icon-01 {
        top: -25%;
        left: 7%;
      }
      .map-icon.icon-02 {
        top: 10%;
        left: 45%;
      }
      .map-icon.icon-03 {
        top: 25%;
      }
      .map-icon.icon-04 {
        top: 12%;
      }
      .map-icon.icon-05 {
        top: 40%;
        right: 3%;
      }

      .map-icon.icon-06 {
        top: 7%;
        right: 13%;
      }
  }


  .perfect-tour{
    .slider{
      .owl-nav{
        width: 100%;
        position: absolute;
        top: 20%;
        width: 100%;
        right: 0;
        .owl-next{
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .header-inner-menu  {
    .header-inner-title{
      margin: 160px 0px;
      .title{
        font-size: 36px;
      }
    }
  }

  .contant-404 {
    h2 {
      font-size: 38px;
    }
  }

  .blog-section{
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .about-img {
    .about-video{
      width: 140px;
      right: 40px;
    }

    .title-travel{
      font-size: 24px;
    }
  }

  .our-destination {
    padding-bottom: 120px;
  }

  .destination-section {
    padding-bottom: 50px;
  }

  .travel-section{
    padding-bottom: 50px;
    margin-top: 0px;
    padding-top: 50px;
  }

  .video-section {
    padding-bottom: 130px;
  }
  .testimonial-section {
    padding-bottom: 50px;
  }

  .map{
    height: 300px !important;
  }

}

@media (max-width:575px) {
  .about-img {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0px;

    .img-02 {
      position: inherit;
      margin-top: 20px;
      img {
        width: 100%;
      }

    }

    .title-travel {
     display: none;
    }

    .about-video{
      margin-top: 20px;
      bottom: inherit;
      position: inherit;
      width: 100%;
      right: inherit;
      padding: 10px;
    }

  }

  .about {
    text-align: center;

    .img-01 {
      width: 90%;
      margin: 0 auto;
    }

    .img-02 {
      width: 75%;
      margin: 0 auto;
    }

  }

  .social-icon.social-rounded {
    ul {
      li {
        a {
          width: 40px;
          height: 40px;
          font-size: 14px;
          margin-right: 15px;
        }

      }

    }

  }

    .map-listing {
      .contant {
        i{
          font-size: 14px;
        }
        .map-img{
           margin-bottom: 5px;
           p{
            display: none;
           }
        }
      }
      .map-icon.icon-02 {
        top: 20%;
        left: 47%;
      }
      .map-icon.icon-03 {
        top: 30%;
      }
      .map-icon.icon-04 {
        top: 14%;
      }
      .map-icon.icon-06 {
        top: 10%;
        right: 13%;
      }
  }

  .header-inner-menu  {
    .header-inner-title{
      margin: 100px 0px;
       .title{
        font-size: 30px;
      }
    }
  }

  .contant-404 {
    h2 {
      font-size: 30px;
    }
  }

  .blog-section{
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .testimonial-section{
    padding:0 15px;
    padding-bottom: 50px;
    .owl-carousel {
      .owl-nav{
        display: none;
      }
    }
  } 

  .counter-section{
    padding:0 15px;
  }

  .video-section{
    .video-section-conten{
      h2{
        font-size: 28px;
      }

      p{
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  } 

}


/* Back To Top */
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 20px;
  color: $dark;
  background-color: $primary;
  border-radius: $border-radius-lg;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: $box-shadow;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 9;
  &:hover {
    color: rgba($yellow, 0);
    transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
    &:before {
      animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
    }
    &:after {
      animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
    }
  }
  &:before {
    position: absolute;
    display: inline-block;
    content: "";
    background: $yellow;
    width: 3px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -2px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  &:after {
    position: absolute;
    display: inline-block;
    content: "";
    width: 15px;
    height: 15px;
    color: $yellow;
    border-top: 3px solid;
    border-left: 3px solid;
    transform: rotateZ(45deg);
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -8px;
    transform: translateY(50px) rotateZ(45deg);
  }
  a{
    color: $yellow;
  }
  &:hover{
      a{
      color: transparent;
    }
  }
}

.back-to-top.auto {
  color: rgba($white, 0);
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
  &:before {
    animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  }
  &:after {
    animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
  }
}


@keyframes tipUp {
  0% {
    transform: translateY(50px) rotateZ(45deg);
  }
  100% {
    transform: translateY(-70px) rotateZ(45deg);
  }
}
@keyframes lineUp {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(-70px);
  }
}
