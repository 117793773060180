/*****************************
  Countdown
*****************************/

.countdown-2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .countdown{
    padding: 10px;
    background-color: $primary;
    color: $white;
    border-radius: $border-radius-sm;
    margin: 5px;
    width: 66px;
    height: 66px;
    span{
      font-size: 18px;
      line-height: 1;
      font-weight: bold;
      text-transform: capitalize;
    }
    p{
      font-size: 12px;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 0;
      color: $yellow;
    }
  }
}

.coming-soon-section{
  p{
    font-size: 18px;
    line-height: 28px;
    color: $white;
    margin-bottom: 0;
  }

  h1{
    font-size: 90px;
    font-family: $font-hedding;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 30px;
    color: $white;
  }

  .countdown-box{
    display: flex;
    align-items: center;
    justify-content: center;
    .countdown{
      padding: 0 30px;
      span{
        font-size: 60px;
        color: $white;
        font-weight: bold;
        font-family: $font-hedding;
      }
      p{
        font-size: 24px;
        font-family: $font-hedding;
        text-transform: capitalize;
        color: $yellow;
      }
    }
  }
  a{
    margin: 45px 0px;
    display: block;
    color: $white;
    &:hover{
      color: $yellow;
    }
  }

  .form-inline{
    position: relative;
    .form-control{
      height: 60px;
      color: $secondary;
      background-color: $white;
      padding-right: 130px;
      padding-left: 30px;
      border-radius: $border-radius-md !important;
    }
    .form-control::-moz-placeholder {
      color: $secondary;
    }

    .form-control::-ms-input-placeholder {
      color: $secondary;
    }

    .form-control::-webkit-input-placeholder {
      color: $secondary;
    }

    .btn{
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 13px 25px;
      height: 50px;
      border-radius: $border-radius-md !important;
      &:hover{
        color: $primary;
        background-color:$yellow;
      }
    }
  }
}


.coming-soon-top{
  .navbar{
   .navbar-brand{
    img{
      height: 70px;
    }
   }
  }
}

@media (max-width:1200px) {

  .coming-soon-section {
    h1{
      font-size: 70px;
    }
    .countdown-box{
      .countdown {
        span{
           font-size: 40px;
        }
      }
    } 
    .countdown-2{
      .countdown{
        span{
          font-size: 60px;
        }
      }
    }
  }
}

@media (max-width:991px) {
  .coming-soon-section {
    h1{
      font-size: 60px;
    }
    .countdown-2{
      .countdown{
        span{
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width:767px) {

  .coming-soon-section {
    h1{
      font-size: 46px;
    }
    .countdown-box{
      .countdown {
        span{
           font-size: 30px;
        }
      }
    } 
    .countdown-2{
      .countdown{
        span{
          font-size: 40px;
        }
      }
    }
  }

}



@media (max-width:575px) {

  .coming-soon-section {
    h1{
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .countdown-box{
      .countdown {
        padding:0px 10px;
        p{
          font-size: 18px;
        }
      }
    }

    a{
      margin: 20px 0px;
    }
    
  }
 
}


/*****************************
  Countdown
*****************************/


@media (max-width:1200px) {
  
}

@media (max-width:991px) {
  
}

@media (max-width:767px) {
  .coming-soon-section {
    h1{
      font-size: 46px;
    }
    .countdown-box{
      .countdown {
        span{
           font-size: 30px;
        }
      }
    } 
  }
}



@media (max-width:575px) {
  .coming-soon-section {
    h1{
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .countdown-box{
      .countdown {
        padding:0px 10px;
        p{
          font-size: 18px;
        }
      }
    }

    a{
      margin: 20px 0px;
    }
  }
}  