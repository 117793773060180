/*****************************
  Testimonial
*****************************/
.testimonial {
  position: relative;
  .testimonial-star-icon {
    ul {
      display: flex;
      padding-left: 0;
      margin-bottom: 20px;

      li {
        list-style-type: none;
        margin-right: 5px;
        font-size: 16px;
        color: $yellow;
      }

    }

  }

  .testimonial-quote-icon {
    width: 105px;
    position: absolute;
    right: 75px;
    top: 0px;
    opacity: 0.1;
  }

  .testimonial-author {
    margin-top: 30px;
    .avatar {
      margin-right: 30px;
      img {
        width: 55px;
        border-radius: 100%;
        border: 4px solid $white;
      }

    }

    .testimonial-name {

      .author-tittle {
        font-family: $font-hedding;
        font-size: 16px;
        font-weight: 500;
      }
      span {
        position: relative;
        padding-left: 15px;
        margin-left: 15px;
        font-size: 14px;

        &:before {
          content: "";
          height: 1px;
          width: 5px;
          background: $white;
          position: absolute;
          left: 0;
          top: 50%
        }

      }

    }

  }

  .testimonial-content {
    margin-bottom: 20px;
    p {
      font-size: 18px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

  }

}


.testimonial-style-1{
  .testimonial{
    text-align: center;
    .testimonial-quote-icon{
      position: inherit;
      margin: 0 auto;
      right: inherit;
    }
    .testimonial-star-icon{
      ul{
        justify-content: center;
        margin-bottom: 0;
      }
    }

    .testimonial-author{
      margin-top: 10px;
      .testimonial-name {
        .author-tittle{
          font-size: 18px;
        }
        span{
          margin-left: 0;
          padding-left: 0;
          &:before{
            content:inherit;
          }
        }
      }
    } 
  }
}

.owl-carousel.testimonial-style-1{
  .owl-dots{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    button.owl-dot{
      span{
        height: 60px;
        width: 60px;
        border-radius: $border-radius-lg;
        background-image: url(../images/avatar/01.jpg);
        background-size: cover;
        background-position: center;
      }
      &:first-child{
        span{
          background-image: url(../images/avatar/02.jpg);
        }
      }
      &:last-child{
        span{
          margin-right: 0px;
          background-image: url(../images/avatar/03.jpg);
        }
      }
    }
    button.owl-dot.active{
      span{
        height: 80px;
        width: 80px;
        border-radius: $border-radius-lg;
        &:before{
          content:inherit;
        }
      }
    }
  }
}

.owl-carousel.testimonial-style-1.align-left{
  .testimonial{
    text-align: left;
    .testimonial-quote-icon{
      margin: 0;
    }
    .testimonial-star-icon{
      ul{
        justify-content: flex-start;
      }
    }
  }
  .owl-dots{
    justify-content: flex-start;
  }
}
 
  .testimonial.testimonial-style-2{
    background: $white;
    padding: 40px;
    box-shadow: $box-shadow;
    margin-bottom: 20px;
    border-radius: $border-radius;

    .testimonial-content {
      p {
        color: $body-color;
        font-size: 16px;
        line-height: 28px;
      }

    }

    .testimonial-quote-icon {
      background: $white;
      right: 20px;
      top: 10px;
      opacity: 0.1;
      width: 68px;
    }

    .testimonial-author {
      margin-top: 0px;
      .avatar {
        img {
          box-shadow: 0px 1px 10px 0px rgba($black,0.1);
        }
      }
      .testimonial-name {
        .author-tittle {
         color: $gray-2;
         &:before {
          background:inherit;
         }

        }

        span {
          &:before {
            background: $black;
          }

        }
      }
    }
  }

