/*****************************
  Tabs
*****************************/

.nav-tab{
  .nav-tabs{
    border: inherit;
    .nav-link{
      font-family: $font-hedding;
      font-weight: 600;
      color: $body-color;
      border: 2px solid $border-color;
      background-color: transparent;
      transition: all 0.3s ease-out 0s;
      padding: 15px 30px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
      border-radius: $border-radius-md;

      &:hover{
        color: $white;
        background-color:$primary;
        border: 2px solid $primary;
      }

      &:last-child{
        margin-right: 0px;
      }
    }
    .nav-link.active{
      border: 2px solid $primary;
      color: $white;
      background-color: $primary;
      &:before{
        content:inherit;
      }
    }
  }
}

.nav.nav-pills{
 margin-right: 0;

 .nav-link {
   padding: 0;
   border-radius: 0;
   background-color: inherit;
 }


}

/*categories-tab*/
.categories-tab{
  .nav-tabs{
    border-bottom:none;
    margin-bottom: 30px;
    .nav-item{
      width: 19%;
      margin-right: 1%;
      &:last-child{
        margin-right: 0%;
      }
      .nav-link{
        padding:0px;
        margin-bottom: 0px;
        border-radius: $border-radius;
        width: 100%;
        position: relative;
        z-index: 2;
        border: none;
        border-bottom: 2px solid transparent;
        box-shadow: $box-shadow;
        background-color: $white;
        .nav-box{
          position: relative;
          z-index: -1;
          padding:15px;
          display: block;
          .icon-box{
            display: block;
            object{
              width: 40px;
              margin-bottom: 0px;
            }
          }
          .title{
            text-transform: uppercase;
            font-size: 16px;
            font-family: $font-hedding;
            font-weight: 600;
            color: $secondary;
          }
        }

        &:focus{
          border: none;
        }
        &:hover{
          border: none;
          border-bottom: 2px solid $primary;
          .title{
            color: $primary;
          }
        }
      }

      .nav-link.active{
        border: none;
        border-bottom: 2px solid $primary;
        .nav-box{
          .title{
            color: $primary;
          }
        }
      }
    }
    .nav-item.show{
      .nav-link{
        border: none;
      }
    }
  }

  .listing-item.listing-item-3{
    align-items: flex-start;
    .listing-image{
      flex: 0 0 150px;
      height: 135px;
      width: 150px;
    }
    .listing-details {
      .listing-title {
        .country-flags{
          margin-right: 10px;
        }
        .title{
          font-size: 22px;
        }
      }
      .listing-rating{
        margin-bottom: 0;
      }
    }
  } 
}

@media (max-width:575px) {
  .categories-tab{
    .listing-item.listing-item-3{
      .listing-image{
        flex: 0 0 100%;
        width: 100%;
        height: auto;
      }
    }
  } 

  .nav-tab {
    .nav-tabs{
      .nav-link {
        padding: 10px 20px;
        margin-right: 5px;
        margin-bottom: 10px;
      }
    } 
  }
}

