/*****************************
    Banner
*****************************/

/*Banner 01*/
.banner {
  padding: 250px 0 280px;
  .form-location {
    position: relative;
    .input-group.date {
      .input-group-text {
        background: $white;
        color: $body-color;
        border: none;
        border-radius: 0;
        border-right: 1px solid $border-color;
      }
    }

    .form-control {
      border-radius: 0;
      height: 60px !important;
      border-radius: inherit;
      border-right: inherit;
      color: $body-color;
      &:placeholder {
        color: $body-color;
      }

    }

  }
  .form-select {
    border-radius: 0;
  }

  .form-select.select-fast {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    height: 60px;
  }

  .select-fast {
    .select2-container--default {
      .select2-selection--single {
        border-bottom-left-radius: $border-radius-md;
        border-top-left-radius: $border-radius-md;
      }

    }

  }

  .select2-container--default {
    .select2-selection--single {
      border-radius: 0;
      height: 60px !important;
      line-height: 30px;

      .select2-selection__rendered {
        color: $body-color;
      }

      .select2-selection__arrow {
        &:before {
          color: $body-color;
        }

      }

    }

  }

  .btn {
    height: 60px;
    line-height: 30px;
    border-radius: 0;
    border-bottom-right-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;

    &:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.home-search {
  .form-control {
    height: 60px !important;
    border-radius: 0;
  }

}

/*Banner 01*/

.banner-1{
  padding: 330px 0 250px;
  position: relative;
  z-index: 2;
  &:before{
    position:absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(5,16,53,0.5) 0%, rgba(5,16,53,0.9) 75%);
    z-index: -1;
  }

  h1 {
    font-size: 70px;
    font-weight: 600;
    line-height: 1.1;
  }


  .home-search{
    margin-top: 60px;
  }
}


/*Banner 02*/
.banner-02 {
  .slider-social {
    display: flex;
    position: absolute;
    z-index: 2;
    bottom: 50px;
    right:20px;
    padding-top:0px;
    text-align: center;
    

    .slider-social-info {
      ul {
        display: flex;
        align-items: center;
        &:before {
          content: "";
          background: $yellow;
          width: 82px;
          height: 2px;
          display: inline-block;

        }

        li {
          margin-left: 15px;
          a {
            color: $white;
            display: inline-block;
            padding: 5px 10px;
            font-size: 20px;
            &:hover {
              color: $yellow;
            }

          }

        }

      }

    }

    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      font-family: $font-hedding;
      color: $white;
      text-transform: uppercase;
    }

  }

  .swiper-slide {
    height: 750px;
    padding-top: 0px;

    .title {
      font-size: 50px;
      line-height: 70px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      margin: 20px 0 35px;
      justify-content: space-between;
    }

  }

  .pagination-button {
    .swiper-button-prev {
      background-image: none;
      display: flex;
      color: $white;
      align-items: center;
      bottom: 50px;
      top: auto;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $yellow;
      }
    }

    .swiper-button-next {
      background-image: none;
      display: flex;
      color: $white;
      align-items: center;
      bottom: 50px;
      top: auto;
      right: auto;
      left: 80px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $yellow;
      }
    }
  }
}


/*Travel-Banner*/
.travel-banner{
  .owl-carousel {
    .owl-stage-outer{
      overflow: visible;
      .owl-item{
        .travel-slider {
          .single-banner-info{
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .owl-item.active{
        opacity: 1;
        .travel-slider {
          .single-banner-info{
            opacity: 1;
          }
        }
      }
    }
  }
   
  .travel-slider{
    height: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 2;
    .single-banner-info{
      width: 100%;
      padding-top: 290px;
      height: 100%;

      p{
        font-size: 22px;
        font-weight: 400;
        text-transform: uppercase;
        font-family: $font-hedding;
        color: $yellow;
      }
      .title{
        margin-bottom: 30px;
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    &:before{
      position:absolute;
      content: "";
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgba(5,16,53,0.5) 0%, rgba(5,16,53,0.5) 75%);
      z-index: -1;
    }
  }

  .slider-social {
    position: absolute;
    width: 100%;
    bottom: 100px;
    z-index: 9;
    left: 0;

    .play-btn{
      position: relative;
      padding-left: 70px;
      margin-left: 50px;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: $font-hedding;
      color: $white;
      i{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex: 0 0 40px;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: $border-radius-lg;
        background-color: $white;
        color: $secondary;
        font-size: 16px;
        transition: all 0.3s ease-in-out;
        z-index: 99;

        &:after{
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 50px;
          height: 50px;
          background: $white;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite;
        }

      }
    }
  }

  .slider-social-info {
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      margin-right: 50px;
      &:before {
        content: "";
        background: $yellow;
        width: 82px;
        height: 2px;
        display: inline-block;

      }

      li {
        margin-left: 15px;
        a {
          color: $white;
          display: inline-block;
          padding: 5px 5px;
          font-size: 18px;
          text-transform: uppercase;
          font-family: $font-hedding;
          font-weight: 600;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  .owl-carousel.owl-nav-center{
    .owl-nav {
      .owl-prev{
        left: 0;
        opacity: 0;
      }
      .owl-next{
        right: 0;
        opacity: 0;
      }
    }
    &:hover{
      .owl-nav {
        .owl-prev{
          left: 30px;
          opacity: 1;
        }
        .owl-next{
          right: 30px;
          opacity: 1;
        }
      }
    }
  } 
}

/*Banner bg*/
.banner-bg-splash {
  h1 {
    font-size: 84px;
    -webkit-text-stroke: 1px $white;
    -webkit-text-fill-color: rgba($white, 0.50);
  }

  .search {
    position: relative;

    a {
      position: absolute;
      right: 0;
      top: 0;
      height: 60px;
      line-height: 30px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .form-control {
      height: 60px;
    }

  }

}

.home-search-02 {
  position: relative;
  z-index: 2;
  .home-search {
    .select2-container--default {
      .select2-selection--single {
        height: 60px;
        line-height: 30px;
        border-radius: $border-radius-md;
      }
    }
    .form-location.datetimepickers{
      .input-group.date{
        .form-control{
          border-top-left-radius: $border-radius-md;
          border-bottom-left-radius: $border-radius-md;
        }
        .input-group-text{
          border-top-right-radius: $border-radius-md;
          border-bottom-right-radius: $border-radius-md;
        }
      } 
    }
  }

}


@media (max-width:1200px) {
  .banner {
    .home-search {
      .btn {
        padding: 15px;
      }
    }
  }

  .banner-02 {
    .swiper-slide {
      height: 650px;
      .title {
        font-size: 40px;
        line-height: 60px;
      }

    }

  }
}

@media (max-width:991px) {
  .banner {
    padding: 170px 0 100px;

    h1 {
      font-size: 60px;
    }
  }

  .banner-02 {
    .swiper-slide {
      height: 500px;

      .title {
        font-size: 30px;
        line-height: 40px;
      }

    }

    .slider-social {
      display: none;
    }

  }

  .travel-banner {
    .travel-slider{
      height: 500px;
      .single-banner-info{
        padding-top: 150px;
        p{
          font-size: 18px;
        }
        h1{
          font-size: 60px;
        }
      }
    }
  } 

}

@media (max-width:767px) {
  .banner {
    h1 {
      font-size: 48px;
    }

    .home-search {
      .form-location.datetimepickers{
        #datetimepicker-01{
          .datetimepicker-input{
            border-top-left-radius: $border-radius-md;
            border-bottom-left-radius: $border-radius-md;
          }
        }
        #datetimepicker-02{
          .input-group-text {
            border-top-right-radius: $border-radius-md;
            border-bottom-right-radius: $border-radius-md;
          }
        }
      }
      .input-group.date {
        .input-group-text {
          border-radius: 0;
        }

      }

      .select-secon {
        .select2-container--default {
          .select2-selection--single {
            border-radius: 0;
            border-top-right-radius: $border-radius-md;
            border-bottom-right-radius: $border-radius-md;
          }

        }

      }
      .form-btn-are{
        .btn{
            border-top-left-radius: $border-radius-md;
            border-bottom-left-radius: $border-radius-md;
        }
      } 
    }

  }

  .banner-1 {
    .home-search {
      margin-top: 30px;
    }
  }

  .banner-02 {
    .swiper-slide {
      position: relative;
      &:before{
        position:absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary,0.6);
      }
    }
  }

  .slider-social-info{
    display: none;
  }

  .travel-banner {
    .travel-slider{
      height: 450px;
      .single-banner-info{
        p{
          font-size: 16px;
        }
        h1{
          font-size: 48px;
        }
      }
    }
    .slider-social {
      .play-btn{
        margin-left: 0px;
        font-size: 16px;
      }
    }
  } 

}

@media (max-width:575px) {
  .banner.banner-1 {
    padding: 140px 0 60px;

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 28px;
    }
  }


  .banner-02 {
    .swiper-slide {
      p {
        font-size: 16px;
        margin-bottom: 20px;
      }

    }

    .pagination-button {
      .swiper-button-prev {
        bottom: 20px;
      }
      .swiper-button-next {
        bottom: 20px;
      }

    }

  }

  .banner {
    .home-search {
      .input-group.date {
        .input-group-text {
          border-radius: $border-radius-md;
        }
      }

      .select2-container--default {
        .select2-selection--single {
          border-radius: $border-radius-md !important;
        }
      }

      .form-location {
        .form-control {
          border-radius: $border-radius !important;
          border-top-left-radius: $border-radius-md !important;
          border-bottom-left-radius: $border-radius-md !important;
        }
      }

      .btn {
        border-radius: $border-radius-md !important;
      }
    }
  }

   .travel-banner {
    .travel-slider{
      .single-banner-info{
        h1{
          font-size: 36px;
        }
      }
    }
  } 
}

@media (max-width:479px) {
   .travel-banner {
    .travel-slider{
      height: 350px;
      .single-banner-info{
         padding-top: 130px;
        h1{
          font-size: 30px;
        }
      }
    }
    .slider-social{
      bottom: 50px;
    }
  } 
}
