/*

Template: Xtrip - Tour & Travel Hotel Booking HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/
/*==============================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Accordion
    - Blog
    - Button
		- Client Logo
		- Countdown
    - Counter
		- Destination
		- Feature Box
		- Form
		- List
		- Listing
		- Owl Carousel
		- Portfolio
		- Pricing
		- Progress Bar
		- Range Slider
    - Select
    - Sidebar
    - Tabs
    - Team
    - Testimonial
    - Video
 :: Header
   - Header default
   - Header transparent
 :: Banner
   - Banner 01
   - Banner 02
   - Banner 03
   - Banner bg
 :: Layout
   - Social Icon
   - Section Title
   - Home Search 02
   - Inner Header
   - About us
   - Map Listing
 :: Sidebar
   - sidebar
 :: Blog
   - Blog
   - Blog Style Vertical
   - Blog detail
 :: Shop
   - Product
   - Product Detail
    - Commentlist
    - Checkout
    - Cart
    - Shop Category
 :: Not Found
 :: Footer

======================================
[ End table content ]
======================================*/
/*****************************
    Helper Classes
*****************************/
.text-primary {
  color: #14357b !important; }

.text-secondary {
  color: #3664d1 !important; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #091c3a !important; }

.text-light {
  color: #858585 !important; }

.text-yellow {
  color: #f9d448 !important; }

.bg-primary {
  background: #14357b !important; }

.bg-dark {
  background: #091c3a !important; }

.bg-secondary {
  background: #3664d1 !important; }

.bg-light {
  background: #f3f9ff !important; }

/* fill */
.fill-primary {
  fill: #14357b;
  fill-rule: evenodd; }

.fill-white {
  fill: #ffffff;
  fill-rule: evenodd; }

.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

.bg-holder-bottom {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%; }

/*Background overlay*/
.bg-overlay-half-bottom {
  position: relative; }

.bg-overlay-black-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-1:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-2:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-3:before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-4:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-5:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-6:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-7:before {
    background: rgba(0, 0, 0, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-8:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-9:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

/*Background overlay*/
.bg-overlay-dark-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-1:before {
    background: rgba(6, 16, 54, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-2:before {
    background: rgba(6, 16, 54, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-3:before {
    background: rgba(6, 16, 54, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-4:before {
    background: rgba(6, 16, 54, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-5:before {
    background: rgba(6, 16, 54, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-6:before {
    background: rgba(6, 16, 54, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-7:before {
    background: rgba(6, 16, 54, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-8:before {
    background: rgba(6, 16, 54, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-dark-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-dark-9:before {
    background: rgba(6, 16, 54, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

/*bg-overlay-white*/
.bg-overlay-white-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-1:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-2:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-3:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-4:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-5:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-6:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-7:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-8:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-white-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-white-9:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-half-bottom:before {
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 100%);
  top: auto;
  height: 100%;
  transition: all .35s; }

/*bg-overlay-primary*/
.bg-overlay-primary-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-1:before {
    background: rgba(20, 53, 123, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-2:before {
    background: rgba(20, 53, 123, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-3:before {
    background: rgba(20, 53, 123, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-4:before {
    background: rgba(20, 53, 123, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-5:before {
    background: rgba(20, 53, 123, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-6:before {
    background: rgba(20, 53, 123, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-7:before {
    background: rgba(20, 53, 123, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-8:before {
    background: rgba(20, 53, 123, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-primary-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-primary-9:before {
    background: rgba(20, 53, 123, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

/*bg-overlay-secondary*/
.bg-overlay-secondary {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary:before {
    background: rgba(54, 100, 209, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-1:before {
    background: rgba(54, 100, 209, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-2:before {
    background: rgba(54, 100, 209, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-3:before {
    background: rgba(54, 100, 209, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-4:before {
    background: rgba(54, 100, 209, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-5:before {
    background: rgba(54, 100, 209, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-6:before {
    background: rgba(54, 100, 209, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-7:before {
    background: rgba(54, 100, 209, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-8:before {
    background: rgba(54, 100, 209, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-9:before {
    background: rgba(54, 100, 209, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

/* Half-overlay */
.half-overlay::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 30%;
  height: 100%;
  background-position: center center; }

.half-overlay.left-position::before {
  left: 0px;
  background: url(../images/bg/05.jpg) center center;
  background-color: #14357b; }

.half-overlay.right-position::before {
  right: 0px;
  background: url(../images/bg/05.jpg) center center;
  background-color: #14357b; }

.space-ptb {
  padding: 100px 0; }

.space-pt {
  padding: 100px 0 0; }

.space-pb {
  padding: 0 0 100px; }

.h-100vh {
  height: 100vh !important; }

/* Sticky column */
.is-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px; }

.font-sm {
  font-size: 13px; }

.bg-success-soft {
  background: rgba(10, 187, 10, 0.1) !important; }

.bg-danger-soft {
  background: rgba(245, 38, 38, 0.1) !important; }

.bg-white-soft {
  background: rgba(255, 255, 255, 0.1); }

.bg-dark-soft {
  background: rgba(0, 0, 0, 0.1); }

.form-group {
  margin-bottom: 1rem; }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

@media (max-width: 1200px) {
  .space-ptb {
    padding: 80px 0; }
  .space-pt {
    padding: 80px 0 0; }
  .space-pb {
    padding: 0 0 80px; } }

@media (max-width: 991px) {
  .space-ptb {
    padding: 60px 0; }
  .space-pt {
    padding: 60px 0 0; }
  .space-pb {
    padding: 0 0 60px; } }

@media (max-width: 767px) {
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding: 50px 0 0; }
  .space-pb {
    padding: 0 0 50px; } }

@media (max-width: 575px) {
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding: 40px 0 0; }
  .space-pb {
    padding: 0 0 40px; } }

/*****************************
    Typography
*****************************/
body {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #858585;
  overflow-x: hidden; }

a {
  outline: medium none;
  color: #14357b;
  transition: all 0.3s ease-in-out;
  text-decoration: none; }
  a:focus {
    color: #14357b;
    text-decoration: none; }
  a:hover {
    color: #14357b;
    text-decoration: none; }

input {
  outline: medium none !important;
  color: #14357b; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Jost", sans-serif;
  font-weight: normal;
  color: #14357b;
  margin-top: 0px;
  font-weight: 400; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit; }

label {
  font-weight: normal; }

h1 {
  font-size: 90px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.1; }

h2 {
  font-size: 36px;
  font-weight: 400; }

h3 {
  font-size: 28px;
  font-weight: 400; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 22px; }

h6 {
  font-size: 20px; }

p {
  font-weight: normal;
  line-height: 1.5; }

*::-moz-selection {
  background: #14357b;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #14357b;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #14357b;
  color: #ffffff;
  text-shadow: none; }

.fw-medium {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.border-radius {
  border-radius: 5px; }

.border-radius-sm {
  border-radius: 3px; }

.border-radius-md {
  border-radius: 30px; }

.border-radius-lg {
  border-radius: 100%; }

.box-shadow {
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }

.box-shadow-sm {
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }

.box-shadow-lg {
  box-shadow: 0 1rem 3rem rgba(9, 28, 58, 0.175); }

/* form-control */
.form-control, .form-select {
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 60px;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #091c3a;
  background-clip: inherit !important; }
  .form-control:focus, .form-select:focus {
    box-shadow: none;
    border-color: #14357b; }

.avatar-lg {
  min-width: 80px;
  width: 80px; }

/*Datetimepickers*/
.datetimepickers .bootstrap-datetimepicker-widget {
  width: 100%; }
  .datetimepickers .bootstrap-datetimepicker-widget table td.active {
    background-color: #14357b; }
  .datetimepickers .bootstrap-datetimepicker-widget table td.day {
    height: 30px;
    line-height: 20px;
    width: 40px;
    border-radius: 5px; }
  .datetimepickers .bootstrap-datetimepicker-widget table td.day.old {
    color: rgba(54, 100, 209, 0.5); }
  .datetimepickers .bootstrap-datetimepicker-widget table td.day.new {
    color: rgba(54, 100, 209, 0.5); }
  .datetimepickers .bootstrap-datetimepicker-widget table td {
    padding: 12px 13px;
    border-radius: inherit;
    font-size: 15px; }
    .datetimepickers .bootstrap-datetimepicker-widget table td:hover {
      background-color: #14357b;
      color: #ffffff; }
  .datetimepickers .bootstrap-datetimepicker-widget table thead tr {
    border-bottom: 2px solid #ededed; }

.datetimepickers.time .bootstrap-datetimepicker-widget table td {
  padding: 0px; }
  .datetimepickers.time .bootstrap-datetimepicker-widget table td a.btn {
    background-color: transparent;
    color: #3664d1; }
  .datetimepickers.time .bootstrap-datetimepicker-widget table td span {
    transition: all 0.3s ease-in-out; }
    .datetimepickers.time .bootstrap-datetimepicker-widget table td span:hover {
      background-color: #14357b;
      color: #ffffff; }
  .datetimepickers.time .bootstrap-datetimepicker-widget table td:hover {
    background-color: transparent;
    color: #3664d1; }
  .datetimepickers.time .bootstrap-datetimepicker-widget table td .btn-primary:hover {
    background-color: #3664d1; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto; }
  .bootstrap-datetimepicker-widget.dropdown-menu th {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    width: 35px;
    color: #3664d1;
    font-weight: 500; }

.border-right-0 {
  border-right: inherit !important; }

.border-right {
  border-right: 1px solid #ededed; }

.form-control::-moz-placeholder {
  color: #858585; }

.form-control::-ms-input-placeholder {
  color: #858585; }

.form-control::-webkit-input-placeholder {
  color: #858585; }

textarea.form-control {
  height: auto; }

.input-group.date .input-group-text {
  height: 100%;
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
  border-radius: 30px;
  background-color: #ffffff; }
  .input-group.date .input-group-text i {
    color: #858585; }

/* input-group-prepend */
.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  width: 50px;
  background: transparent;
  border-color: #ededed;
  box-shadow: none;
  justify-content: center; }

/* file-upload */
.input-group.file-upload {
  height: 50px; }
  .input-group.file-upload .form-control {
    height: 50px;
    color: #858585;
    border-radius: 3px;
    border-color: #ededed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    border-radius: 3px; }
  .input-group.file-upload .input-group-text {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #858585;
    border-color: #ededed;
    padding: 15px 90px 15px 20px;
    border-radius: 3px;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    line-height: 1.5;
    background-color: #ffffff;
    justify-content: space-between;
    margin-left: 0; }
    .input-group.file-upload .input-group-text:after {
      content: "Browse";
      height: 48px;
      padding: 14px 25px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      line-height: 1.5;
      color: #091c3a;
      background-color: #f3f9ff;
      border-left: inherit;
      border-radius: 0px 3px 3px 0px; }

@media (max-width: 767px) {
  h2 {
    font-size: 30px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 24px; } }

@media (max-width: 575px) {
  h2 {
    font-size: 28px; }
  h3 {
    font-size: 24px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 20px; }
  h6 {
    font-size: 18px; }
  /*Datetimepicker*/
  .bootstrap-datetimepicker-widget {
    width: 300px !important;
    overflow-x: auto !important; } }

@media (max-width: 375px) {
  .bootstrap-datetimepicker-widget {
    width: 260px !important;
    overflow-x: auto !important; } }

/*****************************
  Button
*****************************/
.btn-link {
  text-decoration: none;
  color: #14357b;
  transition: all 0.3s ease-in-out; }
  .btn-link:after {
    display: none;
    content: inherit; }
  .btn-link:hover {
    color: #3664d1;
    text-decoration: underline; }

button {
  outline: medium none !important;
  color: #14357b; }

/* btn */
.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  background-color: #14357b; }
  .btn:hover {
    background-color: #061036;
    color: #f9d448; }
  .btn:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff; }
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #ffffff; }

.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
  color: #ffffff; }

.show > .btn-primary.dropdown-toggle {
  background: #14357b;
  border-color: #14357b; }

.btn.btn-sm {
  padding: 8px 20px;
  font-size: 14px; }

.btn.btn-md {
  padding: 10px 30px;
  font-size: 14px; }

.btn.btn-lg {
  padding: 20px 40px; }

.btn.btn-xl {
  padding: 25px 40px; }

.btn-app {
  display: flex; }
  .btn-app i {
    font-size: 40px; }

.btn.btn-link {
  padding: 0;
  background-color: inherit;
  color: #14357b;
  font-size: 14px; }
  .btn.btn-link:before {
    content: inherit; }
  .btn.btn-link:hover {
    color: #3664d1;
    text-decoration: underline; }
  .btn.btn-link:focus {
    color: #14357b;
    text-decoration: underline; }

.btn.btn-primary {
  background-color: #14357b; }
  .btn.btn-primary:hover {
    background-color: #061036;
    color: #f9d448; }
  .btn.btn-primary:focus {
    box-shadow: none;
    outline: none;
    color: #f9d448;
    background-color: #14357b; }
  .btn.btn-primary:active {
    box-shadow: none;
    outline: none;
    color: #f9d448;
    background-color: #14357b; }

.bg-secondary .btn.btn-primary {
  background-color: #14357b; }
  .bg-secondary .btn.btn-primary:before {
    background-color: #ffffff; }
  .bg-secondary .btn.btn-primary:hover {
    background-color: #14357b;
    color: #14357b; }

.btn.btn-secondary {
  background-color: #3664d1; }
  .btn.btn-secondary:hover {
    background-color: #f9d448;
    color: #061036; }
  .btn.btn-secondary:focus {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background-color: #3664d1; }
  .btn.btn-secondary:active {
    box-shadow: none;
    outline: none;
    color: #ffffff;
    background-color: #3664d1; }

.bg-primary .btn.btn-secondary {
  background-color: #3664d1; }
  .bg-primary .btn.btn-secondary:hover {
    background-color: #f9d448;
    color: #061036; }

.btn.btn-light {
  background-color: #ffffff;
  color: #3664d1; }
  .btn.btn-light:hover {
    background-color: #14357b;
    color: #f9d448; }
  .btn.btn-light:focus {
    box-shadow: none;
    outline: none;
    color: #3664d1;
    background-color: #ffffff; }
  .btn.btn-light:active {
    box-shadow: none;
    outline: none;
    color: #3664d1;
    background-color: #ffffff; }

.btn.btn-outline-primary {
  border: 2px solid #14357b;
  color: #14357b;
  background-color: transparent; }
  .btn.btn-outline-primary:hover {
    background-color: #3664d1;
    border: 2px solid #3664d1;
    color: #f9d448; }
  .btn.btn-outline-primary:focus {
    background-color: transparent;
    color: #14357b;
    border: 2px solid #14357b; }
  .btn.btn-outline-primary:active {
    background-color: transparent;
    color: #14357b;
    border: 2px solid #14357b; }

.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #ffffff;
  border: 2px solid #3664d1; }

.btn.btn-outline-secondary {
  border: 2px solid #3664d1;
  color: #3664d1;
  background-color: transparent; }
  .btn.btn-outline-secondary:hover {
    background-color: #14357b;
    border: 2px solid #14357b;
    color: #f9d448; }
  .btn.btn-outline-secondary:focus {
    background-color: transparent;
    color: #3664d1;
    border: 2px solid #3664d1; }
  .btn.btn-outline-secondary:active {
    background-color: transparent;
    color: #3664d1;
    border: 2px solid #3664d1; }

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #ffffff;
  border: 2px solid #14357b; }

/* keyframes */
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1; }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0; } }

@keyframes sonarWave {
  from {
    opacity: 0.4; }
  to {
    transform: scale(3);
    opacity: 0; } }

/*btn-app*/
.btn-app {
  font-size: 14px;
  padding: 8px 20px;
  background-color: #14357b;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  border-radius: 5px; }
  .btn-app i {
    font-size: 30px;
    padding-right: 10px; }
  .btn-app .small-text {
    font-size: 11px; }
  .btn-app span {
    line-height: 14px; }
  .btn-app:hover {
    background-color: #14357b;
    color: #f9d448; }

@media (max-width: 575px) {
  .btn-app {
    display: inline-flex;
    padding: 8px 14px; }
    .btn-app i {
      font-size: 24px;
      padding-right: 6px; } }

/*****************************
  List
*****************************/
ul.list {
  padding: 0;
  margin: 0; }
  ul.list li {
    margin-bottom: 15px;
    display: flex;
    align-items: start; }
    ul.list li i {
      margin-right: 10px;
      color: #ffffff;
      background-color: #14357b;
      border-radius: 3px;
      height: 10px;
      width: 10px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin-top: 5px; }
    ul.list li:last-child {
      margin-bottom: 0; }

ul.list.list-circle li i {
  font-size: 6px; }

/*****************************
 listing
*****************************/
.listing-item {
  background-color: #ffffff;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px;
  padding: 10px; }
  .listing-item .listing-image {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
    .listing-item .listing-image img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover; }
    .listing-item .listing-image .listing-favourite {
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 9; }
      .listing-item .listing-image .listing-favourite i {
        height: 32px;
        width: 32px;
        background-color: #ffffff;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        color: #858585; }
        .listing-item .listing-image .listing-favourite i:hover {
          color: #ff0000; }
    .listing-item .listing-image .listing-favourite.listing-like i {
      color: #ff0000; }
      .listing-item .listing-image .listing-favourite.listing-like i:hover {
        color: #ff0000; }
  .listing-item .listing-details {
    padding: 25px 15px 15px; }
    .listing-item .listing-details .listing-title h5 {
      display: flex;
      align-items: center; }
    .listing-item .listing-details .listing-title .title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      color: #091c3a; }
      .listing-item .listing-details .listing-title .title:hover {
        color: #14357b; }
    .listing-item .listing-details .listing-title .country-flags {
      margin-right: 10px; }
    .listing-item .listing-details .listing-rating {
      padding: 12px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #ededed;
      border-top: 1px solid #ededed;
      align-items: center; }
      .listing-item .listing-details .listing-rating .rating i {
        font-size: 14px; }
      .listing-item .listing-details .listing-rating .price {
        font-size: 22px;
        font-weight: bold;
        color: #091c3a; }
        .listing-item .listing-details .listing-rating .price span {
          color: #858585;
          font-size: 16px;
          font-weight: 400;
          margin-right: 10px; }
    .listing-item .listing-details .listing-loaction {
      color: #858585;
      font-size: 12px;
      padding: 10px 0 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%; }
      .listing-item .listing-details .listing-loaction i {
        font-size: 18px;
        margin-right: 6px; }
      .listing-item .listing-details .listing-loaction img {
        font-size: 18px; }
      .listing-item .listing-details .listing-loaction:hover {
        color: #14357b; }
    .listing-item .listing-details .listing-info {
      display: flex;
      padding-bottom: 20px; }
      .listing-item .listing-details .listing-info img {
        flex: 0 0 41px;
        width: 41px;
        height: 41px;
        margin-right: 10px;
        border-radius: 50%; }
      .listing-item .listing-details .listing-info p {
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical; }
  .listing-item:hover .listing-image img {
    transform: scale(1.1); }

.listing-item.listing-item-2 {
  display: flex;
  position: relative;
  padding: 0;
  width: 100%;
  box-shadow: inherit;
  background-color: inherit; }
  .listing-item.listing-item-2 .listing-image {
    width: 50%;
    display: flex;
    position: relative;
    border-radius: 5px; }
  .listing-item.listing-item-2 .listing-details {
    padding: 25px 30px;
    align-self: center;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    border-radius: 5px;
    width: 65%;
    left: 35%; }
    .listing-item.listing-item-2 .listing-details .price {
      font-size: 22px;
      font-weight: bold;
      color: #091c3a;
      padding-top: 0px; }
      .listing-item.listing-item-2 .listing-details .price span {
        color: #858585;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px; }
    .listing-item.listing-item-2 .listing-details .listing-title .title {
      font-size: 26px;
      font-weight: 600; }
    .listing-item.listing-item-2 .listing-details .listing-loaction {
      padding: 20px 0 20px; }
    .listing-item.listing-item-2 .listing-details .listing-rating {
      padding: 10px 0; }
    .listing-item.listing-item-2 .listing-details .listing-info {
      padding: 20px 0 30px; }

.listing-item.listing-item-3 {
  display: flex;
  align-items: center;
  padding: 10px; }
  .listing-item.listing-item-3 .listing-image {
    flex: 0 0 120px;
    height: 120px;
    width: 120px;
    border-radius: 6px; }
    .listing-item.listing-item-3 .listing-image img {
      border-radius: 6px; }
  .listing-item.listing-item-3 .listing-details {
    padding: 10px 0px 10px 15px;
    width: 60%;
    flex: auto; }
    .listing-item.listing-item-3 .listing-details .listing-rating {
      padding: 10px 0 0;
      border-bottom: inherit; }
      .listing-item.listing-item-3 .listing-details .listing-rating .price {
        font-size: 14px; }
        .listing-item.listing-item-3 .listing-details .listing-rating .price span {
          font-size: 14px; }
      .listing-item.listing-item-3 .listing-details .listing-rating .rating span {
        font-size: 14px; }
    .listing-item.listing-item-3 .listing-details .listing-title .title {
      font-size: 18px; }
  .listing-item.listing-item-3 .listing-loaction {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 0;
    display: inline-block;
    max-width: 100%; }

.owl-carousel .listing-item {
  margin-bottom: 20px; }

.masonry {
  column-count: 3;
  column-gap: 0; }

.masonry .masonry-item {
  vertical-align: top; }

/*listing-item-details*/
.listing-item.listing-item-details {
  box-shadow: none; }
  .listing-item.listing-item-details .listing-details {
    margin-bottom: 24px;
    padding: 0px; }
    .listing-item.listing-item-details .listing-details .listing-title .country-name {
      display: flex;
      align-items: center; }
    .listing-item.listing-item-details .listing-details .listing-title .title {
      font-size: 26px;
      line-height: 36px;
      font-weight: 600; }
    .listing-item.listing-item-details .listing-details .listing-title .price {
      font-size: 22px;
      font-weight: bold;
      color: #091c3a; }
      .listing-item.listing-item-details .listing-details .listing-title .price span {
        color: #858585;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px; }
    .listing-item.listing-item-details .listing-details .listing-favourite .btn.btn-light {
      box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }
  .listing-item.listing-item-details .listing-detail-page .listing-detail-box {
    box-shadow: none;
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 30px; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box .detail-title {
      border-bottom: 1px solid #ededed;
      padding-bottom: 12px;
      margin-bottom: 20px; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box .rating {
      font-size: 14px; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .rating span {
        border: 1px solid #0abb0a;
        padding: 0 4px;
        line-height: 16px;
        border-radius: 3px; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item {
      position: relative;
      border: none;
      padding-bottom: 30px;
      margin-bottom: 0px; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item:last-child {
        padding-bottom: 0px; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item:before {
        position: absolute;
        content: "";
        left: 14px;
        height: 100%;
        width: 1px;
        border: 1px dashed #ededed; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button {
        padding: 0;
        box-shadow: none;
        border: none; }
        .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button:before {
          font-size: 16px;
          font-family: "Font Awesome 5 Free";
          display: inline-block;
          content: "\f192";
          font-weight: 900;
          color: #858585;
          margin-right: 10px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 100%; }
        .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button:after {
          display: none; }
        .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button:focus {
          box-shadow: none; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button:not(.collapsed) {
        background-color: inherit;
        box-shadow: none;
        color: #14357b; }
        .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-button:not(.collapsed):before {
          content: "\f3c5";
          background-color: #14357b;
          color: #f9d448; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item .accordion-body {
        padding: 16px 20px 16px 40px; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
      .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
        .listing-item.listing-item-details .listing-detail-page .listing-detail-box .accordion .accordion-item:last-child:before {
          display: none; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box:hover .listing-image img {
      transform: scale(1.1); }
  .listing-item.listing-item-details .listing-detail-page .listing-detail-box.listing-detail-description {
    padding: 0;
    border: none;
    border-radius: inherit; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box.listing-detail-description .listing-image {
      border-radius: 5px; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box.listing-detail-description .listing-detail-content {
      background-color: #ffffff;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      border-radius: 5px;
      padding: 20px;
      margin: 20px;
      margin-top: -80px;
      position: relative;
      z-index: 9; }
  .listing-item.listing-item-details .listing-detail-page .listing-detail-box.reviews-box .avatar {
    width: 70px;
    flex: 0 0 70px; }
    .listing-item.listing-item-details .listing-detail-page .listing-detail-box.reviews-box .avatar img {
      border-radius: 100%;
      border: 4px solid #ffffff;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1); }
  .listing-item.listing-item-details .sidebar .widget {
    padding: 0px; }
    .listing-item.listing-item-details .sidebar .widget .widget-title {
      padding: 15px 20px;
      margin-bottom: 0px; }
      .listing-item.listing-item-details .sidebar .widget .widget-title h6 {
        color: #091c3a !important; }
      .listing-item.listing-item-details .sidebar .widget .widget-title .collapse-title {
        font-size: 24px;
        font-weight: 600;
        color: #091c3a;
        font-family: "Jost", sans-serif;
        display: flex;
        align-items: center; }
        .listing-item.listing-item-details .sidebar .widget .widget-title .collapse-title i {
          margin-left: auto; }
    .listing-item.listing-item-details .sidebar .widget .widget-content {
      padding: 25px 20px; }
  .listing-item.listing-item-details .sidebar.tour-sidebar .widget .widget-title {
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px; }

.ratings {
  border: none;
  float: left; }

.ratings > input {
  display: none; }

.ratings > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f005";
  font-weight: 900; }

.ratings > .half:before {
  content: "\f089";
  position: absolute; }

.ratings > label {
  color: #ddd;
  float: right; }

.ratings > input:checked ~ label, .ratings:not(:checked) > label:hover, .ratings:not(:checked) > label:hover ~ label {
  color: #FFD700; }

.ratings > input:checked + label:hover, .ratings > input:checked ~ label:hover, .ratings > label:hover ~ input:checked ~ label, .ratings > input:checked ~ label:hover ~ label {
  color: #FFED85; }

/*****************************
		Responsive
*****************************/
@media (max-width: 1200px) {
  .sidebar .widget .listing-item.listing-item-3 .listing-image {
    flex: 0 0 90px;
    width: 90px;
    height: 90px; }
  .sidebar .widget .listing-item.listing-item-3 .listing-details .listing-rating .price {
    font-size: 12px; }
    .sidebar .widget .listing-item.listing-item-3 .listing-details .listing-rating .price span {
      font-size: 12px; }
  .sidebar .widget .listing-item.listing-item-3 .listing-details .listing-rating .rating span {
    font-size: 12px; } }

@media (max-width: 1199px) {
  .listing-item.listing-item-2 .listing-image {
    width: 60%; } }

@media (max-width: 991px) {
  .listing-item.listing-item-2 {
    display: block; }
    .listing-item.listing-item-2 .listing-image {
      width: auto; }
    .listing-item.listing-item-2 .listing-details {
      width: calc(100% - 30px);
      padding: 30px;
      margin: -50px auto 10px;
      position: inherit;
      left: inherit; } }

@media (max-width: 767px) {
  .listing-item.listing-item-2 {
    display: inherit;
    padding: 0; }
    .listing-item.listing-item-2 .listing-details {
      padding: 25px; } }

@media (max-width: 575px) {
  .categories-tab .listing-item.listing-item-3 {
    display: block; }
    .categories-tab .listing-item.listing-item-3 .listing-details {
      width: 100%;
      padding: 30px 20px 20px; } }

/*****************************
  Tabs
*****************************/
.nav-tab .nav-tabs {
  border: inherit; }
  .nav-tab .nav-tabs .nav-link {
    font-family: "Jost", sans-serif;
    font-weight: 600;
    color: #858585;
    border: 2px solid #ededed;
    background-color: transparent;
    transition: all 0.3s ease-out 0s;
    padding: 15px 30px;
    margin-right: 10px;
    position: relative;
    z-index: 2;
    border-radius: 30px; }
    .nav-tab .nav-tabs .nav-link:hover {
      color: #ffffff;
      background-color: #14357b;
      border: 2px solid #14357b; }
    .nav-tab .nav-tabs .nav-link:last-child {
      margin-right: 0px; }
  .nav-tab .nav-tabs .nav-link.active {
    border: 2px solid #14357b;
    color: #ffffff;
    background-color: #14357b; }
    .nav-tab .nav-tabs .nav-link.active:before {
      content: inherit; }

.nav.nav-pills {
  margin-right: 0; }
  .nav.nav-pills .nav-link {
    padding: 0;
    border-radius: 0;
    background-color: inherit; }

/*categories-tab*/
.categories-tab .nav-tabs {
  border-bottom: none;
  margin-bottom: 30px; }
  .categories-tab .nav-tabs .nav-item {
    width: 19%;
    margin-right: 1%; }
    .categories-tab .nav-tabs .nav-item:last-child {
      margin-right: 0%; }
    .categories-tab .nav-tabs .nav-item .nav-link {
      padding: 0px;
      margin-bottom: 0px;
      border-radius: 5px;
      width: 100%;
      position: relative;
      z-index: 2;
      border: none;
      border-bottom: 2px solid transparent;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      background-color: #ffffff; }
      .categories-tab .nav-tabs .nav-item .nav-link .nav-box {
        position: relative;
        z-index: -1;
        padding: 15px;
        display: block; }
        .categories-tab .nav-tabs .nav-item .nav-link .nav-box .icon-box {
          display: block; }
          .categories-tab .nav-tabs .nav-item .nav-link .nav-box .icon-box object {
            width: 40px;
            margin-bottom: 0px; }
        .categories-tab .nav-tabs .nav-item .nav-link .nav-box .title {
          text-transform: uppercase;
          font-size: 16px;
          font-family: "Jost", sans-serif;
          font-weight: 600;
          color: #3664d1; }
      .categories-tab .nav-tabs .nav-item .nav-link:focus {
        border: none; }
      .categories-tab .nav-tabs .nav-item .nav-link:hover {
        border: none;
        border-bottom: 2px solid #14357b; }
        .categories-tab .nav-tabs .nav-item .nav-link:hover .title {
          color: #14357b; }
    .categories-tab .nav-tabs .nav-item .nav-link.active {
      border: none;
      border-bottom: 2px solid #14357b; }
      .categories-tab .nav-tabs .nav-item .nav-link.active .nav-box .title {
        color: #14357b; }
  .categories-tab .nav-tabs .nav-item.show .nav-link {
    border: none; }

.categories-tab .listing-item.listing-item-3 {
  align-items: flex-start; }
  .categories-tab .listing-item.listing-item-3 .listing-image {
    flex: 0 0 150px;
    height: 135px;
    width: 150px; }
  .categories-tab .listing-item.listing-item-3 .listing-details .listing-title .country-flags {
    margin-right: 10px; }
  .categories-tab .listing-item.listing-item-3 .listing-details .listing-title .title {
    font-size: 22px; }
  .categories-tab .listing-item.listing-item-3 .listing-details .listing-rating {
    margin-bottom: 0; }

@media (max-width: 575px) {
  .categories-tab .listing-item.listing-item-3 .listing-image {
    flex: 0 0 100%;
    width: 100%;
    height: auto; }
  .nav-tab .nav-tabs .nav-link {
    padding: 10px 20px;
    margin-right: 5px;
    margin-bottom: 10px; } }

/*****************************
  Range Slider
*****************************/
.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #f9d448;
  height: 6px; }

.irs--flat .irs-from {
  background: transparent;
  color: #f9d448;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-from:before {
    display: none; }

.irs--flat .irs-to {
  background: transparent;
  color: #f9d448;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-to:before {
    display: none; }

.irs--flat .irs-single {
  background: transparent;
  color: #f9d448;
  font-size: 13px;
  font-weight: 600; }
  .irs--flat .irs-single:before {
    display: none; }

.irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #f9d448;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-handle:hover > i:first-child {
  background: #f9d448; }

.irs--flat .irs-handle.state_hover > i:first-child {
  background: #f9d448; }

/*****************************
  Pricing Table
*****************************/
.pricing-header {
  text-align: center; }
  .pricing-header .pricing-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px; }
  .pricing-header .pricing-price {
    font-size: 18px;
    border-radius: 5px;
    font-family: "Jost", sans-serif;
    color: #ffffff;
    background-color: #3664d1;
    font-weight: 600;
    padding: 8px 10px; }
    .pricing-header .pricing-price span {
      font-size: 24px;
      padding-right: 5px; }
    .pricing-header .pricing-price .monthly-price,
    .pricing-header .pricing-price .yearly-price {
      text-transform: capitalize; }
    .pricing-header .pricing-price .yearly-price {
      display: none; }
    .pricing-header .pricing-price .monthly-price {
      display: block; }
  .pricing-header .pricing-price.change-pricing-price .yearly-price {
    display: block; }
  .pricing-header .pricing-price.change-pricing-price .monthly-price {
    display: none; }

.pricing-tab span {
  vertical-align: middle; }

.pricing-tab .monthly-tab {
  font-size: 20px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize; }

.pricing-tab .yearly-tab {
  font-size: 20px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize; }

.pricing-tab .pricing-tab-switcher {
  height: 20px;
  width: 44px;
  background: #14357b;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  transition: all 0.3s ease;
  margin: 0 10px;
  border: 1px solid #14357b;
  cursor: pointer; }
  .pricing-tab .pricing-tab-switcher:before {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: #ffffff;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    transition: all 0.3s ease; }

.pricing-tab .pricing-tab-switcher.active:before {
  left: calc(100% - 20px); }

.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 0px 15px;
  position: relative; }
  .switch .switch-btn {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .switch .switch-btn span {
      font-size: 18px;
      color: #ffffff;
      font-weight: 600; }
  .switch input {
    display: none; }

.pricing {
  background-color: #f3f9ff; }
  .pricing .pricing-header {
    padding: 50px 30px 20px; }
  .pricing .pricing-header.pricing-titel-header {
    padding: 68px 40px 68px;
    text-align: left; }
  .pricing .pricing-body ul {
    margin-bottom: 0px; }
    .pricing .pricing-body ul li {
      text-align: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 24px;
      color: #3664d1;
      font-family: "Jost", sans-serif;
      padding: 12px 20px; }
  .pricing .pricing-body .pricing-titel-list li {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    padding: 16px 40px;
    color: #ffffff;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .pricing .pricing-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    text-align: center; }
    .pricing .pricing-footer .btn {
      background-color: #3664d1;
      color: #ffffff;
      font-weight: 500; }
      .pricing .pricing-footer .btn:hover {
        background-color: #14357b;
        color: #f9d448; }
    .pricing .pricing-footer .btn-primary.btn {
      background-color: #14357b;
      color: #ffffff; }
      .pricing .pricing-footer .btn-primary.btn i {
        padding-right: 0;
        padding-left: 10px; }
      .pricing .pricing-footer .btn-primary.btn:hover {
        background-color: #061036;
        color: #f9d448; }
  .pricing .pricing-footer.pricing-titel-footer {
    text-align: left;
    padding: 40px 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .pricing .pricing-footer.pricing-titel-footer .btn {
      text-transform: uppercase;
      font-weight: 500; }

.pricing.active {
  background-color: #14357b; }
  .pricing.active .pricing-header .pricing-title {
    color: #ffffff; }
  .pricing.active .pricing-body ul li {
    color: #ffffff;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .pricing.active .pricing-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .pricing.active .pricing-footer .btn {
      background-color: #3664d1;
      color: #ffffff; }
      .pricing.active .pricing-footer .btn:hover {
        color: #3664d1;
        background-color: #f9d448; }

@media (max-width: 1399px) {
  .pricing .pricing-header.pricing-titel-header {
    padding: 54px 20px; }
    .pricing .pricing-header.pricing-titel-header .pricing-tab .yearly-tab {
      line-height: 29px; }
  .pricing .pricing-body .pricing-titel-list li {
    padding: 16px 20px; }
  .pricing .pricing-footer .btn-dark.btn {
    padding: 13px 30px; }
  .pricing .pricing-footer.pricing-titel-footer {
    padding: 40px 20px; } }

@media (max-width: 1199px) {
  .pricing .pricing-header {
    padding: 30px 20px 20px; }
    .pricing .pricing-header .pricing-title {
      font-size: 22px;
      margin-bottom: 18px; }
    .pricing .pricing-header .pricing-price span {
      font-size: 20px; }
  .pricing .pricing-header.pricing-titel-header {
    padding: 44px 20px 44px; }
  .pricing .pricing-footer {
    padding: 20px 20px; }
    .pricing .pricing-footer .btn {
      padding: 13px 15px;
      font-size: 15px; }
  .pricing .pricing-footer.pricing-titel-footer {
    padding: 20px 20px; }
    .pricing .pricing-footer.pricing-titel-footer .btn-primary.btn {
      padding: 13px 15px; }
      .pricing .pricing-footer.pricing-titel-footer .btn-primary.btn i {
        padding-left: 5px; } }

@media (max-width: 991px) {
  .pricing {
    border: 1px solid rgba(255, 255, 255, 0.1); }
    .pricing .pricing-header {
      padding: 25px 20px; }
    .pricing .pricing-header.pricing-titel-header {
      padding: 25px 20px;
      text-align: center; }
    .pricing .pricing-body ul li {
      position: relative;
      text-align: right; }
      .pricing .pricing-body ul li:before {
        content: attr(data-label);
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        float: left;
        font-size: 18px;
        font-weight: normal;
        color: #3664d1;
        text-transform: uppercase; }
    .pricing .pricing-footer.pricing-titel-footer {
      padding: 30px; }
  .pricing.active .pricing-body ul li:before {
    color: #ffffff; } }

@media (max-width: 767px) {
  .pricing .pricing-header.pricing-titel-header {
    text-align: center; }
  .pricing .pricing-footer.pricing-titel-footer {
    text-align: center; } }

@media (max-width: 479px) {
  .pricing-tab .monthly-tab {
    font-size: 14px; }
  .pricing-tab .pricing-tab-switcher {
    height: 20px;
    width: 40px;
    margin: 0 8px; }
  .pricing-tab .pricing-tab-switcher.active:before {
    left: calc(100% - 20px); }
  .pricing-tab .yearly-tab {
    font-size: 14px; } }

/*************************
        Progress Bar
*************************/
.progress {
  position: relative;
  overflow: visible;
  height: .5rem; }
  .progress .progress-bar {
    border-radius: 5px;
    background-color: #14357b; }

/*****************************
 Accordion
*****************************/
.accordion .accordion-item {
  border: none;
  margin-bottom: 20px; }
  .accordion .accordion-item .accordion-button {
    padding: 18px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    background-color: #ffffff;
    border: 1px solid #ededed;
    border-radius: 50px; }
    .accordion .accordion-item .accordion-button:after {
      display: none; }
    .accordion .accordion-item .accordion-button i {
      position: absolute;
      right: 30px;
      transition: all 0.3s ease-in-out; }
    .accordion .accordion-item .accordion-button:focus {
      box-shadow: none; }
  .accordion .accordion-item .accordion-button.collapsed {
    border-radius: 50px; }
  .accordion .accordion-item .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: #14357b;
    color: #f9d448;
    border-radius: 50px; }
    .accordion .accordion-item .accordion-button:not(.collapsed):after {
      display: none; }
    .accordion .accordion-item .accordion-button:not(.collapsed) i {
      transform: rotate(-180deg); }
  .accordion .accordion-item ul.list li i {
    background-color: inherit;
    color: #14357b;
    font-size: 16px;
    width: inherit;
    height: inherit;
    padding: 0;
    display: inherit; }
  .accordion .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 50px; }

.accordion .card-header {
  background: none;
  border-bottom: none;
  padding: 0;
  border-radius: inherit; }
  .accordion .card-header .accordion-title {
    display: flex;
    align-items: center; }
    .accordion .card-header .accordion-title .btn {
      background: #14357b;
      padding: 18px 30px; }
    .accordion .card-header .accordion-title i {
      font-size: 12px;
      margin-left: auto; }

.accordion-checkbox .card label {
  display: flex; }
  .accordion-checkbox .card label .accordion-title {
    padding-left: 11px;
    display: inline-block; }

.accordion-checkbox .card .card-body {
  padding: 10px 0px 0px 36px; }

.accordion-checkbox .card .pay-icon {
  height: 20px;
  margin-left: auto; }

.asked-questions .questions-title {
  font-weight: 600;
  display: flex;
  font-size: 20px;
  line-height: 30px; }

.booking-02 {
  padding: 40px 28px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .booking-02 .accordion .custom-control .custom-control-label .accordion-title {
    font-weight: 700; }

@media (max-width: 767px) {
  .accordion .accordion-item .accordion-button {
    padding: 18px 30px 18px 20px; }
    .accordion .accordion-item .accordion-button i {
      right: 20px; } }

@media (max-width: 575px) {
  .booking-02 {
    padding: 30px 15px; }
    .booking-02 .accordion-checkbox .card label {
      display: block !important; }
      .booking-02 .accordion-checkbox .card label .pay-icon {
        margin-left: 0 !important;
        display: block; } }

/*****************************
  Countdown
*****************************/
.countdown-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .countdown-2 .countdown {
    padding: 10px;
    background-color: #14357b;
    color: #ffffff;
    border-radius: 3px;
    margin: 5px;
    width: 66px;
    height: 66px; }
    .countdown-2 .countdown span {
      font-size: 18px;
      line-height: 1;
      font-weight: bold;
      text-transform: capitalize; }
    .countdown-2 .countdown p {
      font-size: 12px;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 0;
      color: #f9d448; }

.coming-soon-section p {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 0; }

.coming-soon-section h1 {
  font-size: 90px;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 30px;
  color: #ffffff; }

.coming-soon-section .countdown-box {
  display: flex;
  align-items: center;
  justify-content: center; }
  .coming-soon-section .countdown-box .countdown {
    padding: 0 30px; }
    .coming-soon-section .countdown-box .countdown span {
      font-size: 60px;
      color: #ffffff;
      font-weight: bold;
      font-family: "Jost", sans-serif; }
    .coming-soon-section .countdown-box .countdown p {
      font-size: 24px;
      font-family: "Jost", sans-serif;
      text-transform: capitalize;
      color: #f9d448; }

.coming-soon-section a {
  margin: 45px 0px;
  display: block;
  color: #ffffff; }
  .coming-soon-section a:hover {
    color: #f9d448; }

.coming-soon-section .form-inline {
  position: relative; }
  .coming-soon-section .form-inline .form-control {
    height: 60px;
    color: #3664d1;
    background-color: #ffffff;
    padding-right: 130px;
    padding-left: 30px;
    border-radius: 30px !important; }
  .coming-soon-section .form-inline .form-control::-moz-placeholder {
    color: #3664d1; }
  .coming-soon-section .form-inline .form-control::-ms-input-placeholder {
    color: #3664d1; }
  .coming-soon-section .form-inline .form-control::-webkit-input-placeholder {
    color: #3664d1; }
  .coming-soon-section .form-inline .btn {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 13px 25px;
    height: 50px;
    border-radius: 30px !important; }
    .coming-soon-section .form-inline .btn:hover {
      color: #14357b;
      background-color: #f9d448; }

.coming-soon-top .navbar .navbar-brand img {
  height: 70px; }

@media (max-width: 1200px) {
  .coming-soon-section h1 {
    font-size: 70px; }
  .coming-soon-section .countdown-box .countdown span {
    font-size: 40px; }
  .coming-soon-section .countdown-2 .countdown span {
    font-size: 60px; } }

@media (max-width: 991px) {
  .coming-soon-section h1 {
    font-size: 60px; }
  .coming-soon-section .countdown-2 .countdown span {
    font-size: 50px; } }

@media (max-width: 767px) {
  .coming-soon-section h1 {
    font-size: 46px; }
  .coming-soon-section .countdown-box .countdown span {
    font-size: 30px; }
  .coming-soon-section .countdown-2 .countdown span {
    font-size: 40px; } }

@media (max-width: 575px) {
  .coming-soon-section h1 {
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .coming-soon-section .countdown-box .countdown {
    padding: 0px 10px; }
    .coming-soon-section .countdown-box .countdown p {
      font-size: 18px; }
  .coming-soon-section a {
    margin: 20px 0px; } }

/*****************************
  Countdown
*****************************/
@media (max-width: 767px) {
  .coming-soon-section h1 {
    font-size: 46px; }
  .coming-soon-section .countdown-box .countdown span {
    font-size: 30px; } }

@media (max-width: 575px) {
  .coming-soon-section h1 {
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .coming-soon-section .countdown-box .countdown {
    padding: 0px 10px; }
    .coming-soon-section .countdown-box .countdown p {
      font-size: 18px; }
  .coming-soon-section a {
    margin: 20px 0px; } }

/*****************************
  counter
*****************************/
.counter {
  position: relative; }
  .counter span {
    font-size: 36px;
    line-height: 1;
    color: #14357b;
    font-weight: bold;
    margin-bottom: 30px; }
  .counter label {
    display: block;
    margin-top: 20px; }
  .counter:before {
    background: #ededed;
    position: absolute;
    width: 3px;
    height: 60px;
    content: "";
    right: 30%;
    top: 50%;
    transform: translateY(-50%); }

.counter-section .counter-box {
  background-color: #ffffff;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 5px;
  position: relative;
  z-index: 9;
  margin-top: -90px; }
  .counter-section .counter-box .counter:before {
    right: -12px;
    background: #3664d1;
    width: 2px; }
  .counter-section .counter-box .counter .counter-number .counter-text {
    display: flex;
    align-items: center;
    justify-content: center; }
    .counter-section .counter-box .counter .counter-number .counter-text h2 {
      font-size: 60px;
      line-height: 70px;
      color: #3664d1;
      font-weight: 600; }
    .counter-section .counter-box .counter .counter-number .counter-text span {
      font-size: 60px;
      line-height: 70px;
      color: #14357b;
      font-weight: 600;
      margin-bottom: 0; }
  .counter-section .counter-box .counter .counter-number p {
    text-align: center; }
  .counter-section .counter-box .last-counter:before {
    content: inherit; }

@media (max-width: 991px) {
  .counter-section .counter-box .counter:before {
    content: inherit;
    right: 0; } }

/*****************************
  Testimonial
*****************************/
.testimonial {
  position: relative; }
  .testimonial .testimonial-star-icon ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 20px; }
    .testimonial .testimonial-star-icon ul li {
      list-style-type: none;
      margin-right: 5px;
      font-size: 16px;
      color: #f9d448; }
  .testimonial .testimonial-quote-icon {
    width: 105px;
    position: absolute;
    right: 75px;
    top: 0px;
    opacity: 0.1; }
  .testimonial .testimonial-author {
    margin-top: 30px; }
    .testimonial .testimonial-author .avatar {
      margin-right: 30px; }
      .testimonial .testimonial-author .avatar img {
        width: 55px;
        border-radius: 100%;
        border: 4px solid #ffffff; }
    .testimonial .testimonial-author .testimonial-name .author-tittle {
      font-family: "Jost", sans-serif;
      font-size: 16px;
      font-weight: 500; }
    .testimonial .testimonial-author .testimonial-name span {
      position: relative;
      padding-left: 15px;
      margin-left: 15px;
      font-size: 14px; }
      .testimonial .testimonial-author .testimonial-name span:before {
        content: "";
        height: 1px;
        width: 5px;
        background: #ffffff;
        position: absolute;
        left: 0;
        top: 50%; }
  .testimonial .testimonial-content {
    margin-bottom: 20px; }
    .testimonial .testimonial-content p {
      font-size: 18px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }

.testimonial-style-1 .testimonial {
  text-align: center; }
  .testimonial-style-1 .testimonial .testimonial-quote-icon {
    position: inherit;
    margin: 0 auto;
    right: inherit; }
  .testimonial-style-1 .testimonial .testimonial-star-icon ul {
    justify-content: center;
    margin-bottom: 0; }
  .testimonial-style-1 .testimonial .testimonial-author {
    margin-top: 10px; }
    .testimonial-style-1 .testimonial .testimonial-author .testimonial-name .author-tittle {
      font-size: 18px; }
    .testimonial-style-1 .testimonial .testimonial-author .testimonial-name span {
      margin-left: 0;
      padding-left: 0; }
      .testimonial-style-1 .testimonial .testimonial-author .testimonial-name span:before {
        content: inherit; }

.owl-carousel.testimonial-style-1 .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px; }
  .owl-carousel.testimonial-style-1 .owl-dots button.owl-dot span {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-image: url(../images/avatar/01.jpg);
    background-size: cover;
    background-position: center; }
  .owl-carousel.testimonial-style-1 .owl-dots button.owl-dot:first-child span {
    background-image: url(../images/avatar/02.jpg); }
  .owl-carousel.testimonial-style-1 .owl-dots button.owl-dot:last-child span {
    margin-right: 0px;
    background-image: url(../images/avatar/03.jpg); }
  .owl-carousel.testimonial-style-1 .owl-dots button.owl-dot.active span {
    height: 80px;
    width: 80px;
    border-radius: 100%; }
    .owl-carousel.testimonial-style-1 .owl-dots button.owl-dot.active span:before {
      content: inherit; }

.owl-carousel.testimonial-style-1.align-left .testimonial {
  text-align: left; }
  .owl-carousel.testimonial-style-1.align-left .testimonial .testimonial-quote-icon {
    margin: 0; }
  .owl-carousel.testimonial-style-1.align-left .testimonial .testimonial-star-icon ul {
    justify-content: flex-start; }

.owl-carousel.testimonial-style-1.align-left .owl-dots {
  justify-content: flex-start; }

.testimonial.testimonial-style-2 {
  background: #ffffff;
  padding: 40px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  margin-bottom: 20px;
  border-radius: 5px; }
  .testimonial.testimonial-style-2 .testimonial-content p {
    color: #858585;
    font-size: 16px;
    line-height: 28px; }
  .testimonial.testimonial-style-2 .testimonial-quote-icon {
    background: #ffffff;
    right: 20px;
    top: 10px;
    opacity: 0.1;
    width: 68px; }
  .testimonial.testimonial-style-2 .testimonial-author {
    margin-top: 0px; }
    .testimonial.testimonial-style-2 .testimonial-author .avatar img {
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1); }
    .testimonial.testimonial-style-2 .testimonial-author .testimonial-name .author-tittle {
      color: #091c3a; }
      .testimonial.testimonial-style-2 .testimonial-author .testimonial-name .author-tittle:before {
        background: inherit; }
    .testimonial.testimonial-style-2 .testimonial-author .testimonial-name span:before {
      background: #000000; }

/*****************************
  Destination
*****************************/
.destination-plan .destination-icon {
  overflow: hidden;
  border-radius: 100%; }
  .destination-plan .destination-icon img {
    transform: scale(1);
    transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    min-height: 100%;
    object-fit: cover; }

.destination-plan .destination-content {
  height: 90%;
  width: 90%;
  background: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0.1); }
  .destination-plan .destination-content img {
    width: 50px;
    height: auto; }
  .destination-plan .destination-content a {
    font-family: "Jost", sans-serif;
    color: #000000;
    font-weight: 600;
    font-size: 24px; }
    .destination-plan .destination-content a:hover {
      color: #14357b; }

.destination-plan:hover .destination-content {
  opacity: 1; }

.destination-plan:hover .destination-icon img {
  transform: scale(1.1); }

/*Destination-style-02*/
.destination-plan.destination-style-02 {
  overflow: hidden; }
  .destination-plan.destination-style-02 .destination-icon {
    overflow: hidden;
    border-radius: 5px; }
    .destination-plan.destination-style-02 .destination-icon img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover; }
  .destination-plan.destination-style-02 .destination-content {
    height: auto;
    width: 100%;
    background: inherit;
    margin: 0 auto;
    border-radius: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: inherit;
    bottom: -45px;
    transform: inherit;
    opacity: 1;
    transition: all 0.7s ease;
    display: block;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 9; }
    .destination-plan.destination-style-02 .destination-content img {
      width: 50px;
      height: auto;
      margin: 0 auto; }
    .destination-plan.destination-style-02 .destination-content a {
      font-family: "Jost", sans-serif;
      color: #ffffff;
      font-weight: 600;
      font-size: 24px; }
      .destination-plan.destination-style-02 .destination-content a:hover {
        color: #f9d448; }
    .destination-plan.destination-style-02 .destination-content a.btn {
      font-family: "Nunito", sans-serif;
      font-size: 14px; }
      .destination-plan.destination-style-02 .destination-content a.btn:hover {
        color: #f9d448; }
    .destination-plan.destination-style-02 .destination-content .destination-round {
      margin-bottom: 20px; }
  .destination-plan.destination-style-02:before {
    position: absolute;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
    left: 0;
    width: 100%;
    height: 75%;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease; }
  .destination-plan.destination-style-02:hover .destination-content {
    bottom: 20px; }
  .destination-plan.destination-style-02:hover .destination-icon img {
    transform: scale(1.1); }
  .destination-plan.destination-style-02:hover:before {
    height: 100%; }

.destination-section .owl-carousel.owl-nav-center .owl-nav .owl-next {
  right: 0;
  opacity: 0;
  transition: all 0.5s ease; }

.destination-section .owl-carousel.owl-nav-center .owl-nav .owl-prev {
  left: 0;
  opacity: 0;
  transition: all 0.5s ease; }

.destination-section .owl-carousel.owl-nav-center:hover .owl-nav .owl-next {
  right: 15px;
  opacity: 1; }

.destination-section .owl-carousel.owl-nav-center:hover .owl-nav .owl-prev {
  left: 15px;
  opacity: 1; }

/*Destination Price*/
.destination-price-item {
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .destination-price-item .destination-img {
    position: relative;
    border-radius: 5px;
    overflow: hidden; }
    .destination-price-item .destination-img:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba(6, 16, 54, 0) 0, #061036 100%);
      top: auto;
      height: 0%;
      transition: all .35s;
      border-radius: 5px; }
    .destination-price-item .destination-img img {
      border-radius: 5px; }
    .destination-price-item .destination-img .rating {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9; }
      .destination-price-item .destination-img .rating a {
        color: #ffffff;
        background-color: #f9d448;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 3px; }
        .destination-price-item .destination-img .rating a i {
          font-size: 10px; }
        .destination-price-item .destination-img .rating a:hover {
          color: #061036; }
    .destination-price-item .destination-img .destination-info {
      position: absolute;
      z-index: 9;
      padding: 25px;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      transition: all 0.3s ease-in-out;
      width: 90%;
      left: 5%;
      bottom: 20px; }
      .destination-price-item .destination-img .destination-info .destination-title {
        font-size: 22px;
        color: #3664d1;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex; }
        .destination-price-item .destination-img .destination-info .destination-title:hover {
          color: #f9d448; }
      .destination-price-item .destination-img .destination-info .destination-price {
        font-size: 18px;
        color: #484848; }
        .destination-price-item .destination-img .destination-info .destination-price:hover {
          color: #14357b; }
      .destination-price-item .destination-img .destination-info .loaction {
        position: absolute;
        opacity: 0;
        width: 80%;
        transition: all 0.5s ease-in-out; }
        .destination-price-item .destination-img .destination-info .loaction a {
          color: #3664d1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0 0;
          display: inline-block;
          max-width: 100%;
          font-size: 16px; }
          .destination-price-item .destination-img .destination-info .loaction a:hover {
            color: #14357b; }
  .destination-price-item:hover .destination-img:before {
    height: 100%; }
  .destination-price-item:hover .destination-img .destination-info {
    padding-bottom: 60px; }
    .destination-price-item:hover .destination-img .destination-info .loaction {
      opacity: 1; }

/*Destination Trips*/
.destination-trips-item {
  overflow: hidden; }
  .destination-trips-item .trips-img {
    position: relative; }
    .destination-trips-item .trips-img:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba(6, 16, 54, 0) 0, rgba(6, 16, 54, 0.6) 100%);
      top: auto;
      height: 75%;
      transition: all .35s; }
    .destination-trips-item .trips-img img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      min-height: 100%;
      object-fit: cover; }
    .destination-trips-item .trips-img .trips-content {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 30px 25px;
      width: 100%;
      z-index: 9; }
      .destination-trips-item .trips-img .trips-content .trips-title {
        color: #ffffff;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex; }
        .destination-trips-item .trips-img .trips-content .trips-title:hover {
          color: #f9d448; }
      .destination-trips-item .trips-img .trips-content .trips-info {
        font-size: 14px;
        color: #ffffff;
        display: flex; }
        .destination-trips-item .trips-img .trips-content .trips-info:hover {
          color: #f9d448; }
  .destination-trips-item:hover .trips-img img {
    transform: scale(1.1); }
  .destination-trips-item:hover .trips-img:before {
    height: 100%;
    background: linear-gradient(to bottom, rgba(6, 16, 54, 0) 0, rgba(6, 16, 54, 0.8) 100%); }

/*Destination Trips*/
.destination-trips-main {
  position: relative; }
  .destination-trips-main .destination-trips-content {
    position: absolute;
    top: 0;
    left: 16.66%;
    width: 16.66%;
    z-index: 99;
    height: 100%;
    background-color: #091c3a;
    color: #ffffff;
    padding: 50px 50px;
    text-align: center; }
    .destination-trips-main .destination-trips-content .content img {
      height: 50px; }
    .destination-trips-main .destination-trips-content .content h5 {
      font-size: 20px;
      line-height: 40px;
      font-weight: bold; }
  .destination-trips-main .destination-trips-item.trips-style-2 {
    overflow: hidden;
    border-radius: 5px; }
    .destination-trips-main .destination-trips-item.trips-style-2 .trips-img {
      position: relative;
      border-radius: 5px; }
      .destination-trips-main .destination-trips-item.trips-style-2 .trips-img:before {
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, black 100%);
        top: auto;
        height: 50%;
        transition: all .35s; }
      .destination-trips-main .destination-trips-item.trips-style-2 .trips-img img {
        transform: scale(1);
        transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        min-height: 100%;
        object-fit: cover;
        border-radius: 5px; }
      .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .video a {
        color: #091c3a;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 100%;
        z-index: 99;
        display: flex;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
        .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .video a:before {
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 50px;
          height: 50px;
          background: #ffffff;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite; }
      .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px 25px 20px;
        width: 100%;
        z-index: 9;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
        .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content .rating ul li a {
          font-size: 14px;
          color: #f9d448; }
        .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content .trips-title {
          color: #ffffff;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 10px;
          display: flex; }
          .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content .trips-title:hover {
            color: #f9d448; }
        .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content .trips-price {
          font-size: 16px;
          color: #ffffff;
          display: flex; }
          .destination-trips-main .destination-trips-item.trips-style-2 .trips-img .trips-content .trips-price:hover {
            color: #f9d448; }
    .destination-trips-main .destination-trips-item.trips-style-2:hover .trips-img img {
      transform: scale(1.1); }
    .destination-trips-main .destination-trips-item.trips-style-2:hover .trips-img:before {
      height: 100%; }
    .destination-trips-main .destination-trips-item.trips-style-2:hover .video a {
      opacity: 1; }
    .destination-trips-main .destination-trips-item.trips-style-2:hover .trips-content {
      opacity: 1; }
    .destination-trips-main .destination-trips-item.trips-style-2 .destination-trips-content {
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(20, 53, 123, 0.9);
      color: #ffffff;
      padding: 50px 50px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
      .destination-trips-main .destination-trips-item.trips-style-2 .destination-trips-content .content img {
        height: 50px;
        transform: scale(1); }
      .destination-trips-main .destination-trips-item.trips-style-2 .destination-trips-content .content h5 {
        font-size: 20px;
        line-height: 40px;
        font-weight: bold; }
      .destination-trips-main .destination-trips-item.trips-style-2 .destination-trips-content .content .btn.btn-link {
        color: #ffffff; }
        .destination-trips-main .destination-trips-item.trips-style-2 .destination-trips-content .content .btn.btn-link:hover {
          color: #f9d448; }
  .destination-trips-main .destination-trips-item.trips-style-2.active .trips-content {
    opacity: 1; }
  .destination-trips-main .destination-trips-item.trips-style-2.active .video a {
    opacity: 1; }
  .destination-trips-main .owl-carousel {
    z-index: inherit; }
    .destination-trips-main .owl-carousel .owl-nav {
      position: absolute;
      left: 22%;
      bottom: 15%;
      z-index: 99;
      display: flex; }
      .destination-trips-main .owl-carousel .owl-nav button {
        margin: 0 5px; }

/*Destination-plan-2*/
.destination-plan-2 {
  max-height: 630px;
  overflow-y: auto; }
  .destination-plan-2 .nav-link {
    width: 50%; }
    .destination-plan-2 .nav-link .listing-details {
      padding: 200px 40px 40px;
      transition: all 0.5s ease-in-out;
      background-color: #3664d1;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      z-index: 2; }
      .destination-plan-2 .nav-link .listing-details:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(54, 100, 209, 0.3);
        z-index: -1;
        transition: all 0.5s ease-in-out; }
      .destination-plan-2 .nav-link .listing-details .listing-loaction span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%; }
      .destination-plan-2 .nav-link .listing-details .icon-location {
        width: 15px;
        filter: invert(100%) sepia(0%) saturate(23%) hue-rotate(4deg) brightness(105%) contrast(108%);
        transition: all 0.5s ease-in-out; }
      .destination-plan-2 .nav-link .listing-details span {
        font-size: 12px; }
      .destination-plan-2 .nav-link .listing-details .listing-title .title {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff; }
      .destination-plan-2 .nav-link .listing-details .listing-title .country-flags h6 {
        font-family: "Jost", sans-serif; }
      .destination-plan-2 .nav-link .listing-details:hover {
        color: #ffffff;
        background-color: #14357b; }
        .destination-plan-2 .nav-link .listing-details:hover .icon-location {
          filter: invert(98%) sepia(94%) saturate(2535%) hue-rotate(319deg) brightness(102%) contrast(103%); }
    .destination-plan-2 .nav-link:hover .listing-details .listing-title .title {
      color: #f9d448; }
    .destination-plan-2 .nav-link:hover .listing-details:before {
      background-color: rgba(6, 16, 54, 0.8); }
  .destination-plan-2 .nav-link.active .listing-details {
    background-color: #14357b; }
    .destination-plan-2 .nav-link.active .listing-details .listing-title .title {
      color: #f9d448; }
    .destination-plan-2 .nav-link.active .listing-details:before {
      background-color: rgba(6, 16, 54, 0.8);
      z-index: -1; }
  .destination-plan-2 .nav-link.active .icon-location {
    filter: invert(98%) sepia(94%) saturate(2535%) hue-rotate(319deg) brightness(102%) contrast(103%); }
  .destination-plan-2 .united-states .listing-details {
    background-image: url(../images/destination/24.jpg); }
  .destination-plan-2 .united-kingdom .listing-details {
    background-image: url(../images/destination/25.jpg); }
  .destination-plan-2 .india .listing-details {
    background-image: url(../images/destination/26.jpg); }
  .destination-plan-2 .mexico .listing-details {
    background-image: url(../images/destination/28.jpg); }
  .destination-plan-2 .malaysia .listing-details {
    background-image: url(../images/destination/30.jpg); }
  .destination-plan-2 .qatar .listing-details {
    background-image: url(../images/destination/33.jpg); }
  .destination-plan-2 .sri-lanka .listing-details {
    background-image: url(../images/destination/34.jpg); }
  .destination-plan-2 .switzerland .listing-details {
    background-image: url(../images/destination/35.jpg); }
  .destination-plan-2 .france .listing-details {
    background-image: url(../images/destination/37.jpg); }

.upcomming-plan {
  position: relative; }
  .upcomming-plan .upcomming {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 20px; }
    .upcomming-plan .upcomming .upcomming-box {
      padding: 20px;
      background: rgba(255, 255, 255, 0.85);
      height: 100%; }
      .upcomming-plan .upcomming .upcomming-box .upcomming-info {
        height: 100%;
        border: 3px dashed #14357b;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 30px; }
        .upcomming-plan .upcomming .upcomming-box .upcomming-info .upcomming-logo {
          width: 200px; }
        .upcomming-plan .upcomming .upcomming-box .upcomming-info .title {
          font-weight: 600;
          margin: 25px 0px; }

/*Destination Details*/
.destinations-details .img-details {
  position: relative;
  overflow: hidden;
  border-radius: 5px; }
  .destinations-details .img-details img {
    transform: scale(1);
    transition: -webkit-transform 7s;
    transition: transform 7s;
    transition: transform 7s, -webkit-transform 7s;
    min-height: 100%;
    object-fit: cover; }

.destinations-details .destinations-content {
  position: relative;
  z-index: 2;
  margin: -50px 20px 30px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  background-color: #ffffff; }
  .destinations-details .destinations-content .title {
    font-weight: 600; }

.destinations-details .country-info {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  background-color: #ffffff;
  margin-bottom: 30px; }
  .destinations-details .country-info .country-item {
    display: flex;
    padding: 20px 0; }
    .destinations-details .country-info .country-item .country-name {
      width: 45%; }
      .destinations-details .country-info .country-item .country-name .icon {
        font-size: 16px;
        margin-right: 20px;
        color: #14357b; }
    .destinations-details .country-info .country-item .description {
      width: 45%; }
    .destinations-details .country-info .country-item .flags {
      width: 10%;
      text-align: end; }

.destinations-details:hover .img-details img {
  transform: scale(1.1); }

/*Booking 03*/
.booking-03 {
  background-color: #ffffff;
  padding: 40px; }
  .booking-03 .success-message {
    padding: 10px;
    background-color: rgba(10, 187, 10, 0.15);
    border-radius: 5px; }
  .booking-03 .booking-info .booking-info-contant {
    padding: 40px;
    margin: -50px 40px 0;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    background-color: #ffffff; }
    .booking-03 .booking-info .booking-info-contant object.icon {
      width: 22px;
      filter: invert(52%) sepia(62%) saturate(510%) hue-rotate(179deg) brightness(98%) contrast(95%);
      margin-right: 10px; }
    .booking-03 .booking-info .booking-info-contant .coupon-form {
      width: 75%; }
      .booking-03 .booking-info .booking-info-contant .coupon-form .btn {
        padding: 12px 40px;
        display: flex;
        align-items: center; }

.destination-find-area {
  display: flex;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  padding: 10px; }
  .destination-find-area > div {
    width: 25%;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.04); }
  .destination-find-area .form-btn-are a {
    white-space: nowrap;
    width: 100%; }

/*Home 03*/
.destination-find-section {
  z-index: 9;
  position: relative;
  margin-top: -50px; }
  .destination-find-section .home-search {
    background-color: #ffffff;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .destination-find-section .destination-find-area {
    display: flex;
    background: inherit;
    border-radius: 30px;
    padding: 0px; }
    .destination-find-section .destination-find-area > div {
      width: 25%;
      box-shadow: inherit; }
    .destination-find-section .destination-find-area .form-btn-are a {
      white-space: nowrap;
      width: 100%; }
    .destination-find-section .destination-find-area .form-select.select-fast {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      height: 60px; }
    .destination-find-section .destination-find-area .select-fast .select2-container--default .select2-selection--single {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px; }
    .destination-find-section .destination-find-area .select2-container--default .select2-selection--single {
      border-radius: 0;
      height: 60px !important;
      line-height: 30px; }
      .destination-find-section .destination-find-area .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #858585; }
      .destination-find-section .destination-find-area .select2-container--default .select2-selection--single .select2-selection__arrow:before {
        color: #858585; }
    .destination-find-section .destination-find-area .input-group.date .input-group-text {
      border-radius: 0; }
    .destination-find-section .destination-find-area .btn {
      height: 60px;
      line-height: 30px;
      border-radius: 0;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px; }
      .destination-find-section .destination-find-area .btn:before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }

/*****************************
    Responsive
*****************************/
@media (max-width: 1600px) {
  .destination-trips-main .owl-carousel .owl-nav {
    position: absolute;
    left: 21%;
    bottom: 13%; }
  .destination-trips-main .destination-trips-content {
    padding: 20px; } }

@media (max-width: 1399px) {
  .destination-trips-main .destination-trips-content {
    width: 20%;
    left: 20%; }
  .destination-trips-main .owl-carousel .owl-nav {
    left: 25%; }
  .upcomming-plan .upcomming .upcomming-box .upcomming-info .upcomming-logo {
    width: 200px; } }

@media (max-width: 1299px) {
  .destination-trips-main .destination-trips-content {
    width: 25%;
    left: 25%; }
  .destination-trips-main .owl-carousel .owl-nav {
    left: 32%; } }

@media (max-width: 1199px) {
  .destination-trips-main .destination-trips-content {
    width: 33.33%;
    left: 0; }
  .destination-trips-main .owl-carousel .owl-nav {
    left: 11%; }
  .destination-plan-2 .listing-details {
    padding: 20px 25px; } }

@media (max-width: 991px) {
  .destination-trips-main .destination-trips-content {
    padding: 40px; }
  .destination-trips-main .owl-carousel .owl-nav button i {
    width: 40px;
    height: 40px; }
  .booking-03 {
    padding: 30px; }
    .booking-03 .booking-info .booking-info-contant {
      padding: 30px;
      margin: -40px 30px 0; }
  .destination-find-section .destination-find-area {
    flex-wrap: wrap;
    background: none;
    border-radius: inherit;
    grid-gap: 15px 0; }
    .destination-find-section .destination-find-area > div {
      width: 50%; }
    .destination-find-section .destination-find-area .select-secon .select2-container--default .select2-selection--single {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px; }
    .destination-find-section .destination-find-area .form-location #datetimepicker-01 .form-control {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px; }
    .destination-find-section .destination-find-area .form-location #datetimepicker-02 .input-group-text {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px; }
    .destination-find-section .destination-find-area .btn {
      border-radius: 30px; } }

@media (max-width: 767px) {
  .destination-trips-main .destination-trips-content {
    width: 50%;
    left: 0;
    padding: 15px; }
  .destination-trips-main .owl-carousel .owl-nav {
    left: 17%; }
    .destination-trips-main .owl-carousel .owl-nav button i {
      width: 35px;
      height: 35px; }
  .destination-trips-item .trips-img .trips-content {
    padding: 15px; }
  .destination-plan-2 .nav-link {
    width: 100%; }
    .destination-plan-2 .nav-link .listing-details {
      padding: 100px 40px 40px; }
  .destination-plan-2 .tab-content.map {
    max-height: 450px; }
  .booking-03 {
    padding: 20px; }
    .booking-03 .booking-info .booking-info-contant {
      padding: 20px;
      margin: -30px 20px 0; }
  .destination-find-area {
    flex-wrap: wrap;
    background: none;
    border-radius: inherit;
    grid-gap: 15px 0; }
    .destination-find-area > div {
      width: 50%; }
    .destination-find-area .select-secon .select2-container--default .select2-selection--single {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px; }
    .destination-find-area .form-location #datetimepicker-01 .form-control {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px; }
    .destination-find-area .form-location #datetimepicker-02 .input-group-text {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px; }
    .destination-find-area .btn {
      border-radius: 30px; }
  .categories-tab .nav-tabs .nav-item {
    width: 25%;
    margin-right: 3%;
    margin-bottom: 10px; }
  .destination-find-section {
    margin-top: 50px;
    margin-bottom: 50px; } }

@media (max-width: 575px) {
  .booking-03 .booking-info .booking-info-contant .coupon-form {
    width: 100%; }
  .destination-find-area > div {
    width: 100%; }
  .upcomming-plan .upcomming {
    position: inherit; }
  .categories-tab .nav-tabs .nav-item {
    width: 30%;
    margin-right: 3%;
    margin-bottom: 10px; }
  .destination-find-section {
    margin-top: 40px;
    margin-bottom: 0px; } }

@media (max-width: 480px) {
  .destination-trips-main .destination-trips-content {
    display: none; }
  .destination-trips-main .owl-carousel .owl-nav {
    display: none; }
  .categories-tab .nav-tabs .nav-item {
    width: 47%;
    margin-right: 3%;
    margin-bottom: 10px; }
  .destination-find-section .home-search {
    padding: 40px 20px; }
  .destination-find-section .destination-find-area > div {
    width: 100%; }
  .destination-find-section .destination-find-area .select-fast .select2-container--default .select2-selection--single {
    border-radius: 30px; }
  .destination-find-section .destination-find-area .select-secon .select2-container--default .select2-selection--single {
    border-radius: 30px; }
  .destination-find-section .destination-find-area .form-location #datetimepicker-01 .input-group-text {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px; }
  .destination-find-section .destination-find-area .form-location #datetimepicker-01 .form-control {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px; }
  .destination-find-section .destination-find-area .form-location #datetimepicker-02 .form-control {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px; }
  .destination-find-section .destination-find-area .btn {
    border-radius: 30px; } }

/*****************************
  Blog
*****************************/
.blog .blog-img {
  position: relative; }
  .blog .blog-img .time-card {
    position: absolute;
    background-color: #ffffff;
    top: 15px;
    padding: 8px;
    border-radius: 5px;
    left: 15px;
    color: #14357b;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    display: inline-flex; }
    .blog .blog-img .time-card img {
      height: 20px;
      width: 20px;
      transform: inherit;
      transition: inherit;
      min-height: inherit;
      object-fit: inherit; }

.blog .blog-body {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 5px;
  margin: -50px 15px 30px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  position: relative; }
  .blog .blog-body .time-card img {
    height: 20px;
    width: 20px; }
  .blog .blog-body span {
    font-size: 14px;
    line-height: 24px; }
  .blog .blog-body .user .user-img img {
    height: 20px;
    width: 20px;
    border-radius: 50%; }

.blog a {
  margin-top: 10px;
  font-weight: 600;
  line-height: 34px;
  font-size: 24px;
  color: #14357b;
  font-family: "Jost", sans-serif;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .blog a:hover {
    color: #3664d1; }

/*Blog Detail*/
.blog-detail .blog .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px; }
  .blog-detail .blog .blog-img img {
    transform: scale(1);
    transition: transform 7s;
    min-height: 100%;
    object-fit: cover; }
  .blog-detail .blog .blog-img .time-card img {
    transform: inherit;
    transition: inherit;
    min-height: inherit;
    object-fit: inherit; }

.blog-detail:hover .blog-img img {
  transform: scale(1.1); }

.blog-style-2 .blog-img {
  min-width: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 5px; }
  .blog-style-2 .blog-img img {
    transform: scale(1);
    transition: transform 3s; }
    .blog-style-2 .blog-img img:hover {
      transform: scale(1.1); }

.blog-style-2 .blog-body {
  padding: 0;
  margin: 0;
  box-shadow: inherit; }
  .blog-style-2 .blog-body a {
    font-weight: 500;
    line-height: 24px;
    font-size: 16px; }
    .blog-style-2 .blog-body a:hover {
      color: #f9d448; }

.blog.blog-post-quote {
  position: relative;
  background-color: #14357b;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding: 50px; }
  .blog.blog-post-quote .blog-body {
    background-color: inherit;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    box-shadow: none; }
  .blog.blog-post-quote .time-card {
    position: absolute;
    background-color: #ffffff;
    top: 15px;
    padding: 8px;
    border-radius: 5px;
    right: 15px;
    color: #14357b;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    display: inline-flex; }
    .blog.blog-post-quote .time-card img {
      height: 20px;
      width: 20px;
      transform: inherit;
      transition: inherit;
      min-height: inherit;
      object-fit: inherit; }
  .blog.blog-post-quote .quote-icon i {
    font-size: 65px;
    color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 20px;
    left: 20px; }
  .blog.blog-post-quote a.title {
    color: #ffffff; }
    .blog.blog-post-quote a.title:hover {
      color: #f9d448; }

/*Blog Style Vertical */
.blog-style-vertical {
  height: 500px;
  overflow: auto; }
  .blog-style-vertical::-webkit-scrollbar {
    width: 7px;
    margin-right: 5px; }
  .blog-style-vertical::-webkit-scrollbar-thumb {
    background-color: #f3f9ff;
    border-radius: 5px;
    margin-right: 10px; }
    .blog-style-vertical::-webkit-scrollbar-thumb:hover {
      background-color: #ededed; }

.navigation.post-navigation .nav-links {
  display: flex; }
  .navigation.post-navigation .nav-links .nav-previous {
    width: 49%;
    border: 1px solid #ededed;
    margin-right: 15px;
    transition: all 0.5s ease-in-out;
    border-radius: 5px; }
    .navigation.post-navigation .nav-links .nav-previous a {
      display: flex;
      color: #3664d1; }
      .navigation.post-navigation .nav-links .nav-previous a .pagi-text {
        color: #3664d1;
        transition: all 0.5s ease-in-out;
        display: inline-block;
        padding: 12px 25px;
        border-right: 1px solid #ededed;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-radius: 5px; }
      .navigation.post-navigation .nav-links .nav-previous a .nav-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: auto; }
    .navigation.post-navigation .nav-links .nav-previous:hover {
      border: 1px solid #f3f9ff;
      background-color: #f3f9ff;
      transition: all 0.5s ease-in-out; }
      .navigation.post-navigation .nav-links .nav-previous:hover .pagi-text {
        background-color: #14357b;
        color: #f9d448;
        transition: all 0.5s ease-in-out;
        border-right: 1px solid #14357b; }
  .navigation.post-navigation .nav-links .nav-next {
    width: 49%;
    border: 1px solid #ededed;
    margin-right: 15px;
    margin-right: 0;
    text-align: right;
    transition: all 0.5s ease-in-out;
    border-radius: 5px; }
    .navigation.post-navigation .nav-links .nav-next a {
      display: flex;
      color: #3664d1;
      padding-left: 15px; }
      .navigation.post-navigation .nav-links .nav-next a .nav-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: auto; }
      .navigation.post-navigation .nav-links .nav-next a .pagi-text {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border-left: 1px solid #ededed;
        transition: all 0.5s ease-in-out;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-radius: 5px; }
    .navigation.post-navigation .nav-links .nav-next:hover {
      border: 1px solid #f3f9ff;
      background-color: #f3f9ff;
      transition: all 0.5s ease-in-out; }
      .navigation.post-navigation .nav-links .nav-next:hover .pagi-text {
        background-color: #14357b;
        color: #f9d448;
        transition: all 0.5s ease-in-out;
        border-left: 1px solid #14357b; }
  .navigation.post-navigation .nav-links .nav-title {
    margin: 12px 20px; }
  .navigation.post-navigation .nav-links .pagi-text {
    padding: 12px 25px; }

.pagination {
  flex-wrap: wrap; }
  .pagination .page-item {
    margin: 0 5px 5px; }
    .pagination .page-item .page-link {
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #091c3a;
      background-color: transparent;
      border: 2px solid #ededed;
      border-radius: 5px; }
      .pagination .page-item .page-link span {
        font-size: 26px; }
      .pagination .page-item .page-link:hover {
        color: #14357b;
        background-color: transparent;
        border: 2px solid #14357b; }
        .pagination .page-item .page-link:hover:focus {
          box-shadow: none; }
    .pagination .page-item:not(:first-child) .page-link {
      margin-left: -1px; }
  .pagination .page-item.active .page-link {
    color: #14357b;
    background-color: transparent;
    border: 2px solid #14357b; }

@media (max-width: 575px) {
  .navigation.post-navigation .nav-links {
    display: block; }
    .navigation.post-navigation .nav-links .nav-previous {
      width: 100%; }
    .navigation.post-navigation .nav-links .nav-next {
      width: 100%; }
  .blog-style-2 .blog-img {
    min-width: 80px;
    width: 80px; } }

/*****************************
  Feature Box
*****************************/
.feature-box {
  padding: 40px; }
  .feature-box .icon-box {
    width: 71%; }

.feature-categories {
  padding: 20px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  color: #3664d1; }
  .feature-categories .icon {
    margin-bottom: 10px; }
    .feature-categories .icon object {
      width: 60px;
      transition: all 0.3s ease-in-out; }
  .feature-categories a {
    color: #3664d1;
    font-weight: 600; }
  .feature-categories:hover {
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    border-radius: 5px; }
    .feature-categories:hover .icon object {
      transition: all 0.3s ease-in-out; }
    .feature-categories:hover a {
      color: #14357b; }

.feature-section a:hover {
  color: #f9d448; }

.feature-section .newsletter {
  width: 80%; }
  .feature-section .newsletter .newsletter-form {
    width: 100%; }
    .feature-section .newsletter .newsletter-form .form-control {
      padding-right: 170px;
      width: 100%; }

/*Feature style 02*/
.feature-box.feature-box-2 {
  text-align: center;
  border: none;
  padding: 0;
  border-radius: 5px; }
  .feature-box.feature-box-2 .feature-info {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 15px;
    padding-top: 60px;
    padding-bottom: 25px;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative; }
    .feature-box.feature-box-2 .feature-info .icon-box {
      display: flex;
      flex: 0 0 80px;
      height: 80px;
      width: 80px;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      border-radius: 100%;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out; }
      .feature-box.feature-box-2 .feature-info .icon-box img {
        width: 40px;
        height: 40px; }
    .feature-box.feature-box-2 .feature-info .title {
      font-size: 18px; }
    .feature-box.feature-box-2 .feature-info h5 {
      padding: 15px 0;
      font-weight: 600; }

/*Feature Categories*/
.categories-section {
  z-index: 9;
  position: relative; }
  .categories-section .feature-categories-2 {
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    border-radius: 5px; }
    .categories-section .feature-categories-2 .feature-categories {
      padding: 40px 20px;
      overflow: hidden;
      background: #ffffff;
      position: relative;
      border-bottom: 5px solid transparent;
      z-index: 2; }
      .categories-section .feature-categories-2 .feature-categories:before {
        content: '';
        background: #14357b;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 0%;
        transition: all 490ms ease;
        z-index: -1;
        border-radius: 0; }
      .categories-section .feature-categories-2 .feature-categories:hover {
        border-bottom: 5px solid #3664d1;
        border-radius: 0; }
        .categories-section .feature-categories-2 .feature-categories:hover:before {
          height: 100%;
          top: 0px; }
        .categories-section .feature-categories-2 .feature-categories:hover .icon object {
          filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
          transition: all 0.3s ease-in-out; }
        .categories-section .feature-categories-2 .feature-categories:hover a {
          color: #ffffff; }
      .categories-section .feature-categories-2 .feature-categories .title {
        font-size: 18px; }

.feature-categories.feature-box-1 {
  position: relative;
  z-index: 2;
  border-radius: 5px;
  padding: 0; }
  .feature-categories.feature-box-1 .feature-img {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .feature-categories.feature-box-1 .feature-img img {
      border-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
  .feature-categories.feature-box-1 .feature-info {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 15px;
    padding-top: 60px;
    padding-bottom: 25px;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    transition: all 0.3s ease-in-out; }
    .feature-categories.feature-box-1 .feature-info .icon-box {
      display: flex;
      flex: 0 0 80px;
      height: 80px;
      width: 80px;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      border-radius: 100%;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out; }
      .feature-categories.feature-box-1 .feature-info .icon-box object {
        width: 30px;
        margin-bottom: 0px; }
    .feature-categories.feature-box-1 .feature-info .title {
      font-size: 18px; }
  .feature-categories.feature-box-1:hover {
    box-shadow: inherit; }
    .feature-categories.feature-box-1:hover .feature-info {
      padding-top: 25px; }
      .feature-categories.feature-box-1:hover .feature-info .icon-box {
        top: -100px; }

.feature-categories.feature-box-2 {
  position: relative;
  z-index: 2;
  border-radius: 5px;
  padding: 0; }
  .feature-categories.feature-box-2 .feature-img {
    border-radius: 5px; }
    .feature-categories.feature-box-2 .feature-img img {
      border-radius: 5px; }
  .feature-categories.feature-box-2 .feature-info {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 15px;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 100%;
    text-align: center; }
    .feature-categories.feature-box-2 .feature-info .icon-box {
      margin: 0 auto;
      margin-bottom: 8px; }
      .feature-categories.feature-box-2 .feature-info .icon-box object {
        width: 40px;
        margin-bottom: 0px; }
    .feature-categories.feature-box-2 .feature-info .title {
      font-size: 16px; }
  .feature-categories.feature-box-2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
    transition: all 0.3s ease-in-out;
    border-radius: 5px; }

.feature-categories.feature-box-3 {
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding: 0;
  position: relative; }
  .feature-categories.feature-box-3 .feature-img {
    position: relative;
    border-radius: 5px; }
    .feature-categories.feature-box-3 .feature-img img {
      border-radius: 5px; }
    .feature-categories.feature-box-3 .feature-img .feature-info {
      box-shadow: none;
      padding: 15px;
      border-radius: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 100%;
      text-align: center; }
      .feature-categories.feature-box-3 .feature-img .feature-info .icon-box {
        margin: 0 auto;
        margin-bottom: 8px; }
        .feature-categories.feature-box-3 .feature-img .feature-info .icon-box object {
          width: 40px;
          margin-bottom: 0px;
          filter: invert(6%) sepia(24%) saturate(7410%) hue-rotate(220deg) brightness(92%) contrast(102%);
          transition: all 0.3s ease-in-out; }
      .feature-categories.feature-box-3 .feature-img .feature-info .title {
        font-size: 18px; }
        .feature-categories.feature-box-3 .feature-img .feature-info .title a {
          color: #3664d1;
          transition: all 0.3s ease-in-out; }
          .feature-categories.feature-box-3 .feature-img .feature-info .title a:hover {
            color: #f9d448; }
  .feature-categories.feature-box-3:before {
    position: absolute;
    content: "";
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 5px;
    transition: all 0.3s ease-in-out; }
  .feature-categories.feature-box-3:hover:before {
    background-color: rgba(20, 53, 123, 0.95); }
  .feature-categories.feature-box-3:hover .feature-img .feature-info .icon-box object {
    filter: invert(97%) sepia(91%) saturate(7487%) hue-rotate(321deg) brightness(105%) contrast(95%); }
  .feature-categories.feature-box-3:hover .feature-img .feature-info .title a {
    color: #f9d448; }
    .feature-categories.feature-box-3:hover .feature-img .feature-info .title a:hover {
      color: #f9d448; }

.feature-box-4 {
  padding: 40px 50px;
  transition: all 0.3s ease-in-out;
  border-radius: 5px; }
  .feature-box-4 .feature-icon {
    margin-bottom: 30px; }
  .feature-box-4 .feature-title {
    font-weight: 600; }
  .feature-box-4:hover {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }

.categories-section .feature-categories-2 .owl-carousel .owl-stage-outer {
  border-radius: 5px; }

.categories-section .feature-categories-2 .owl-stage-outer {
  border-radius: 5px; }

/*****************************
    Responsive
*****************************/
@media (max-width: 1199px) {
  .feature-box {
    padding: 25px; }
  .feature-categories.feature-box-3 .feature-img .feature-info .title {
    font-size: 16px; } }

@media (max-width: 991px) {
  .feature-section .newsletter {
    width: 100%; }
  .feature-box.feature-box-2 {
    margin-bottom: 70px; }
  .feature-box-4 {
    padding: 30px 20px; } }

@media (max-width: 767px) {
  .feature-section img {
    width: 60px; } }

@media (max-width: 575px) {
  .feature-box {
    display: block;
    padding: 20px; }
    .feature-box .icon-box {
      display: block;
      width: 100%; }
  .feature-categories.feature-box-3 .feature-img .feature-info .icon-box object {
    width: 80px; } }

/*****************************
  video
*****************************/
.video-box img {
  width: 70px;
  height: 70px; }

.video-box .video-icon a i {
  width: 30px;
  height: 30px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #000000;
  font-size: 12px;
  transition: all 0.3s ease-in-out; }

.video-box .video-icon a:hover i {
  background: #14357b;
  color: #ffffff; }

.video-box .video-icon a:hover h6 {
  color: #14357b; }

.video-box h6 {
  font-size: 18px;
  padding-left: 15px;
  transition: all 0.3s ease-in-out; }

/*****************************
  Team
*****************************/
.team {
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .team .team-image {
    position: relative;
    overflow: hidden;
    border-radius: 5px; }
    .team .team-image .team-social {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
      content: "";
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(15, 15, 15, 0.75)));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.75) 100%);
      -webkit-transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      .team .team-image .team-social ul {
        position: relative;
        bottom: 30px;
        opacity: 0;
        transition: all 0.5s ease-in-out; }
        .team .team-image .team-social ul li a {
          margin: 0px 5px;
          background: #ffffff;
          color: #000000;
          padding: 10px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          position: relative;
          z-index: 1; }
          .team .team-image .team-social ul li a:hover {
            background: #14357b;
            color: #f9d448; }
    .team .team-image img {
      transform: scale(1);
      transition: transform 7s;
      border-radius: 5px; }
    .team .team-image .team-detail {
      padding-top: 20px;
      padding-bottom: 30px;
      position: absolute;
      left: 5%;
      width: 90%;
      bottom: -100%;
      z-index: 9;
      background-color: #ffffff;
      border-radius: 5px;
      transition: all 0.5s ease-in-out; }
      .team .team-image .team-detail .team-label {
        padding-bottom: 10px;
        display: block;
        font-size: 14px;
        font-weight: 500; }
      .team .team-image .team-detail .team-title {
        font-size: 22px;
        font-weight: 600;
        color: #3664d1; }
  .team:hover .team-image .team-social {
    opacity: 1; }
    .team:hover .team-image .team-social ul {
      bottom: 50%;
      opacity: 1; }
  .team:hover .team-image .team-detail {
    bottom: 20px; }

.team-sticky .team .team-img img {
  border-radius: 6px 6px 0px 0px; }

.team-sticky .team .team-info {
  padding: 35px; }

.team:hover .team-image img {
  transform: scale(1.1); }

.team-sticky.is-sticky {
  top: 100px; }

.team .team-info.clients-detail ul li {
  color: #ffffff; }

.team .team-info.clients-detail .social-icon ul li a {
  font-size: 16px;
  color: #ffffff;
  margin-right: 15px; }
  .team .team-info.clients-detail .social-icon ul li a:hover {
    color: #3664d1; }

.team-single .team-img {
  border-radius: 5px; }

.team-single .team-info {
  padding: 25px 30px;
  align-self: center;
  background-color: #14357b;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px;
  margin: 0 20px;
  margin-top: -100px;
  z-index: 2;
  position: relative; }
  .team-single .team-info ul li {
    color: #ffffff; }
    .team-single .team-info ul li strong i {
      color: #f9d448; }
  .team-single .team-info .social-icon ul li a {
    font-size: 16px;
    color: #ffffff;
    margin-right: 15px; }
    .team-single .team-info .social-icon ul li a:hover {
      color: #f9d448; }

@media (max-width: 767px) {
  .team-single .team-info {
    margin: 0 15px;
    margin-top: -50px; } }

/*****************************
  Owl carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit; }

.owl-carousel .owl-nav button {
  background-color: #ffffff !important;
  padding: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1);
  border-radius: 100%;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-nav button:hover {
    background-color: #14357b !important;
    color: #f9d448; }

.perfect-tour .owl-nav {
  position: absolute;
  top: -100px;
  right: 10px;
  display: flex; }

.owl-carousel .owl-dots {
  margin-top: 30px; }
  .owl-carousel .owl-dots button.owl-dot span {
    background-color: #3664d1;
    width: 10px;
    height: 10px;
    display: flex;
    border-radius: 100%;
    margin-right: 15px;
    transition: all 0.3s ease-in-out;
    position: relative; }
  .owl-carousel .owl-dots button.owl-dot.active span {
    background-color: #14357b; }
    .owl-carousel .owl-dots button.owl-dot.active span:before {
      position: absolute;
      content: "";
      top: -3px;
      left: -3px;
      height: 16px;
      width: 16px;
      border: 1px solid #14357b;
      border-radius: 100%; }

.owl-carousel.dark-bg button.owl-dot span {
  background-color: #ffffff; }

.bg-secondary .owl-carousel .owl-dots button.owl-dot span {
  background-color: #ffffff; }

.bg-secondary .owl-carousel .owl-dots button.owl-dot.active span {
  background-color: #14357b; }

.owl-carousel.owl-dots-bottom-center .owl-dots {
  text-align: center; }

.owl-carousel.owl-nav-center .owl-nav {
  width: 100%; }
  .owl-carousel.owl-nav-center .owl-nav .owl-next {
    top: 50%;
    right: -30px;
    position: absolute;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out; }
  .owl-carousel.owl-nav-center .owl-nav .owl-prev {
    top: 50%;
    left: -30px;
    position: absolute;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out; }

.owl-carousel .owl-nav .owl-next {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0; }
  .owl-carousel .owl-nav .owl-next i {
    color: #3664d1; }
  .owl-carousel .owl-nav .owl-next:hover i {
    color: #f9d448; }

.owl-carousel .owl-nav .owl-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0; }
  .owl-carousel .owl-nav .owl-prev i {
    color: #3664d1; }
  .owl-carousel .owl-nav .owl-prev:hover i {
    color: #f9d448; }

.owl-carousel.feature-categories-2 .owl-nav .owl-next {
  right: 0; }

.owl-carousel.feature-categories-2 .owl-nav .owl-prev {
  left: 0; }

.owl-carousel.feature-categories-2 .owl-nav button {
  opacity: 0; }

.owl-carousel.feature-categories-2:hover .owl-nav button {
  opacity: 1; }

.owl-carousel.feature-categories-2:hover .owl-nav .owl-next {
  right: -30px; }

.owl-carousel.feature-categories-2:hover .owl-nav .owl-prev {
  left: -30px; }

.blog-section .owl-nav {
  position: absolute;
  top: -70px;
  right: 0px;
  display: flex; }

@media (max-width: 991px) {
  .owl-carousel .owl-dots {
    margin-top: 30px; }
  .perfect-tour .owl-nav {
    display: none; }
  .blog-section .owl-nav {
    display: none; } }

@media (max-width: 767px) {
  .owl-carousel .owl-dots {
    margin-top: 20px; }
  .owl-carousel.owl-nav-center .owl-nav .owl-prev {
    display: none; }
  .owl-carousel.owl-nav-center .owl-nav .owl-next {
    display: none; } }

/*****************************
  Client Logo
*****************************/
.client-logo .owl-carousel .owl-item .item a {
  padding: 30px;
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center; }
  .client-logo .owl-carousel .owl-item .item a:hover {
    opacity: 1;
    box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }

/*****************************
  sidebar
*****************************/
.sidebar .widget {
  margin-top: 0px !important;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  margin-bottom: 30px; }
  .sidebar .widget .widget-title {
    padding: 15px 20px;
    background-color: #f1f8fe;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .sidebar .widget .widget-title .title {
      margin-bottom: 0px; }
  .sidebar .widget .widget-content {
    padding: 25px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .sidebar .widget .search {
    position: relative; }
    .sidebar .widget .search i {
      position: absolute;
      right: 0;
      top: 0;
      background: #14357b;
      color: #ffffff;
      padding: 17px 20px;
      cursor: pointer;
      margin: 0;
      border-radius: 0 6px 6px 0px; }
    .sidebar .widget .search input {
      padding-right: 64px;
      height: 50px;
      padding-left: 20px; }
  .sidebar .widget .social ul li a {
    color: #091c3a;
    font-weight: 600;
    text-transform: capitalize; }
  .sidebar .widget .price-filter {
    display: flex; }
    .sidebar .widget .price-filter a {
      margin-left: auto; }
  .sidebar .widget .product-rating {
    margin-bottom: 10px; }
    .sidebar .widget .product-rating:last-child {
      margin-bottom: 0; }
    .sidebar .widget .product-rating .product-rating-nav {
      display: inline-block; }
    .sidebar .widget .product-rating span {
      float: right; }
  .sidebar .widget .product-banner-thumb {
    position: relative; }
    .sidebar .widget .product-banner-thumb .product-banner-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      background: #3664d1;
      color: #ffffff;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .sidebar .widget .product-banner-thumb .product-banner-badge .badge-value {
        font-size: 20px;
        font-weight: 700;
        line-height: 1; }
  .sidebar .widget .form-check {
    display: block; }
    .sidebar .widget .form-check span {
      float: right; }
  .sidebar .widget .social ul {
    margin-bottom: 0; }
    .sidebar .widget .social ul li {
      display: inline-block;
      list-style-type: none;
      font-size: 16px;
      border-radius: 3px; }
  .sidebar .widget .tagcloud ul li {
    margin-bottom: 10px;
    display: inline-block; }
    .sidebar .widget .tagcloud ul li a {
      line-height: 32px;
      border: 1px solid #ededed;
      padding: 0 12px;
      border-radius: 5px;
      display: block; }
      .sidebar .widget .tagcloud ul li a:hover {
        background: #14357b;
        border: 1px solid #14357b;
        color: #ffffff; }
  .sidebar .widget .widget-categories .form-check {
    margin-bottom: 16px; }
    .sidebar .widget .widget-categories .form-check:last-child {
      margin-bottom: 0px; }
  .sidebar .widget .widget-categories ul li a {
    font-size: 14px;
    line-height: 32px;
    display: block; }
    .sidebar .widget .widget-categories ul li a:hover {
      color: #14357b; }
  .sidebar .widget .listing-item.listing-item-3 {
    padding: 0;
    border-radius: 0px;
    padding-bottom: 16px;
    box-shadow: none;
    border-bottom: 1px solid #ededed; }
    .sidebar .widget .listing-item.listing-item-3 .listing-details {
      padding: 0;
      padding-left: 15px; }
      .sidebar .widget .listing-item.listing-item-3 .listing-details .listing-rating {
        margin-bottom: 0; }
    .sidebar .widget .listing-item.listing-item-3:last-child {
      border-bottom: none;
      padding-bottom: 0px; }

.sidebar .widget .search {
  position: relative; }
  .sidebar .widget .search i {
    position: absolute;
    right: 0;
    top: 0;
    background: #14357b;
    color: #ffffff;
    padding: 17px 20px;
    cursor: pointer;
    margin: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 0.5s ease; }
    .sidebar .widget .search i:hover {
      background: #3664d1; }
  .sidebar .widget .search input {
    padding-right: 64px;
    height: 50px;
    padding-left: 20px;
    border-radius: 30px; }

.sidebar .widget ul.list-style li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #858585;
  width: 100%; }

.sidebar .widget .widget-categories .list-style-underline li a {
  padding: 5px 0;
  border-bottom: 1px solid #ededed; }
  .sidebar .widget .widget-categories .list-style-underline li a span {
    font-size: 10px; }

.sidebar .widget .widget-categories .list-style-underline li:last-child a {
  border-bottom: inherit; }

.sidebar .widget .social ul {
  margin-bottom: 0; }
  .sidebar .widget .social ul li {
    padding: 10px 10px 10px 20px;
    border: 1px solid #ededed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .sidebar .widget .social ul li:last-child {
      margin-bottom: 0; }

.sidebar .widget .social ul li .follow {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 3px 18px;
  font-size: 12px;
  border-radius: 3px; }
  .sidebar .widget .social ul li .follow:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #ffffff; }

.sidebar .widget .social ul .facebook {
  background-color: #466ca9;
  border-radius: 5px; }
  .sidebar .widget .social ul .facebook a {
    color: #ffffff; }

.sidebar .widget .social ul .twitter {
  background-color: #20b5e6;
  border-radius: 5px; }
  .sidebar .widget .social ul .twitter a {
    color: #ffffff; }

.sidebar .widget .social ul .youtube {
  background-color: #d92c20;
  border-radius: 5px; }
  .sidebar .widget .social ul .youtube a {
    color: #ffffff; }

.sidebar .widget .social ul .instagram {
  background-color: #cd486b;
  border-radius: 5px; }
  .sidebar .widget .social ul .instagram a {
    color: #ffffff; }

.sidebar .widget .social ul .linkedIn {
  background-color: #13799f;
  border-radius: 5px; }
  .sidebar .widget .social ul .linkedIn a {
    color: #ffffff; }

.sidebar .widget ul li .follow {
  background: #f3f9ff;
  padding: 3px 18px;
  font-size: 12px; }
  .sidebar .widget ul li .follow:hover {
    background: #466ca9;
    color: #ffffff; }

.sidebar .widget .popular-tag ul li {
  display: inline-block;
  margin-bottom: 10px; }
  .sidebar .widget .popular-tag ul li:last-child {
    margin-bottom: 0; }
  .sidebar .widget .popular-tag ul li a {
    padding: 6px 20px;
    display: block;
    font-size: 14px;
    border: 2px solid #ededed;
    color: #858585;
    border-radius: 30px; }
    .sidebar .widget .popular-tag ul li a:hover {
      color: #14357b;
      border-color: #14357b; }

.portfolio-detail .sidebar {
  background: #14357b;
  border-radius: 5px; }
  .portfolio-detail .sidebar .widget .items-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .portfolio-detail .sidebar .widget .items-info .title {
      color: #ffffff;
      flex-wrap: wrap;
      flex: 0 0 130px;
      font-weight: 600;
      margin-bottom: 0; }
    .portfolio-detail .sidebar .widget .items-info:last-child {
      padding: 0;
      margin: 0;
      border-bottom: none; }
  .portfolio-detail .sidebar .widget .social-icon ul li a {
    color: #ffffff; }

.portfolio-detail .portfolio-img {
  overflow: hidden;
  border-radius: 5px;
  position: relative; }
  .portfolio-detail .portfolio-img img {
    transform: scale(1);
    transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    min-height: 100%;
    object-fit: cover; }
  .portfolio-detail .portfolio-img:hover img {
    transform: scale(1.1); }

/*****************************
  Form
*****************************/
.sign-in {
  position: relative; }
  .sign-in .container {
    padding-top: 100px;
    padding-bottom: 100px; }
  .sign-in:before {
    position: absolute;
    content: "";
    height: 100%;
    background-image: url(../images/error/01.jpg);
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover; }
  .sign-in:after {
    position: absolute;
    content: "";
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(54, 100, 209, 0.8); }
  .sign-in .sign-in-bg {
    position: relative;
    z-index: 3; }
    .sign-in .sign-in-bg .sing-title .navbar-brand {
      width: 200px;
      display: inline-block;
      margin-bottom: 50px; }
    .sign-in .sign-in-bg .sing-title h1 {
      font-size: 48px;
      font-weight: bold;
      padding-bottom: 35px; }
    .sign-in .sign-in-bg .sing-title .btn {
      background: #ffffff;
      color: #14357b;
      margin-top: 35px; }
      .sign-in .sign-in-bg .sing-title .btn:after {
        background-color: #091c3a; }
      .sign-in .sign-in-bg .sing-title .btn:hover {
        color: #ffffff; }
  .sign-in .sign-in-content {
    padding: 50px;
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    border-radius: 5px;
    position: relative;
    z-index: 3; }
    .sign-in .sign-in-content .section-title h2 {
      font-size: 48px;
      font-weight: bold;
      color: #14357b; }
    .sign-in .sign-in-content .form-flat-style .from-control {
      background-color: #ffffff;
      border-color: #ededed; }
    .sign-in .sign-in-content .login-social-media form .btn {
      padding: 15px 20px;
      font-size: 14px; }
    .sign-in .sign-in-content .form-flat-style .form-control {
      background: #ffffff; }

.sign-in.sign-up {
  position: relative; }
  .sign-in.sign-up:before {
    left: inherit;
    right: 0; }
  .sign-in.sign-up:after {
    left: inherit;
    right: 0; }

.sign-up .sign-in-content {
  padding: 50px; }

.datetimepicker #datetimepicker-02 .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

@media (max-width: 1600px) {
  .sign-in .sign-in-content {
    padding: 50px; }
  .sign-up .sign-in-content {
    padding: 50px; } }

@media (max-width: 1366px) {
  .sign-in .sign-in-content {
    padding: 50px; } }

@media (max-width: 1199px) {
  .sign-in,
  .sign-in.sign-up {
    height: auto !important;
    position: relative; }
    .sign-in .container,
    .sign-in.sign-up .container {
      padding-top: 50px;
      padding-bottom: 50px; }
    .sign-in:before,
    .sign-in.sign-up:before {
      left: inherit;
      right: 0;
      width: 100%; }
    .sign-in:after,
    .sign-in.sign-up:after {
      left: inherit;
      right: 0;
      width: 100%; } }

@media (max-width: 767px) {
  .sign-in-content .login-social-media form .btn {
    padding: 15px 7px;
    font-size: 14px; }
  .sign-in .sign-in-content .section-title h2 {
    font-size: 32px; }
  .sign-in .sign-in-content .login-social-media form .btn {
    padding: 13px 22px; } }

@media (max-width: 479px) {
  .sign-in .sign-in-bg .sing-title h1 {
    font-size: 32px; }
  .sign-in .sign-in-content {
    padding: 40px; }
    .sign-in .sign-in-content .section-title h2 {
      font-size: 32px; }
    .sign-in .sign-in-content .login-social-media form .btn {
      padding: 13px 22px; } }

/*****************************
Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 30px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #f3f9ff;
    font-weight: normal;
    line-height: inherit;
    text-transform: capitalize; }

.bigdrop {
  min-width: 196px !important; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  transition: all 0.3s ease-in-out; }

input[type="radio"]:radio {
  background-color: #14357b; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 14px;
  right: 10px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #858585; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #ededed; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f3f9ff;
  transition: all 0.3s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(20, 53, 123, 0.1);
  color: #14357b;
  transition: all 0.3s ease-in-out; }

.select2-dropdown {
  border: none;
  border-radius: 5px;
  z-index: 9; }

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #ededed;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 30px; }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px; }

.select2-results__option[aria-selected] {
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  font-size: 14px; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #858585; }

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid rgba(61, 209, 213, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ededed;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

.select2-container--default .select2-selection--single {
  height: 50px;
  padding: 15px 20px; }

.custom-control-input:radio {
  background-color: #14357b; }

.newsletter .search input::placeholder {
  color: #ffffff; }

.form-check .form-check-input {
  float: inherit; }

.form-check-input:checked {
  background-color: #14357b;
  border-color: #14357b;
  box-shadow: inherit; }

/*****************************
  Portfolio
*****************************/
.filters-group {
  display: table;
  margin: 0 auto 30px;
  text-align: center; }
  .filters-group button {
    border: none;
    z-index: 1;
    position: relative;
    font-size: 16px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    padding: 15px 40px;
    overflow: hidden;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    background-color: #14357b;
    color: #ffffff;
    margin: 0 5px; }
    .filters-group button:hover {
      background-color: #14357b;
      color: #f9d448; }
    .filters-group button:focus {
      box-shadow: none;
      outline: none;
      color: #ffffff; }
    .filters-group button:active {
      box-shadow: none;
      outline: none;
      color: #ffffff; }
  .filters-group button.active {
    background-color: #14357b;
    color: #f9d448; }

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px; }
  .my-shuffle-container .grid-item {
    width: 33.33%;
    padding: 15px; }

.my-shuffle-container .portfolio {
  margin-bottom: 0; }

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 .grid-item {
  width: 100%;
  padding: 15px; }

.my-shuffle-container.columns-2 .grid-item {
  width: 50%;
  padding: 15px;
  float: left; }

.my-shuffle-container.columns-3 .grid-item {
  width: 33.33333333%;
  padding: 15px; }

.my-shuffle-container.columns-4 .grid-item {
  width: 25%;
  padding: 10px; }

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 15px; }

.my-shuffle-container.columns-6 .grid-item {
  width: 16.666666%;
  padding: 0px; }

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%; }

.portfolio-detail .items-info-box {
  background-color: #ffffff;
  padding: 50px;
  margin: 0 25px;
  margin-bottom: 20px;
  margin-top: -50px;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  display: flex;
  flex-wrap: wrap; }
  .portfolio-detail .items-info-box .items-info {
    width: 33%;
    margin-bottom: 30px; }
    .portfolio-detail .items-info-box .items-info:last-child {
      margin-bottom: 0px; }
    .portfolio-detail .items-info-box .items-info .title {
      font-size: 24px;
      font-weight: 600; }
    .portfolio-detail .items-info-box .items-info a {
      color: #858585; }
      .portfolio-detail .items-info-box .items-info a:hover {
        color: #14357b; }

.portfolio-detail .owl-carousel .portfolio {
  margin-bottom: 20px; }

.portfolio-single-bg {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding: 10px;
  background-color: #ffffff; }

.portfolio {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .portfolio .portfolio-title {
    display: block;
    color: #f3f9ff;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%; }
    .portfolio .portfolio-title:hover {
      color: #14357b; }
  .portfolio .portfolio-services {
    display: block;
    margin-bottom: 10px; }
    .portfolio .portfolio-services:hover {
      color: #f3f9ff; }
  .portfolio .portfolio-info {
    padding: 20px;
    width: calc(100% - 30px);
    position: absolute;
    left: 50%;
    bottom: 15px;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0%);
    background: #ffffff; }
    .portfolio .portfolio-info p {
      max-height: 66px;
      overflow: auto; }
  .portfolio:hover .portfolio-info {
    opacity: 1;
    bottom: 20px; }
  .portfolio .portfolio-lg + .portfolio-info p {
    max-height: 340px;
    overflow: auto; }

/* style 01 */
.portfolio-style-01 {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding: 10px;
  background-color: #ffffff; }
  .portfolio-style-01 .portfolio-img {
    position: relative; }
    .portfolio-style-01 .portfolio-img:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      display: block;
      z-index: 3;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #061036 100%);
      transition: all 0.3s ease-in-out;
      cursor: pointer; }
  .portfolio-style-01:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    transition: all 0.4s ease-in-out; }
  .portfolio-style-01 .portfolio-info {
    background: #ffffff;
    border-radius: 5px;
    opacity: 0;
    width: calc(100% - 40px);
    transition: all 0.5s ease-in-out; }
    .portfolio-style-01 .portfolio-info p {
      color: #ffffff; }
    .portfolio-style-01 .portfolio-info .portfolio-services {
      color: #3664d1; }
      .portfolio-style-01 .portfolio-info .portfolio-services:hover {
        color: #f9d448; }
    .portfolio-style-01 .portfolio-info .portfolio-title {
      color: #14357b; }
      .portfolio-style-01 .portfolio-info .portfolio-title:hover {
        color: #f9d448; }
  .portfolio-style-01 .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px; }
  .portfolio-style-01:hover:before {
    height: auto;
    transition: all 0.4s ease-in-out; }
  .portfolio-style-01:hover .portfolio-img:before {
    height: 100%; }
  .portfolio-style-01:hover .portfolio-info {
    z-index: 9; }

/*portfolio-style-02*/
.portfolio-style-02 .portfolio-info {
  border-radius: 5px; }
  .portfolio-style-02 .portfolio-info:hover .portfolio-title:hover {
    color: #3664d1 !important; }

/*portfolio-style-03*/
.portfolio-style-03 {
  position: relative;
  overflow: inherit;
  border-radius: 5px;
  box-shadow: none; }
  .portfolio-style-03 .portfolio-images {
    position: relative;
    border-radius: 5px; }
  .portfolio-style-03 .portfolio-img {
    border-radius: 5px;
    overflow: hidden;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
    .portfolio-style-03 .portfolio-img img {
      transition: all 500ms ease; }
  .portfolio-style-03 .portfolio-content {
    position: relative;
    width: 90%;
    z-index: 9;
    padding: 25px;
    margin: 0 auto;
    margin-top: -50px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
    .portfolio-style-03 .portfolio-content .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%; }
    .portfolio-style-03 .portfolio-content p {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
  .portfolio-style-03 .portfolio-info-02 {
    background: transparent; }
    .portfolio-style-03 .portfolio-info-02 .popup-icon {
      width: 50px;
      height: 50px;
      flex: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      position: absolute;
      top: 40%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      margin: 0 auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.7s ease-in-out;
      background: #ffffff;
      z-index: 9; }
  .portfolio-style-03:hover img {
    transform: scale(1.01); }
  .portfolio-style-03:hover .portfolio-info-02 .popup-icon {
    opacity: 1;
    top: 50%;
    visibility: visible; }
  .portfolio-style-03 img {
    transform: scale(1);
    transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

/*Portfolio Style Morden*/
.portfolio-style-morden .portfolio {
  overflow: visible;
  padding: 10px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .portfolio-style-morden .portfolio .portfolio-img {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.28s ease;
    z-index: 2; }
    .portfolio-style-morden .portfolio .portfolio-img:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 85%;
      display: block;
      z-index: 1;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.75) 100%);
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer; }
    .portfolio-style-morden .portfolio .portfolio-img:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0%;
      display: block;
      z-index: 8;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #061036 100%);
      transition: all 0.5s ease-in-out;
      cursor: pointer; }
  .portfolio-style-morden .portfolio:hover .portfolio-img:after {
    height: 100%; }
  .portfolio-style-morden .portfolio .portfolio-info {
    background: none !important; }
  .portfolio-style-morden .portfolio .portfolio-title {
    font-size: 24px;
    font-weight: 600;
    font-family: "Jost", sans-serif;
    color: #ffffff;
    margin-bottom: 5px; }
    .portfolio-style-morden .portfolio .portfolio-title:hover {
      color: #f9d448; }
  .portfolio-style-morden .portfolio .portfolio-services {
    color: #ffffff;
    opacity: 0.7; }
    .portfolio-style-morden .portfolio .portfolio-services:hover {
      color: #f9d448;
      opacity: 1; }

/*Portfolio Style Fancy*/
.portfolio-style-fancy .portfolio {
  overflow: inherit; }
  .portfolio-style-fancy .portfolio .portfolio-img {
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px; }
  .portfolio-style-fancy .portfolio .portfolio-info {
    position: absolute;
    display: block;
    width: 90%;
    height: auto;
    bottom: -20px;
    left: 5%;
    padding: 20px 20px;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out; }
    .portfolio-style-fancy .portfolio .portfolio-info .portfolio-info-wrapper {
      opacity: 0;
      -webkit-transform: translateY(-150%);
      -ms-transform: translateY(-150%);
      transform: translateY(-150%);
      -webkit-transition: opacity 0.4s cubic-bezier(0.16, 0.85, 0.45, 1), -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
      transition: opacity 0.4s cubic-bezier(0.16, 0.85, 0.45, 1), -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
      transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.4s cubic-bezier(0.16, 0.85, 0.45, 1); }
    .portfolio-style-fancy .portfolio .portfolio-info .portfolio-title {
      font-size: 20px;
      font-weight: 600;
      font-family: "Jost", sans-serif;
      margin-bottom: 5px;
      color: #14357b; }
      .portfolio-style-fancy .portfolio .portfolio-info .portfolio-title:hover {
        color: #3664d1; }
    .portfolio-style-fancy .portfolio .portfolio-info .portfolio-services {
      color: #858585; }
      .portfolio-style-fancy .portfolio .portfolio-info .portfolio-services:hover {
        color: #14357b; }
  .portfolio-style-fancy .portfolio:hover .portfolio-info {
    bottom: 20px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1); }
    .portfolio-style-fancy .portfolio:hover .portfolio-info .portfolio-info-wrapper {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: 0.5s cubic-bezier(0.16, 0.85, 0.45, 1) 0.2s;
      transition: 0.5s cubic-bezier(0.16, 0.85, 0.45, 1) 0.2s; }

/*portfolio-style-gallery*/
.portfolio-style-gallery .portfolio.portfolio-style-03 {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .portfolio-style-gallery .portfolio.portfolio-style-03 .portfolio-images:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    display: block;
    z-index: 8;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #061036 100%);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 5px; }
  .portfolio-style-gallery .portfolio.portfolio-style-03:hover .portfolio-images:before {
    height: 100%; }
  .portfolio-style-gallery .portfolio.portfolio-style-03:hover .portfolio-images img {
    transform: scale(1); }

/* Responsive */
@media (max-width: 1199px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 25%; } }

@media (max-width: 991px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%; }
  .filters-group button {
    margin: 0 5px 10px; }
  .filters-group button:first-child {
    margin-left: 0; }
  .filters-group button:last-child {
    margin-right: 0; } }

@media (max-width: 767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%; }
  .portfolio-detail .items-info-box .items-info {
    width: 50%; } }

@media (max-width: 575px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%; }
  .my-shuffle-container {
    margin: 0; }
  .portfolio-detail .items-info-box .items-info {
    width: 100%; } }

/*****************************
  Header
*****************************/
/* Header default */
.header {
  background: #ffffff;
  z-index: 999;
  position: relative;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.02); }
  .header .topbar {
    background: #061036; }
    .header .topbar .social ul {
      margin: 0; }
      .header .topbar .social ul li {
        display: inline-block;
        padding: 0 8px; }
        .header .topbar .social ul li:last-child {
          padding-right: 0; }
        .header .topbar .social ul li a {
          font-size: 12px;
          color: #ffffff;
          transition: all 0.3s ease-in-out; }
          .header .topbar .social ul li a:hover {
            color: #f9d448; }
    .header .topbar .dropdown .dropdown-toggle {
      font-size: 12px; }
      .header .topbar .dropdown .dropdown-toggle i {
        padding-left: 8px; }
      .header .topbar .dropdown .dropdown-toggle:after {
        display: none; }
      .header .topbar .dropdown .dropdown-toggle img {
        height: 20px;
        margin-right: 10px; }
    .header .topbar .dropdown .dropdown-menu {
      top: 50% !important;
      padding: 10px;
      box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1);
      border: none; }
      .header .topbar .dropdown .dropdown-menu a {
        color: #091c3a;
        font-size: 13px;
        padding: 10px 15px;
        border-radius: 3px;
        position: relative;
        z-index: 2; }
        .header .topbar .dropdown .dropdown-menu a:before {
          position: absolute;
          content: "";
          height: 100%;
          width: 0;
          background-color: #14357b;
          left: 0;
          bottom: 0;
          transition: all 0.3s ease-in-out;
          z-index: -1; }
        .header .topbar .dropdown .dropdown-menu a:hover {
          background-color: inherit;
          color: #f9d448; }
          .header .topbar .dropdown .dropdown-menu a:hover:before {
            width: 100%; }
  .header .topbar-inner {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(9, 28, 58, 0.05); }
    .header .topbar-inner .topbar-right a {
      color: #ffffff;
      font-size: 12px;
      letter-spacing: 0.2px; }
      .header .topbar-inner .topbar-right a img {
        width: 14px;
        margin-right: 5px; }
      .header .topbar-inner .topbar-right a:hover {
        color: #f9d448; }
      .header .topbar-inner .topbar-right a i {
        color: #f9d448; }
    .header .topbar-inner .topbar-right .topbar-address {
      margin-right: 24px; }
    .header .topbar-inner ul {
      display: flex;
      margin-bottom: 0;
      color: #ffffff; }
      .header .topbar-inner ul li {
        padding: 0 10px; }
    .header .topbar-inner a {
      color: #ffffff;
      font-size: 12px; }
      .header .topbar-inner a:hover {
        color: #f9d448; }
    .header .topbar-inner .topbar-left {
      display: flex;
      align-items: center; }
      .header .topbar-inner .topbar-left .dropdown .dropdown-toggle {
        display: flex;
        align-items: center; }
      .header .topbar-inner .topbar-left .dropdown .dropdown-menu .dropdown-item .country-flag {
        width: 20px;
        height: auto;
        margin-right: 10px; }

/* Topbar */
.header .navbar {
  padding: 0; }
  .header .navbar .navbar-brand {
    padding: 16px 0; }
    .header .navbar .navbar-brand img {
      height: 50px; }
  .header .navbar .navbar-nav {
    position: relative; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 0px; }
      .header .navbar .navbar-nav .nav-item:last-child {
        margin-right: 0; }
    .header .navbar .navbar-nav .nav-link {
      color: #061036;
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;
      padding: 30px 20px;
      position: relative;
      z-index: 2; }
      .header .navbar .navbar-nav .nav-link:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 0;
        background-color: #14357b;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease-in-out;
        z-index: -1; }
      .header .navbar .navbar-nav .nav-link:hover {
        color: #f9d448; }
        .header .navbar .navbar-nav .nav-link:hover:before {
          width: 100%; }
      .header .navbar .navbar-nav .nav-link i {
        margin-left: 8px; }
    .header .navbar .navbar-nav li.active > a {
      color: #f9d448; }
      .header .navbar .navbar-nav li.active > a:before {
        width: 100%; }
    .header .navbar .navbar-nav .dropdown-menu li a {
      position: relative;
      padding: 10px 15px;
      z-index: 2; }
      .header .navbar .navbar-nav .dropdown-menu li a:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 0;
        background-color: #14357b;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        z-index: -1; }
      .header .navbar .navbar-nav .dropdown-menu li a:hover {
        color: #f9d448; }
        .header .navbar .navbar-nav .dropdown-menu li a:hover:before {
          width: 100%; }
    .header .navbar .navbar-nav .dropdown-menu li:first-child a:hover:before {
      border-radius: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px; }
    .header .navbar .navbar-nav .dropdown-menu li:last-child a:hover:before {
      border-radius: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px; }
    .header .navbar .navbar-nav .dropdown-menu li.active > a {
      background: inherit;
      color: #f9d448;
      border-radius: 0; }
      .header .navbar .navbar-nav .dropdown-menu li.active > a:before {
        width: 100%; }
    .header .navbar .navbar-nav .dropdown-menu li:hover > a {
      background: inherit;
      color: #f9d448;
      border-radius: 0; }
    .header .navbar .navbar-nav .dropdown.nav-item.mega-menu .dropdown-menu.megamenu li ul li .dropdown-submenu:hover:before {
      border-radius: 0; }

.add-listing {
  display: flex;
  align-items: center; }
  .add-listing .woo-action ul {
    margin: 0; }
    .add-listing .woo-action ul li {
      display: inline-block;
      margin-right: 20px; }
      .add-listing .woo-action ul li:last-child {
        margin-right: 0px; }
      .add-listing .woo-action ul li a {
        color: #091c3a;
        font-size: 12px;
        position: relative; }
        .add-listing .woo-action ul li a:hover {
          color: #14357b; }
      .add-listing .woo-action ul li .cart-count {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        right: -4px;
        width: 16px;
        height: 16px;
        font-size: 10px;
        color: #061036;
        background: #f9d448;
        border-radius: 100%; }
    .add-listing .woo-action ul li.cart .dropdown-toggle {
      background: transparent;
      border: none;
      color: #091c3a;
      transition: all 0.3s ease-in-out;
      width: 33px;
      margin-top: 5px; }
      .add-listing .woo-action ul li.cart .dropdown-toggle object {
        width: 23px;
        height: 21px;
        filter: invert(6%) sepia(24%) saturate(7410%) hue-rotate(220deg) brightness(92%) contrast(102%);
        cursor: pointer;
        transition: all 0.3s ease-in-out; }
      .add-listing .woo-action ul li.cart .dropdown-toggle:hover {
        color: #14357b; }
        .add-listing .woo-action ul li.cart .dropdown-toggle:hover object {
          filter: invert(97%) sepia(28%) saturate(7271%) hue-rotate(319deg) brightness(97%) contrast(102%);
          transition: all 0.3s ease-in-out; }
      .add-listing .woo-action ul li.cart .dropdown-toggle:after {
        border-top: none; }
    .add-listing .woo-action ul li.cart .dropdown-menu-right {
      background-color: #ffffff;
      position: absolute;
      box-shadow: 0 1rem 3rem rgba(9, 28, 58, 0.175);
      border-radius: 5px;
      width: 315px;
      z-index: 9;
      transform: translate3d(0%, 10px, 0);
      transition: all 0.3s ease-in-out;
      transition-property: opacity, visibility, transform;
      transform-origin: top center;
      visibility: hidden;
      opacity: 0;
      display: block !important;
      right: 0; }
      .add-listing .woo-action ul li.cart .dropdown-menu-right li {
        margin-right: 0;
        padding: 15px 40px 15px 15px;
        border-bottom: 1px solid #ededed;
        position: relative; }
        .add-listing .woo-action ul li.cart .dropdown-menu-right li .cart-info a {
          font-size: 14px;
          font-weight: 500; }
        .add-listing .woo-action ul li.cart .dropdown-menu-right li .remove-item {
          position: absolute;
          right: 15px;
          top: 15px; }
        .add-listing .woo-action ul li.cart .dropdown-menu-right li img {
          width: 80px;
          height: auto; }
    .add-listing .woo-action ul li.cart .cart-footer {
      padding: 15px; }
      .add-listing .woo-action ul li.cart .cart-footer a {
        display: inline-block;
        text-align: center; }
      .add-listing .woo-action ul li.cart .cart-footer a.btn {
        color: #ffffff; }
    .add-listing .woo-action ul li.cart:hover .dropdown-menu-right {
      transform: translate3d(0%, 0, 0);
      visibility: visible;
      opacity: 1; }
  .add-listing .header-search {
    margin-left: 15px; }
    .add-listing .header-search .search .search-icon a object {
      width: 21px;
      height: 21px;
      filter: invert(6%) sepia(24%) saturate(7410%) hue-rotate(220deg) brightness(92%) contrast(102%);
      cursor: pointer;
      transition: all 0.3s ease-in-out; }
    .add-listing .header-search .search .search-icon a:hover {
      color: #14357b; }
      .add-listing .header-search .search .search-icon a:hover object {
        filter: invert(97%) sepia(28%) saturate(7271%) hue-rotate(319deg) brightness(97%) contrast(102%);
        transition: all 0.3s ease-in-out; }
    .add-listing .header-search .search .search-icon a svg {
      width: 20px;
      height: 20px;
      transition: 0.5s ease-in-out; }
      .add-listing .header-search .search .search-icon a svg path {
        fill: #3664d1;
        transition: 0.5s ease-in-out; }
      .add-listing .header-search .search .search-icon a svg:hover path {
        fill: #14357b; }
    .add-listing .header-search .search .search-icon i {
      font-size: 20px;
      color: #3664d1;
      transition: all 0.3s ease-in-out; }
      .add-listing .header-search .search .search-icon i:hover {
        color: #14357b; }
  .add-listing .search .search-btn {
    color: #091c3a;
    transition: color 300ms ease 0s;
    transition: 0.5s ease-in-out;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px; }
    .add-listing .search .search-btn:before {
      content: "\f002";
      font-family: "Font Awesome 6 free";
      font-size: 18px;
      font-weight: 900;
      text-indent: 0; }
    .add-listing .search .search-btn:hover {
      color: #14357b; }
  .add-listing .search .search-box button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease-in-out; }

.header.is-sticky {
  position: fixed !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  top: 0;
  z-index: 999;
  transition: 0.5s ease-in-out;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both; }
  .header.is-sticky .topbar {
    display: none !important; }

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.search.search-open .search-btn:before {
  content: "\f00d"; }

.search.search-open .search-box {
  opacity: 1 !important;
  visibility: visible !important;
  transform: rotateX(0deg); }
  .search.search-open .search-box .form-control {
    padding-right: 50px; }

.search-box {
  margin: 0;
  opacity: 0 !important;
  position: absolute;
  right: 0;
  top: 50px;
  transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
  visibility: hidden;
  width: 327px;
  z-index: 888;
  border-radius: 3px;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  transition: all 400ms ease; }

.search-box.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(10px);
  transform: translateY(10px); }

.btn-close:focus {
  box-shadow: none; }

/*megamenu*/
.megamenu {
  padding: 20px 20px;
  width: 100%; }
  .megamenu > div > li > ul {
    padding: 0;
    margin: 0; }
    .megamenu > div > li > ul > li {
      list-style: none; }
      .megamenu > div > li > ul > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #f3f9ff;
        white-space: normal; }
        .megamenu > div > li > ul > li > a:hover {
          text-decoration: none;
          color: #f3f9ff;
          background-color: #f3f9ff; }
        .megamenu > div > li > ul > li > a:focus {
          text-decoration: none;
          color: #f3f9ff;
          background-color: #f3f9ff; }
  .megamenu .card {
    outline: none; }
    .megamenu .card:hover {
      outline: 1px solid #000000; }
    .megamenu .card:focus {
      outline: 1px solid #000000; }

.megamenu.disabled > a {
  color: #f3f9ff; }
  .megamenu.disabled > a:hover {
    color: #f3f9ff;
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: not-allowed; }
  .megamenu.disabled > a:focus {
    color: #f3f9ff;
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: not-allowed; }

.megamenu.dropdown-header {
  color: #14357b;
  font-size: 18px; }

.dropdown-submenu {
  position: relative;
  padding: 7px 0 7px 0;
  display: block;
  color: #3664d1; }

/* Header Transparent */
.header.header-transparent {
  position: absolute;
  width: 100%;
  background: none;
  backdrop-filter: blur(0.1rem); }
  .header.header-transparent .navbar-brand img {
    height: 50px; }
  .header.header-transparent .navbar-nav .nav-item .nav-link {
    color: #ffffff; }
    .header.header-transparent .navbar-nav .nav-item .nav-link:hover {
      color: #f9d448; }
      .header.header-transparent .navbar-nav .nav-item .nav-link:hover:before {
        background-color: inherit;
        content: inherit; }
  .header.header-transparent .navbar-nav .nav-item.active .nav-link {
    color: #f9d448; }
    .header.header-transparent .navbar-nav .nav-item.active .nav-link:before {
      background-color: inherit;
      content: inherit; }
  .header.header-transparent .add-listing .woo-action ul li.cart .dropdown-toggle {
    color: #091c3a; }
    .header.header-transparent .add-listing .woo-action ul li.cart .dropdown-toggle object {
      width: 21px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%);
      cursor: pointer;
      transition: all 0.3s ease-in-out; }
    .header.header-transparent .add-listing .woo-action ul li.cart .dropdown-toggle:hover object {
      filter: invert(97%) sepia(28%) saturate(7271%) hue-rotate(319deg) brightness(97%) contrast(102%);
      transition: all 0.3s ease-in-out; }
  .header.header-transparent .add-listing .search ul li a {
    color: #ffffff; }
    .header.header-transparent .add-listing .search ul li a object {
      width: 21px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%);
      cursor: pointer;
      transition: all 0.3s ease-in-out; }
    .header.header-transparent .add-listing .search ul li a:hover {
      color: #14357b; }
      .header.header-transparent .add-listing .search ul li a:hover object {
        filter: invert(97%) sepia(28%) saturate(7271%) hue-rotate(319deg) brightness(97%) contrast(102%);
        transition: all 0.3s ease-in-out; }
  .header.header-transparent .add-listing .search .search-icon a svg {
    width: 20px;
    height: 20px;
    transition: 0.5s ease-in-out; }
    .header.header-transparent .add-listing .search .search-icon a svg path {
      fill: #ffffff;
      transition: 0.5s ease-in-out; }
    .header.header-transparent .add-listing .search .search-icon a svg:hover path {
      fill: #ffffff; }

.header.header-transparent.is-sticky {
  background: #061036; }
  .header.header-transparent.is-sticky .navbar .navbar-brand {
    padding: 10px 0px; }
  .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #ffffff; }
    .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover {
      color: #f9d448; }
      .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item .nav-link:hover:before {
        background-color: inherit; }
  .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #f9d448; }
    .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item.active .nav-link:before {
      background-color: inherit; }

/* Header Style 03 */
.header.header-3 .topbar {
  padding: 0 65px; }

.header.header-3 .navbar {
  padding: 0 65px; }

@media (min-width: 992px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    min-width: 200px;
    font-size: 14px;
    border-radius: 5px;
    padding: 0px;
    border-color: transparent;
    left: 0;
    transform: translate3d(-10px, 15px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important; }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
      left: 100%;
      right: auto;
      transform: translate3d(0, 10px, 0); }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
      right: 100%;
      left: auto;
      transform: translate3d(-15px, 10px, 0); }
  .header .navbar .dropdown-menu.megamenu {
    left: 0;
    padding: 10px; }
    .header .navbar .dropdown-menu.megamenu .nav-title {
      padding-left: 15px;
      padding-top: 20px;
      display: inline-block;
      font-size: 16px;
      font-weight: 600; }
  .header .navbar .dropdown > .dropdown-menu {
    top: 100%;
    margin-top: 0px; }
  .header .navbar .dropdown > .dropdown-menu.megamenu {
    top: 100%; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-10px, 0px, 0);
    visibility: visible;
    opacity: 1; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 630px; }
  .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 450px; }
  .navbar-nav .mega-menu {
    position: static; }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative;
    position: relative;
    padding: 7px 0 7px 0;
    display: block;
    color: #061036; }
    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); }
  .dropdown-menu .dropdown-item {
    padding: 7px 0 7px 0; }
    .dropdown-menu .dropdown-item:focus {
      background-color: transparent; } }

@media (max-width: 1400px) {
  .header.header-3 .navbar {
    padding: 0 30px; }
    .header.header-3 .navbar .navbar-nav .nav-item .nav-link {
      padding: 30px 10px; } }

@media (max-width: 1199px) {
  .header.header-3 .navbar {
    padding: 0; }
    .header.header-3 .navbar .navbar-nav .nav-item {
      margin-right: 12px; }
  .header .navbar .navbar-brand {
    padding: 10px 0; }
  .header .navbar .navbar-nav .nav-item {
    margin-right: 0px; }
    .header .navbar .navbar-nav .nav-item .nav-link {
      padding: 24px 10px; } }

@media (max-width: 991px) {
  .header .topbar-inner .topbar-left {
    justify-content: center; }
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    left: 0;
    margin-top: -1px;
    margin-left: 0px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .header .navbar .navbar-brand img {
    height: 40px; }
  .header .navbar .navbar-nav {
    align-items: initial; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 0; }
      .header .navbar .navbar-nav .nav-item .nav-link {
        color: #091c3a;
        padding: 10px 20px; }
        .header .navbar .navbar-nav .nav-item .nav-link:hover {
          color: #f9d448; }
        .header .navbar .navbar-nav .nav-item .nav-link i {
          position: absolute;
          right: 20px; }
    .header .navbar .navbar-nav .nav-item.active .nav-link {
      color: #f9d448;
      background-color: #14357b; }
    .header .navbar .navbar-nav .dropdown.mega-menu .megamenu {
      width: auto;
      margin: 0 auto; }
  .header .navbar .dropdown-menu {
    margin: 0px 0px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: inherit;
    padding: 0px 15px;
    font-size: 16px;
    border-radius: 5px; }
    .header .navbar .dropdown-menu .dropdown-item {
      padding: 10px 0px; }
  .header .navbar .nav-title {
    margin-top: 20px; }
  .header .navbar .navbar-toggler {
    position: absolute;
    right: 15px;
    box-shadow: none;
    transition: 0.5s ease-in-out;
    background: #14357b;
    color: #ffffff;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    height: 45px;
    width: 45px;
    justify-content: center;
    align-items: center; }
  .header .navbar .add-listing {
    padding-right: 80px; }
    .header .navbar .add-listing .menu-btn {
      display: none; }
  .header.header-transparent .navbar-nav .nav-item .nav-link {
    color: #3664d1; }
  .header.header-3 .navbar .navbar-nav .nav-item {
    margin-right: 0; }
    .header.header-3 .navbar .navbar-nav .nav-item .nav-link {
      padding: 10px 20px; }
  .header.header-transparent.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #3664d1; } }

@media (max-width: 767px) {
  .header .navbar .add-listing {
    padding-right: 70px; }
    .header .navbar .add-listing .header-search {
      margin-left: 10px; } }

@media (max-width: 575px) {
  .header .navbar .navbar-brand {
    padding: 15px 0; }
  .header .navbar .add-listing {
    padding-right: 60px; }
    .header .navbar .add-listing .header-search {
      margin-left: 6px; }
      .header .navbar .add-listing .header-search .search .search-icon i {
        font-size: 16px; }
    .header .navbar .add-listing .cart img {
      height: 18px; } }

@media (max-width: 479px) {
  .add-listing .woo-action ul li.cart .dropdown-menu-right {
    width: 280px;
    right: inherit;
    left: -172px; } }

/*Social Icon*/
.social-icon ul {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0; }
  .social-icon ul li {
    display: inline-flex;
    list-style-type: none;
    float: left; }
    .social-icon ul li:last-child a {
      margin-right: 0; }
    .social-icon ul li a {
      display: flex;
      font-size: 18px;
      color: #091c3a;
      margin-right: 25px;
      transition: all 0.5s ease; }
      .social-icon ul li a:hover {
        color: #14357b; }

.social-icon.space-right ul li a {
  font-size: 20px;
  margin-right: 50px; }

/* Social Bg Color */
.social-bg-hover {
  position: relative;
  color: #ffffff; }
  .social-bg-hover:hover {
    color: #ffffff; }
  .social-bg-hover span {
    position: relative; }

.btn.facebook-bg:hover {
  background-color: #5870a3;
  color: #ffffff; }

.btn.twitter-bg:hover {
  background-color: #5ec2f7;
  color: #ffffff; }

.btn.google-bg:hover {
  background-color: #f64e57;
  color: #ffffff; }

.btn.instagram-bg:hover {
  background-color: #f93a90;
  color: #ffffff; }

.btn.linkedin-bg:hover {
  background-color: #038fca;
  color: #ffffff; }

.btn.pinterest-bg:hover {
  background-color: #fa4b65;
  color: #ffffff; }

.facebook-bg {
  background-color: #445c8e; }

.twitter-bg {
  background-color: #43afe9; }

.google-bg {
  background-color: #dc0510; }

.instagram-bg {
  background-color: #DD2A7B; }

.linkedin-bg {
  background-color: #007eb3; }

.pinterest-bg {
  background-color: #E60023; }

.social-icon.social-rounded ul li a {
  color: #ffffff;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px; }
  .social-icon.social-rounded ul li a:hover {
    background: #14357b;
    border: 1px solid #14357b; }

/*Section Title*/
.section-title .title {
  margin-bottom: 20px;
  font-weight: 600; }

.section-title .description {
  margin-bottom: 35px; }

.call-box {
  display: flex;
  align-items: center; }
  .call-box i {
    background-color: #ffffff;
    padding: 15px;
    color: #3664d1;
    border-radius: 100%;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .call-box .call-box-content {
    margin-left: 10px; }
    .call-box .call-box-content p {
      margin-bottom: 5px; }
    .call-box .call-box-content span {
      font-weight: 600;
      font-size: 20px;
      font-family: "Jost", sans-serif; }

/*Home Search 02*/
.home-search-02 .input-group.date .input-group-append .input-group-text {
  border-radius: 0;
  border: inherit;
  color: #858585; }

.home-search-02 .input-group.date .form-control {
  border-right: inherit;
  height: 60px; }

.home-search-02 .select2-container--default .select2-selection--single .select2-selection__arrow:before {
  color: #858585; }

/*Inner Header*/
.header-inner-menu {
  background-position: center;
  background-size: cover; }
  .header-inner-menu .header-inner-title {
    margin: 200px 0px; }
    .header-inner-menu .header-inner-title .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .header-inner-menu .header-inner-title .section-title .sub-title {
        padding-bottom: 0px;
        margin-bottom: 0; }
        .header-inner-menu .header-inner-title .section-title .sub-title span {
          text-transform: capitalize;
          color: #ffffff; }
        .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb {
          margin-bottom: 0; }
          .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
            color: #ffffff; }
          .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb .breadcrumb-item a {
            color: #ffffff; }
            .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb .breadcrumb-item a i {
              color: #f9d448; }
            .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb .breadcrumb-item a:hover {
              color: #f9d448; }
        .header-inner-menu .header-inner-title .section-title .sub-title:after {
          background: #ffffff; }
    .header-inner-menu .header-inner-title .title {
      font-size: 48px;
      margin-bottom: 0; }

.perfect-tour .title-border {
  border-bottom: 1px solid #ededed;
  padding-bottom: 40px; }

.contant-404 h2 {
  font-size: 55px;
  color: #14357b;
  font-weight: bold; }

.breadcrumb-item.active {
  color: #f9d448; }

/* Form */
.form-control {
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  border-color: #ededed;
  transition: all 0.3s ease-in-out;
  color: #858585;
  border-radius: 30px; }
  .form-control:focus {
    box-shadow: none;
    border-color: #ededed; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #091c3a; }

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%; }

.custom-checkbox .custom-control-label:before {
  border-radius: 0;
  border: 2px solid #091c3a; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=!string!width=!string!height=!string!viewBox=!string!%3e%3cpath fill=!string!d=!string!/%3e%3c/svg%3e"); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=!string!width=!string!height=!string!viewBox=!string!%3e%3ccircle r=!string!fill=!string!/%3e%3c/svg%3e"); }

textarea.form-control {
  height: auto;
  border-radius: 20px; }

.custom-control-input:checked ~ .custom-control-label:before {
  background: #14357b;
  border-color: #14357b; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #14357b; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #14357b;
  border-color: #14357b; }

/*Home 03*/
.travel-section {
  margin-top: -110px;
  padding-top: 180px;
  padding-bottom: 100px; }

.video-section {
  padding-bottom: 180px; }
  .video-section .video-box {
    display: flex;
    justify-content: center; }
    .video-section .video-box .play-btn {
      position: relative; }
      .video-section .video-box .play-btn i {
        position: relative;
        display: flex;
        flex: 0 0 90px;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 90px;
        border-radius: 100%;
        background-color: #ffffff;
        color: #14357b;
        font-size: 25px;
        transition: all 0.3s ease-in-out;
        z-index: 99; }
        .video-section .video-box .play-btn i:after {
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 90px;
          height: 90px;
          background: #ffffff;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite; }
  .video-section .video-section-conten {
    position: relative;
    margin-top: 50px;
    text-align: center; }
    .video-section .video-section-conten p {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-family: "Jost", sans-serif; }
    .video-section .video-section-conten h2 {
      font-size: 50px;
      font-weight: 600;
      color: #ffffff; }

.our-destination {
  padding-bottom: 180px; }

.testimonial-section {
  margin-top: -90px;
  padding-bottom: 100px; }
  .testimonial-section .testimonial-box {
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 5px;
    position: relative;
    z-index: 9; }
    .testimonial-section .testimonial-box .testimonial.testimonial-style-2 {
      background-color: inherit;
      box-shadow: inherit;
      margin-bottom: 0px; }
  .testimonial-section .owl-carousel .owl-nav {
    position: absolute;
    right: 40px;
    width: 100px;
    display: flex;
    bottom: 40px; }
    .testimonial-section .owl-carousel .owl-nav button {
      flex: 0 0 50px; }

.blog-section {
  padding-top: 100px;
  padding-bottom: 70px; }

/*About us*/
.about-02 {
  background-repeat: no-repeat;
  background-size: cover; }

.about-img {
  position: relative;
  display: block;
  padding: 0px 40px 140px 120px; }
  .about-img .title-travel {
    text-transform: uppercase;
    font-weight: 600;
    transform: rotate(-90deg);
    display: inline-block;
    position: absolute;
    opacity: 0.30;
    left: 0px;
    top: 100px;
    font-size: 34px;
    color: #3664d1; }
  .about-img .img-01 {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 12px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
    .about-img .img-01 img {
      border-radius: 0; }
  .about-img .img-02 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 12px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .about-img .about-video {
    position: absolute;
    width: 150px;
    right: 80px;
    bottom: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
    .about-img .about-video a {
      position: relative;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex; }
      .about-img .about-video a i {
        position: absolute;
        display: flex;
        flex: 0 0 40px;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;
        z-index: 99; }
        .about-img .about-video a i:after {
          content: "";
          position: absolute;
          z-index: -9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 50px;
          height: 50px;
          background: #ffffff;
          border-radius: 50%;
          animation: pulse-border 1500ms ease-out infinite; }
  .about-img .feature-categories-2 {
    width: 160px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    box-shadow: 0px 0px 15px 0px rgba(9, 28, 58, 0.1) inset;
    border-radius: 0;
    position: absolute;
    background-color: #ffffff;
    right: 60px;
    bottom: -75px;
    padding: 15px;
    margin: 0 auto;
    text-align: center; }
    .about-img .feature-categories-2 .feature-categories {
      padding: 0px 0px 10px; }
      .about-img .feature-categories-2 .feature-categories .title {
        font-size: 16px; }
  .about-img .owl-carousel .owl-dots {
    margin-top: 0;
    text-align: center; }
    .about-img .owl-carousel .owl-dots button.owl-dot span {
      width: 6px;
      height: 6px;
      border-radius: 0;
      margin: 0 5px; }

.about {
  position: relative;
  text-align: center; }
  .about .img-02 {
    position: absolute;
    left: 20px;
    bottom: -40px; }
  .about .shape {
    position: absolute;
    right: 40px;
    bottom: -65px;
    z-index: -1; }

.map-bg-img {
  position: absolute;
  right: 100px;
  top: 0;
  width: 100%;
  height: 100%; }

/*contact us*/
.social-info h6 {
  padding: 30px 0;
  font-weight: 600; }

.social-info .contact-info ul li {
  padding-bottom: 30px; }
  .social-info .contact-info ul li img {
    height: 36px;
    width: 36px;
    color: #ffffff; }

.social-info .social-icon {
  margin-top: 10px; }
  .social-info .social-icon ul li a {
    color: #ffffff; }
    .social-info .social-icon ul li a:hover {
      color: #091c3a; }

.message-info {
  padding: 40px 30px;
  background-color: #ffffff;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .message-info .title {
    font-weight: 600; }
  .message-info .social-icon {
    margin-top: 10px; }
    .message-info .social-icon ul li a {
      margin-right: 10px;
      height: 40px;
      width: 40px;
      background-color: #14357b;
      box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
      border-radius: 100%;
      transition: all 0.3s ease;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff; }
      .message-info .social-icon ul li a:hover {
        background-color: #3664d1;
        color: #f9d448; }

.map {
  height: 650px; }

.contact-form {
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important; }

.form-inline {
  position: relative; }
  .form-inline .btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 40px 14px;
    height: 59px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.search ul li a {
  color: #000000;
  display: flex; }
  .search ul li a:hover {
    color: #14357b; }
  .search ul li a .icon {
    z-index: -1; }

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f3f9ff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999; }

#search.open {
  opacity: 1;
  visibility: visible; }

#search input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 50%;
  color: #091c3a;
  background: #f3f9ff;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  border: #ededed;
  border-radius: 0;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  text-transform: capitalize; }

#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px; }

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: #3664d1;
  color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  font-size: 24px;
  border-radius: 100%;
  border: transparent;
  transition: all 0.3s ease-in-out; }
  #search .close:hover {
    background-color: #14357b; }

/*Map Listing*/
.map-listing {
  height: 680px;
  position: relative; }
  .map-listing .contant .map-img {
    width: 160px;
    height: auto;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    margin-bottom: 15px;
    border-radius: 0;
    position: relative;
    z-index: 9; }
    .map-listing .contant .map-img img {
      border-radius: 0; }
  .map-listing .contant i {
    font-size: 24px;
    color: #3664d1; }
  .map-listing .contant .icon {
    justify-content: center;
    text-align: center;
    cursor: pointer; }
    .map-listing .contant .icon:hover .map-img {
      opacity: 1; }
    .map-listing .contant .icon .map-img {
      opacity: 0;
      transition: all 0.3s ease-out 0s;
      cursor: pointer; }
  .map-listing .map-icon.icon-01 {
    position: absolute;
    top: -15%;
    left: 10%; }
  .map-listing .map-icon.icon-02 {
    position: absolute;
    top: 27%;
    left: 47%; }
  .map-listing .map-icon.icon-03 {
    position: absolute;
    top: 32%;
    left: 28%; }
  .map-listing .map-icon.icon-04 {
    position: absolute;
    top: 12%;
    left: 61%; }
  .map-listing .map-icon.icon-05 {
    position: absolute;
    top: 43%;
    right: 6%; }
  .map-listing .map-icon.icon-06 {
    position: absolute;
    top: 8%;
    right: 15%; }

/*Blockquote*/
blockquote {
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  background: #ffffff;
  border-radius: 0;
  padding: 25px 30px;
  position: relative;
  z-index: 2;
  margin-bottom: 24px; }
  blockquote i {
    position: absolute;
    z-index: -1;
    width: 70px;
    opacity: 0.2;
    top: 0; }

@media (max-width: 1199px) {
  .header-inner-menu .header-inner-title {
    margin: 150px 0px; }
  .social-icon.space-right ul li a {
    margin-right: 30px; }
  .about-img .feature-categories-2 {
    right: 0; }
  .about .shape {
    right: 0; }
  .about .img-02 {
    left: 0; }
  .map-listing {
    height: 540px; }
    .map-listing .map-icon.icon-01 {
      top: -25%;
      left: 7%; }
    .map-listing .map-icon.icon-02 {
      top: 20%;
      left: 47%; }
    .map-listing .map-icon.icon-03 {
      top: 27%;
      left: 25%; }
    .map-listing .map-icon.icon-04 {
      top: 12%;
      left: 62%; }
    .map-listing .map-icon.icon-05 {
      top: 37%;
      right: 5%; }
    .map-listing .map-icon.icon-06 {
      top: 5%;
      right: 14%; }
    .map-listing .contant .map-img {
      width: 140px; }
  .contant-404 h2 {
    font-size: 48px; }
  .destination-section {
    padding-bottom: 80px; } }

@media (max-width: 991px) {
  .header-inner-menu .header-inner-title {
    margin: 100px 0px; }
    .header-inner-menu .header-inner-title .section-title {
      display: block;
      text-align: center; }
      .header-inner-menu .header-inner-title .section-title .title {
        margin-bottom: 20px; }
      .header-inner-menu .header-inner-title .section-title .sub-title {
        justify-content: center; }
        .header-inner-menu .header-inner-title .section-title .sub-title:before {
          left: 50%;
          transform: translateX(-50%); }
        .header-inner-menu .header-inner-title .section-title .sub-title:after {
          left: 50%;
          transform: translateX(-50%); }
        .header-inner-menu .header-inner-title .section-title .sub-title .breadcrumb {
          justify-content: center; }
  .about-img {
    margin-top: 50px; }
    .about-img .about-video {
      width: 250px; }
  .about {
    margin-bottom: 65px; }
  .map-listing {
    height: 400px; }
    .map-listing .contant .map-img {
      width: 100px; }
      .map-listing .contant .map-img p {
        display: none; }
    .map-listing .map-icon.icon-01 {
      top: -18%;
      left: 10%; }
    .map-listing .map-icon.icon-03 {
      top: 30%; }
    .map-listing .map-icon.icon-04 {
      top: 17%;
      left: 62%; }
    .map-listing .map-icon.icon-05 {
      top: 43%; }
    .map-listing .map-icon.icon-06 {
      top: -2%;
      right: 10%; }
  .contact-form {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .perfect-tour .slider {
    margin-right: 0; }
  .blog-section {
    padding-top: 60px;
    padding-bottom: 30px; }
  .events-content .map {
    height: 300px !important; }
  .our-destination {
    padding-bottom: 150px; }
  .destination-section {
    padding-bottom: 60px; }
  .travel-section {
    padding-bottom: 60px;
    margin-top: -260px;
    padding-top: 320px; }
  .video-section .video-section-conten h2 {
    font-size: 40px; }
  .map {
    height: 450px !important; } }

@media (max-width: 767px) {
  .map-listing {
    height: 300px; }
    .map-listing .contant .map-img {
      width: 80px;
      padding: 5px;
      margin-bottom: 10px; }
      .map-listing .contant .map-img h6 {
        font-size: 16px; }
      .map-listing .contant .map-img p {
        display: none; }
    .map-listing .map-icon.icon-01 {
      top: -25%;
      left: 7%; }
    .map-listing .map-icon.icon-02 {
      top: 10%;
      left: 45%; }
    .map-listing .map-icon.icon-03 {
      top: 25%; }
    .map-listing .map-icon.icon-04 {
      top: 12%; }
    .map-listing .map-icon.icon-05 {
      top: 40%;
      right: 3%; }
    .map-listing .map-icon.icon-06 {
      top: 7%;
      right: 13%; }
  .perfect-tour .slider .owl-nav {
    width: 100%;
    position: absolute;
    top: 20%;
    width: 100%;
    right: 0; }
    .perfect-tour .slider .owl-nav .owl-next {
      position: absolute;
      right: 0; }
  .header-inner-menu .header-inner-title {
    margin: 160px 0px; }
    .header-inner-menu .header-inner-title .title {
      font-size: 36px; }
  .contant-404 h2 {
    font-size: 38px; }
  .blog-section {
    padding-top: 50px;
    padding-bottom: 20px; }
  .about-img .about-video {
    width: 140px;
    right: 40px; }
  .about-img .title-travel {
    font-size: 24px; }
  .our-destination {
    padding-bottom: 120px; }
  .destination-section {
    padding-bottom: 50px; }
  .travel-section {
    padding-bottom: 50px;
    margin-top: 0px;
    padding-top: 50px; }
  .video-section {
    padding-bottom: 130px; }
  .testimonial-section {
    padding-bottom: 50px; }
  .map {
    height: 300px !important; } }

@media (max-width: 575px) {
  .about-img {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0px; }
    .about-img .img-02 {
      position: inherit;
      margin-top: 20px; }
      .about-img .img-02 img {
        width: 100%; }
    .about-img .title-travel {
      display: none; }
    .about-img .about-video {
      margin-top: 20px;
      bottom: inherit;
      position: inherit;
      width: 100%;
      right: inherit;
      padding: 10px; }
  .about {
    text-align: center; }
    .about .img-01 {
      width: 90%;
      margin: 0 auto; }
    .about .img-02 {
      width: 75%;
      margin: 0 auto; }
  .social-icon.social-rounded ul li a {
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin-right: 15px; }
  .map-listing .contant i {
    font-size: 14px; }
  .map-listing .contant .map-img {
    margin-bottom: 5px; }
    .map-listing .contant .map-img p {
      display: none; }
  .map-listing .map-icon.icon-02 {
    top: 20%;
    left: 47%; }
  .map-listing .map-icon.icon-03 {
    top: 30%; }
  .map-listing .map-icon.icon-04 {
    top: 14%; }
  .map-listing .map-icon.icon-06 {
    top: 10%;
    right: 13%; }
  .header-inner-menu .header-inner-title {
    margin: 100px 0px; }
    .header-inner-menu .header-inner-title .title {
      font-size: 30px; }
  .contant-404 h2 {
    font-size: 30px; }
  .blog-section {
    padding-top: 40px;
    padding-bottom: 10px; }
  .testimonial-section {
    padding: 0 15px;
    padding-bottom: 50px; }
    .testimonial-section .owl-carousel .owl-nav {
      display: none; }
  .counter-section {
    padding: 0 15px; }
  .video-section .video-section-conten h2 {
    font-size: 28px; }
  .video-section .video-section-conten p {
    font-size: 16px;
    margin-bottom: 20px; } }

/* Back To Top */
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 20px;
  color: #061036;
  background-color: #14357b;
  border-radius: 100%;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 9; }
  .back-to-top:hover {
    color: rgba(249, 212, 72, 0);
    transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s; }
    .back-to-top:hover:before {
      animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s; }
    .back-to-top:hover:after {
      animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s; }
  .back-to-top:before {
    position: absolute;
    display: inline-block;
    content: "";
    background: #f9d448;
    width: 3px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -2px;
    -webkit-transform: translateY(50px);
    transform: translateY(50px); }
  .back-to-top:after {
    position: absolute;
    display: inline-block;
    content: "";
    width: 15px;
    height: 15px;
    color: #f9d448;
    border-top: 3px solid;
    border-left: 3px solid;
    transform: rotateZ(45deg);
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -8px;
    transform: translateY(50px) rotateZ(45deg); }
  .back-to-top a {
    color: #f9d448; }
  .back-to-top:hover a {
    color: transparent; }

.back-to-top.auto {
  color: rgba(255, 255, 255, 0);
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s; }
  .back-to-top.auto:before {
    animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s; }
  .back-to-top.auto:after {
    animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s; }

@keyframes tipUp {
  0% {
    transform: translateY(50px) rotateZ(45deg); }
  100% {
    transform: translateY(-70px) rotateZ(45deg); } }

@keyframes lineUp {
  0% {
    transform: translateY(50px); }
  100% {
    transform: translateY(-70px); } }

/*****************************
    Banner
*****************************/
/*Banner 01*/
.banner {
  padding: 250px 0 280px; }
  .banner .form-location {
    position: relative; }
    .banner .form-location .input-group.date .input-group-text {
      background: #ffffff;
      color: #858585;
      border: none;
      border-radius: 0;
      border-right: 1px solid #ededed; }
    .banner .form-location .form-control {
      border-radius: 0;
      height: 60px !important;
      border-radius: inherit;
      border-right: inherit;
      color: #858585; }
      .banner .form-location .form-control:placeholder {
        color: #858585; }
  .banner .form-select {
    border-radius: 0; }
  .banner .form-select.select-fast {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 60px; }
  .banner .select-fast .select2-container--default .select2-selection--single {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px; }
  .banner .select2-container--default .select2-selection--single {
    border-radius: 0;
    height: 60px !important;
    line-height: 30px; }
    .banner .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #858585; }
    .banner .select2-container--default .select2-selection--single .select2-selection__arrow:before {
      color: #858585; }
  .banner .btn {
    height: 60px;
    line-height: 30px;
    border-radius: 0;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px; }
    .banner .btn:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.home-search .form-control {
  height: 60px !important;
  border-radius: 0; }

/*Banner 01*/
.banner-1 {
  padding: 330px 0 250px;
  position: relative;
  z-index: 2; }
  .banner-1:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(5, 16, 53, 0.5) 0%, rgba(5, 16, 53, 0.9) 75%);
    z-index: -1; }
  .banner-1 h1 {
    font-size: 70px;
    font-weight: 600;
    line-height: 1.1; }
  .banner-1 .home-search {
    margin-top: 60px; }

/*Banner 02*/
.banner-02 .slider-social {
  display: flex;
  position: absolute;
  z-index: 2;
  bottom: 50px;
  right: 20px;
  padding-top: 0px;
  text-align: center; }
  .banner-02 .slider-social .slider-social-info ul {
    display: flex;
    align-items: center; }
    .banner-02 .slider-social .slider-social-info ul:before {
      content: "";
      background: #f9d448;
      width: 82px;
      height: 2px;
      display: inline-block; }
    .banner-02 .slider-social .slider-social-info ul li {
      margin-left: 15px; }
      .banner-02 .slider-social .slider-social-info ul li a {
        color: #ffffff;
        display: inline-block;
        padding: 5px 10px;
        font-size: 20px; }
        .banner-02 .slider-social .slider-social-info ul li a:hover {
          color: #f9d448; }
  .banner-02 .slider-social span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    color: #ffffff;
    text-transform: uppercase; }

.banner-02 .swiper-slide {
  height: 750px;
  padding-top: 0px; }
  .banner-02 .swiper-slide .title {
    font-size: 50px;
    line-height: 70px; }
  .banner-02 .swiper-slide p {
    font-size: 20px;
    line-height: 28px;
    margin: 20px 0 35px;
    justify-content: space-between; }

.banner-02 .pagination-button .swiper-button-prev {
  background-image: none;
  display: flex;
  color: #ffffff;
  align-items: center;
  bottom: 50px;
  top: auto;
  transition: all 0.3s ease-in-out; }
  .banner-02 .pagination-button .swiper-button-prev:hover {
    color: #f9d448; }

.banner-02 .pagination-button .swiper-button-next {
  background-image: none;
  display: flex;
  color: #ffffff;
  align-items: center;
  bottom: 50px;
  top: auto;
  right: auto;
  left: 80px;
  transition: all 0.3s ease-in-out; }
  .banner-02 .pagination-button .swiper-button-next:hover {
    color: #f9d448; }

/*Travel-Banner*/
.travel-banner .owl-carousel .owl-stage-outer {
  overflow: visible; }
  .travel-banner .owl-carousel .owl-stage-outer .owl-item .travel-slider .single-banner-info {
    opacity: 0;
    transition: all 0.3s ease-in-out; }
  .travel-banner .owl-carousel .owl-stage-outer .owl-item.active {
    opacity: 1; }
    .travel-banner .owl-carousel .owl-stage-outer .owl-item.active .travel-slider .single-banner-info {
      opacity: 1; }

.travel-banner .travel-slider {
  height: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 2; }
  .travel-banner .travel-slider .single-banner-info {
    width: 100%;
    padding-top: 290px;
    height: 100%; }
    .travel-banner .travel-slider .single-banner-info p {
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      font-family: "Jost", sans-serif;
      color: #f9d448; }
    .travel-banner .travel-slider .single-banner-info .title {
      margin-bottom: 30px;
      color: #ffffff;
      font-weight: 600;
      text-transform: uppercase; }
  .travel-banner .travel-slider:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(5, 16, 53, 0.5) 0%, rgba(5, 16, 53, 0.5) 75%);
    z-index: -1; }

.travel-banner .slider-social {
  position: absolute;
  width: 100%;
  bottom: 100px;
  z-index: 9;
  left: 0; }
  .travel-banner .slider-social .play-btn {
    position: relative;
    padding-left: 70px;
    margin-left: 50px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    color: #ffffff; }
    .travel-banner .slider-social .play-btn i {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex: 0 0 40px;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      background-color: #ffffff;
      color: #3664d1;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      z-index: 99; }
      .travel-banner .slider-social .play-btn i:after {
        content: "";
        position: absolute;
        z-index: -9;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 50px;
        height: 50px;
        background: #ffffff;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite; }

.travel-banner .slider-social-info ul {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-right: 50px; }
  .travel-banner .slider-social-info ul:before {
    content: "";
    background: #f9d448;
    width: 82px;
    height: 2px;
    display: inline-block; }
  .travel-banner .slider-social-info ul li {
    margin-left: 15px; }
    .travel-banner .slider-social-info ul li a {
      color: #ffffff;
      display: inline-block;
      padding: 5px 5px;
      font-size: 18px;
      text-transform: uppercase;
      font-family: "Jost", sans-serif;
      font-weight: 600; }
      .travel-banner .slider-social-info ul li a:hover {
        color: #f9d448; }

.travel-banner .owl-carousel.owl-nav-center .owl-nav .owl-prev {
  left: 0;
  opacity: 0; }

.travel-banner .owl-carousel.owl-nav-center .owl-nav .owl-next {
  right: 0;
  opacity: 0; }

.travel-banner .owl-carousel.owl-nav-center:hover .owl-nav .owl-prev {
  left: 30px;
  opacity: 1; }

.travel-banner .owl-carousel.owl-nav-center:hover .owl-nav .owl-next {
  right: 30px;
  opacity: 1; }

/*Banner bg*/
.banner-bg-splash h1 {
  font-size: 84px;
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.5); }

.banner-bg-splash .search {
  position: relative; }
  .banner-bg-splash .search a {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    line-height: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .banner-bg-splash .search .form-control {
    height: 60px; }

.home-search-02 {
  position: relative;
  z-index: 2; }
  .home-search-02 .home-search .select2-container--default .select2-selection--single {
    height: 60px;
    line-height: 30px;
    border-radius: 30px; }
  .home-search-02 .home-search .form-location.datetimepickers .input-group.date .form-control {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; }
  .home-search-02 .home-search .form-location.datetimepickers .input-group.date .input-group-text {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; }

@media (max-width: 1200px) {
  .banner .home-search .btn {
    padding: 15px; }
  .banner-02 .swiper-slide {
    height: 650px; }
    .banner-02 .swiper-slide .title {
      font-size: 40px;
      line-height: 60px; } }

@media (max-width: 991px) {
  .banner {
    padding: 170px 0 100px; }
    .banner h1 {
      font-size: 60px; }
  .banner-02 .swiper-slide {
    height: 500px; }
    .banner-02 .swiper-slide .title {
      font-size: 30px;
      line-height: 40px; }
  .banner-02 .slider-social {
    display: none; }
  .travel-banner .travel-slider {
    height: 500px; }
    .travel-banner .travel-slider .single-banner-info {
      padding-top: 150px; }
      .travel-banner .travel-slider .single-banner-info p {
        font-size: 18px; }
      .travel-banner .travel-slider .single-banner-info h1 {
        font-size: 60px; } }

@media (max-width: 767px) {
  .banner h1 {
    font-size: 48px; }
  .banner .home-search .form-location.datetimepickers #datetimepicker-01 .datetimepicker-input {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; }
  .banner .home-search .form-location.datetimepickers #datetimepicker-02 .input-group-text {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; }
  .banner .home-search .input-group.date .input-group-text {
    border-radius: 0; }
  .banner .home-search .select-secon .select2-container--default .select2-selection--single {
    border-radius: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; }
  .banner .home-search .form-btn-are .btn {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; }
  .banner-1 .home-search {
    margin-top: 30px; }
  .banner-02 .swiper-slide {
    position: relative; }
    .banner-02 .swiper-slide:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(54, 100, 209, 0.6); }
  .slider-social-info {
    display: none; }
  .travel-banner .travel-slider {
    height: 450px; }
    .travel-banner .travel-slider .single-banner-info p {
      font-size: 16px; }
    .travel-banner .travel-slider .single-banner-info h1 {
      font-size: 48px; }
  .travel-banner .slider-social .play-btn {
    margin-left: 0px;
    font-size: 16px; } }

@media (max-width: 575px) {
  .banner.banner-1 {
    padding: 140px 0 60px; }
    .banner.banner-1 h1 {
      font-size: 48px; }
    .banner.banner-1 h2 {
      font-size: 28px; }
  .banner-02 .swiper-slide p {
    font-size: 16px;
    margin-bottom: 20px; }
  .banner-02 .pagination-button .swiper-button-prev {
    bottom: 20px; }
  .banner-02 .pagination-button .swiper-button-next {
    bottom: 20px; }
  .banner .home-search .input-group.date .input-group-text {
    border-radius: 30px; }
  .banner .home-search .select2-container--default .select2-selection--single {
    border-radius: 30px !important; }
  .banner .home-search .form-location .form-control {
    border-radius: 5px !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important; }
  .banner .home-search .btn {
    border-radius: 30px !important; }
  .travel-banner .travel-slider .single-banner-info h1 {
    font-size: 36px; } }

@media (max-width: 479px) {
  .travel-banner .travel-slider {
    height: 350px; }
    .travel-banner .travel-slider .single-banner-info {
      padding-top: 130px; }
      .travel-banner .travel-slider .single-banner-info h1 {
        font-size: 30px; }
  .travel-banner .slider-social {
    bottom: 50px; } }

/*****************************
  Shop
*****************************/
/* Product */
.product {
  padding-top: 15px;
  padding-bottom: 15px; }
  .product .add-to-cart {
    padding-top: 20px; }
    .product .add-to-cart a {
      background: #14357b;
      color: #ffffff;
      text-transform: capitalize;
      padding: 10px 20px;
      border-radius: 5px;
      display: inline-block; }
      .product .add-to-cart a:hover {
        background: #3664d1; }
  .product .thumbnail-bg {
    background-color: #f1f8fe; }
  .product .product-image {
    position: relative;
    border-radius: 5px; }
    .product .product-image img {
      transition: all 0.5s ease-in-out;
      border-radius: 5px; }
      .product .product-image img:hover {
        transform: scale(0.9) rotate(0.01deg); }
    .product .product-image .custom-icon {
      position: absolute;
      bottom: 20px;
      right: 30px;
      opacity: 0;
      right: 10px;
      transition: all 0.3s ease-in-out; }
      .product .product-image .custom-icon ul li a {
        font-size: 18px;
        color: #3664d1;
        display: block;
        line-height: 42px;
        background: #ffffff;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out; }
      .product .product-image .custom-icon ul li a.icon-bg {
        background: #f3f9ff; }
      .product .product-image .custom-icon ul li:hover a {
        color: #f9d448;
        background: #14357b; }
  .product .product-description {
    padding: 30px 20px; }
    .product .product-description .product-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; }
    .product .product-description .product-title {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 200px; }
      .product .product-description .product-title a {
        font-size: 20px;
        font-family: "Jost", sans-serif;
        font-weight: 600;
        color: #3664d1; }
        .product .product-description .product-title a:hover {
          color: #14357b; }
    .product .product-description .product-rating {
      margin-bottom: 0px; }
    .product .product-description .product-price {
      font-size: 18px;
      font-weight: 600; }
      .product .product-description .product-price ins {
        color: #14357b;
        text-decoration: none; }
  .product:hover .custom-icon {
    opacity: 1;
    right: 20px; }

.product.product-list {
  align-items: center; }
  .product.product-list .product-description {
    padding: 0px 20px; }

/* Product Detail */
.shop-single .nav-tabs-02 {
  border: inherit; }
  .shop-single .nav-tabs-02 .nav-item {
    margin: 0 5px 10px; }
    .shop-single .nav-tabs-02 .nav-item .nav-link {
      font-size: 16px;
      padding: 10px 30px;
      border-radius: 5px;
      border: inherit;
      color: #ffffff;
      background-color: #14357b; }
    .shop-single .nav-tabs-02 .nav-item .nav-link.active {
      border: inherit;
      background-color: #3664d1;
      color: #ffffff; }
  .shop-single .nav-tabs-02 .tab-content {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 30px;
    border-radius: 5px; }

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-price h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-price h4 span {
      font-size: 24px;
      color: #14357b;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #14357b; }

.shop-single .product-detail .add-to-cart-input .form-control {
  height: 54px; }

.shop-single .product-detail .product-summary {
  margin-top: 30px; }
  .shop-single .product-detail .product-summary a {
    margin-right: 10px;
    color: #14357b; }
    .shop-single .product-detail .product-summary a i {
      color: #14357b;
      margin-right: 10px; }
    .shop-single .product-detail .product-summary a:hover {
      color: #3664d1; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }
    .shop-single .product-detail .product-detail-meta span a {
      color: #14357b; }
      .shop-single .product-detail .product-detail-meta span a:hover {
        color: #3664d1; }

.shop-single .product-detail .product-detail-social {
  display: flex;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 10px 0; }
  .shop-single .product-detail .product-detail-social span {
    font-weight: 800; }
  .shop-single .product-detail .product-detail-social ul {
    padding-left: 15px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-detail-social ul li {
      display: inline-block;
      list-style-type: none;
      float: left;
      margin-right: 20px;
      font-size: 16px; }
      .shop-single .product-detail .product-detail-social ul li a {
        color: #14357b; }
        .shop-single .product-detail .product-detail-social ul li a:hover {
          color: #3664d1; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.slider-slick .slider-for .slick-prev {
  left: 0; }
  .slider-slick .slider-for .slick-prev:before {
    content: inherit; }

.slider-slick .slider-for .slick-next {
  right: 0; }
  .slider-slick .slider-for .slick-next:before {
    content: inherit; }

.slider-slick .slider-nav .slick-next:before {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 900; }

.slider-slick .slider-nav .slick-prev:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: 999;
  color: #ffffff;
  font-size: 20px;
  content: "\f053";
  font-family: "Font Awesome 6 Free";
  font-weight: 900; }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
  border-radius: 3px; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
  border-radius: 3px; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #14357b;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #14357b;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/*commentlist*/
.commentlist {
  display: flex; }
  .commentlist .comment-author {
    width: 70px;
    flex: 0 0 70px; }
    .commentlist .comment-author img {
      border-radius: 100%;
      border: 4px solid #ffffff;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1); }
  .commentlist .comment-content {
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    padding: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px; }
    .commentlist .comment-content .reviews {
      display: flex; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #14357b; }

.form-check-label {
  padding-left: 15px; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #ededed;
  width: 100%;
  background: #ffffff; }

/* Checkout */
.checkout.checkout-form {
  border-radius: 5px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  padding: 30px; }

.checkout-info {
  padding: 25px 25px 25px 25px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  display: flex;
  align-items: center; }
  .checkout-info .returning-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-coupon .checkout-info {
  display: flex;
  align-items: center; }
  .checkout-info-coupon .checkout-info .coupon-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #ededed;
  border-radius: 3px; }

.checkout-review {
  padding: 15px 30px 30px;
  border-radius: 3px;
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07); }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    color: #3664d1; }
  .checkout-review .table tr th.shipping {
    font-size: 14px; }
  .checkout-review .table tr td {
    text-align: left;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500;
    color: #858585; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }
  .checkout-review .table tbody {
    border-bottom: 2px solid #ededed; }

/* Cart */
.cart-table {
  box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
  border-radius: 5px; }
  .cart-table .table {
    margin-bottom: 0;
    font-size: 14px; }
    .cart-table .table tr th {
      border: none;
      padding: 15px 10px;
      color: #ffffff;
      background-color: #14357b;
      text-align: left;
      vertical-align: middle; }
    .cart-table .table tr td {
      border: none;
      border-top: 1px solid #ededed;
      padding: 15px 10px;
      font-weight: 500;
      text-align: left;
      vertical-align: middle; }
      .cart-table .table tr td.product-remove {
        padding: 15px 25px;
        text-align: center; }
        .cart-table .table tr td.product-remove a {
          color: #ff0000; }
      .cart-table .table tr td.product-quantity .form-control {
        width: 100px; }
      .cart-table .table tr td a:hover {
        color: #14357b; }
    .cart-table .table thead tr {
      border: none; }
      .cart-table .table thead tr th:last-child {
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .cart-table .table thead tr th:first-child {
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .cart-table .table tbody tr td.product-name a {
      color: #3664d1; }
      .cart-table .table tbody tr td.product-name a:hover {
        color: #14357b; }
  .cart-table .actions {
    display: flex;
    border-top: none;
    padding: 15px 10px;
    align-items: center; }
    .cart-table .actions .coupon form {
      display: flex; }
    .cart-table .actions .coupon .input-text {
      border: 1px solid #ededed;
      border-radius: 0px;
      height: 48px;
      padding: 10px 20px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 30px;
      margin-right: 10px; }
    .cart-table .actions .coupon .btn {
      padding: 12px 20px; }
    .cart-table .actions .update-cart {
      margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  padding: 20px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table {
    font-size: 14px; }
    .cart-totals .table tr th {
      text-align: left;
      border: none;
      border-top: 1px solid #ededed;
      vertical-align: top;
      background: #ffffff; }
    .cart-totals .table tr td {
      text-align: right;
      border: none;
      border-top: 1px solid #ededed;
      background: #ffffff; }
    .cart-totals .table tr.shipping .form-check-label {
      padding-left: 0px; }
    .cart-totals .table tr.shipping p {
      margin-bottom: 0; }
    .cart-totals .table tr.order-total th {
      font-size: 18px;
      font-weight: 600;
      color: #3664d1; }
    .cart-totals .table tr.order-total td {
      font-size: 26px;
      font-weight: 600;
      color: #3664d1; }
    .cart-totals .table .cart-subtotal th {
      font-size: 18px;
      font-weight: 600;
      border: none; }
    .cart-totals .table .cart-subtotal td {
      font-size: 18px;
      font-weight: 600;
      border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px; }

/* Shop Category */
.shop-category {
  padding: 25px 15px; }
  .shop-category h5 {
    margin-bottom: 20px; }
  .shop-category ul {
    margin-bottom: 0; }
    .shop-category ul li a {
      padding: 5px 0;
      display: block; }

@media (max-width: 768px) {
  .slider-slick .slider-nav .slick-prev {
    display: none !important; }
  .slider-slick .slider-nav .slick-next {
    display: none !important; }
  .shop-single .nav-tabs-02 .nav-item .nav-link {
    font-size: 14px; }
  .cart-table .actions {
    display: block; } }

@media (max-width: 575px) {
  .cart-table .actions {
    display: block; }
    .cart-table .actions .coupon form {
      display: block; }
    .cart-table .actions .coupon #coupon_code {
      margin-bottom: 10px; }
    .cart-table .actions .coupon .btn {
      margin-bottom: 10px; } }

/*****************************
  Footer
*****************************/
.footer .min-footer {
  padding: 80px 0 70px 0; }
  .footer .min-footer .social-icon ul li a {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    background: #ffffff;
    box-shadow: 0px 7px 16px 0px rgba(9, 28, 58, 0.07);
    border-radius: 100%;
    transition: all 0.5s ease;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .footer .min-footer .social-icon ul li a:hover {
      background-color: #14357b;
      color: #f9d448; }

.footer .footer-title {
  margin-bottom: 40px; }

.footer .footer-logo {
  width: 150px;
  display: block; }

.footer .footer-contact-info ul {
  width: 100%; }
  .footer .footer-contact-info ul li {
    display: flex;
    margin-bottom: 30px;
    align-items: center; }
    .footer .footer-contact-info ul li i {
      font-size: 22px;
      min-width: 25px;
      margin-right: 10px;
      color: #3664d1; }
    .footer .footer-contact-info ul li img {
      height: 36px; }
    .footer .footer-contact-info ul li span {
      color: #091c3a;
      margin-left: 20px; }
    .footer .footer-contact-info ul li span.phone {
      font-size: 16px;
      color: #858585; }
    .footer .footer-contact-info ul li span.address {
      color: #858585; }
    .footer .footer-contact-info ul li span.email {
      color: #858585; }

.footer .footer-link ul li:last-child {
  margin-bottom: 0; }

.footer .footer-link ul li a {
  color: #091c3a; }
  .footer .footer-link ul li a:hover {
    color: #14357b; }

.footer .footer-bottom {
  padding: 25px 0; }
  .footer .footer-bottom .copyright p {
    font-size: 15px;
    margin-bottom: 0;
    color: #ffffff; }
    .footer .footer-bottom .copyright p a {
      color: #091c3a; }
      .footer .footer-bottom .copyright p a:hover {
        color: #3664d1; }
  .footer .footer-bottom .copyright .copyright-menu ul {
    padding: 0; }
    .footer .footer-bottom .copyright .copyright-menu ul li a {
      color: #ffffff;
      padding: 0px 0px 0px 30px; }
      .footer .footer-bottom .copyright .copyright-menu ul li a:hover {
        color: #3664d1; }
    .footer .footer-bottom .copyright .copyright-menu ul li:first-child a {
      padding-left: 0; }

.footer .footer-gallery {
  display: flex;
  flex-wrap: wrap; }
  .footer .footer-gallery li {
    margin: 0px 4px 10px 4px; }
    .footer .footer-gallery li a {
      display: inline-block;
      border-radius: 5px;
      position: relative;
      z-index: 2;
      transition: all 0.5s ease; }
      .footer .footer-gallery li a:before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 0;
        bottom: 0;
        background-color: rgba(6, 16, 54, 0.5);
        border-radius: 3px;
        transition: all 0.5s ease; }
      .footer .footer-gallery li a:hover:before {
        height: 100%;
        top: 0; }
      .footer .footer-gallery li a img {
        width: 90px;
        border-radius: 3px; }

.footer .footer-menu ul {
  display: flex;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap; }
  .footer .footer-menu ul li {
    display: flex; }
    .footer .footer-menu ul li a {
      color: #ffffff;
      padding: 10px 35px 10px 0;
      font-size: 16px; }
      .footer .footer-menu ul li a:hover {
        color: #14357b; }
    .footer .footer-menu ul li:last-child {
      padding-right: 0px; }

.footer .footer-menu.dark-text ul {
  display: inline-block;
  padding: 0;
  margin-bottom: 0; }
  .footer .footer-menu.dark-text ul li a {
    color: #858585;
    padding: 0 0 15px; }
    .footer .footer-menu.dark-text ul li a:hover {
      color: #14357b; }
  .footer .footer-menu.dark-text ul li:last-child a {
    padding-bottom: 0; }

.footer.footer-style-01 .min-footer {
  background-color: #14357b; }
  .footer.footer-style-01 .min-footer p {
    color: #ffffff; }
  .footer.footer-style-01 .min-footer .social-icon ul li a:hover {
    background-color: #061036;
    color: #f9d448; }

.footer.footer-style-01 .footer-title {
  color: #ffffff; }

.footer.footer-style-01 .footer-contact-info ul li i {
  color: #f9d448; }

.footer.footer-style-01 .footer-contact-info ul li span {
  color: #ffffff; }

.footer.footer-style-01 .footer-menu.dark-text ul li a {
  color: #ffffff; }
  .footer.footer-style-01 .footer-menu.dark-text ul li a:hover {
    color: #f9d448; }

.footer.footer-style-01 .footer-bottom {
  background-color: #061036; }
  .footer.footer-style-01 .footer-bottom .copyright a {
    color: #f9d448; }
    .footer.footer-style-01 .footer-bottom .copyright a:hover {
      color: #ffffff; }
  .footer.footer-style-01 .footer-bottom .copyright .copyright-menu ul li a:hover {
    color: #f9d448; }

/*footer-style-02*/
.footer.footer-style-02 .min-footer {
  background-color: #f3f9ff; }
  .footer.footer-style-02 .min-footer .footer-maps {
    height: 220px;
    border-radius: 5px; }
    .footer.footer-style-02 .min-footer .footer-maps iframe {
      border-radius: 5px; }
  .footer.footer-style-02 .min-footer form .form-control {
    border-radius: 30px;
    margin-bottom: 15px; }

.footer.footer-style-02 .footer-menu ul {
  padding: 0;
  margin: 0;
  display: block; }
  .footer.footer-style-02 .footer-menu ul li a {
    color: #858585;
    padding: 0;
    padding-bottom: 15px; }
    .footer.footer-style-02 .footer-menu ul li a:hover {
      color: #14357b; }
  .footer.footer-style-02 .footer-menu ul li:last-child a {
    padding-bottom: 0; }

.footer.footer-style-02 .social-icon ul {
  padding: 0; }
  .footer.footer-style-02 .social-icon ul li a:hover {
    color: #f9d448; }

.footer.footer-style-02 .footer-bottom {
  padding: 15px 0;
  background-color: #061036; }
  .footer.footer-style-02 .footer-bottom .copyright .copyright-logo .footer-logo {
    display: inline-block; }
    .footer.footer-style-02 .footer-bottom .copyright .copyright-logo .footer-logo img {
      height: 40px; }
  .footer.footer-style-02 .footer-bottom .copyright p a {
    color: #f9d448; }
    .footer.footer-style-02 .footer-bottom .copyright p a:hover {
      color: #ffffff; }

.footer.footer-style-02 .footer-contact-info ul li span {
  margin-left: 0px; }

.footer.footer-style-03 .newsletter-top .footer-title {
  margin-bottom: 15px; }

.footer.footer-style-03 .newsletter-top p {
  margin-bottom: 20px; }

.footer.footer-style-03 .min-footer {
  padding: 100px 0 50px 0; }
  .footer.footer-style-03 .min-footer .social-icon.space-right ul {
    justify-content: end;
    display: flex; }
    .footer.footer-style-03 .min-footer .social-icon.space-right ul li a {
      margin-right: 60px; }
    .footer.footer-style-03 .min-footer .social-icon.space-right ul li:last-child a {
      margin-right: 0px; }
  .footer.footer-style-03 .min-footer .b-bottom {
    border-bottom: 1px solid rgba(20, 53, 123, 0.1);
    margin: 30px 0px 50px; }
  .footer.footer-style-03 .min-footer form .form-control {
    margin-bottom: 20px; }
  .footer.footer-style-03 .min-footer form .btn {
    padding: 13px 40px; }
  .footer.footer-style-03 .min-footer .copyright a:hover {
    color: #f9d448; }

.newsletter-form {
  position: relative; }
  .newsletter-form .form-control {
    border-radius: 50px;
    width: 500px;
    padding-right: 175px; }
  .newsletter-form button {
    position: absolute;
    top: 0;
    right: 0; }
  .newsletter-form .btn {
    padding: 13px 40px; }

@media (max-width: 1199px) {
  .newsletter-form .form-control {
    width: 400px; } }

@media (max-width: 991px) {
  .footer .min-footer {
    padding: 60px 0 50px 0; }
  .footer.footer-style-03 .min-footer {
    padding-top: 60px; }
    .footer.footer-style-03 .min-footer .social-icon.space-right ul li a {
      margin-right: 30px; }
    .footer.footer-style-03 .min-footer .b-bottom {
      margin: 40px 0px 40px; }
  .newsletter-form .form-control {
    width: 450px; } }

@media (max-width: 767px) {
  .footer .footer-title {
    margin-bottom: 20px; }
  .footer .footer-contact-info ul li {
    margin-bottom: 15px; }
  .footer .min-footer {
    padding: 50px 0 50px 0; }
    .footer .min-footer .newsletter {
      padding-bottom: 30px;
      margin-bottom: 30px; }
    .footer .min-footer .footer-map {
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit; }
  .footer.footer-style-03 .min-footer {
    padding: 50px 0 25px 0; }
    .footer.footer-style-03 .min-footer .social-icon.space-right ul {
      justify-content: start; }
    .footer.footer-style-03 .min-footer .b-bottom {
      margin: 30px 0px 30px; } }

@media (max-width: 575px) {
  .footer .newsletter {
    flex-direction: column; }
  .footer .min-footer {
    padding: 40px 0 40px 0; }
  .footer .footer-menu ul li a {
    padding: 6px 25px 6px 0; }
  .footer.footer-style-02 .social-icon.social-rounded ul li a {
    margin: 0px 7px; }
  .newsletter-form .form-control {
    width: 290px; } }
