/*****************************
  Destination
*****************************/
.destination-plan {
    .destination-icon{
      overflow: hidden;
      border-radius: $border-radius-lg;
       img{
          transform: scale(1);
          transition: transform 7s cubic-bezier(.25, .46, .45, .94);
          min-height: 100%;
          object-fit: cover;
       }
    }
  .destination-content {
    height: 90%;
    width: 90%;
    background: $white;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid  rgba($black, 0.1);

    img {
      width: 50px;
      height: auto;
    }

    a {
      font-family: $font-hedding;
      color: $black;
      font-weight: 600;
      font-size: 24px;
      &:hover {
        color: $primary;
      }

    }

  }

  &:hover {
    .destination-content {
      opacity: 1;
    }

    .destination-icon{
       img{
           transform: scale(1.1);
       }
    }
  }

}

/*Destination-style-02*/
.destination-plan.destination-style-02 {
  overflow: hidden;
    .destination-icon{
      overflow: hidden;
      border-radius: $border-radius;
      img{
        transform: scale(1);
        transition: transform 7s cubic-bezier(.25, .46, .45, .94);
        min-height: 100%;
        object-fit: cover;
      }
    }
  .destination-content {
    height: auto;
    width: 100%;
    background: inherit;
    margin: 0 auto;
    border-radius: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: inherit;
    bottom: -45px;
    transform: inherit;
    opacity: 1;
    transition: all 0.7s ease;
    display: block;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 9;

    img {
      width: 50px;
      height: auto;
      margin: 0 auto;
    }

    a {
      font-family: $font-hedding;
      color: $white;
      font-weight: 600;
      font-size: 24px;
      &:hover {
        color: $yellow;
      }
    }
    a.btn{
      font-family: $font-base;
      font-size: 14px;
       &:hover {
        color: $yellow;
      }
    }
    .destination-round{
      margin-bottom: 20px;
    }
  }
  &:before{
    position: absolute;
    content: "";
    background: linear-gradient(to bottom, rgba($black, 0) 0, rgba($black, 0.6) 100%);
    left: 0;
    width: 100%;
    height: 75%;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease;
  }

  &:hover {
    .destination-content {
      bottom: 20px;
    }

    .destination-icon{
       img{
           transform: scale(1.1);
       }
    }

    &:before{
      height: 100%;
    }

  }

}


.destination-section{
  .owl-carousel.owl-nav-center{
    .owl-nav {
      .owl-next{
        right: 0;
        opacity: 0;
        transition: all 0.5s ease;
      }
      .owl-prev{
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
      }
    }

    &:hover{
      .owl-nav {
        .owl-next{
          right: 15px;
          opacity: 1;
        }
        .owl-prev{
          left: 15px;
          opacity: 1;
        }
      }
    }
  } 
}

/*Destination Price*/
.destination-price-item {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  .destination-img {
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    &:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba($dark, 0) 0, rgba($dark, 1) 100%);
      top: auto;
      height: 0%;
      transition: all .35s;
      border-radius: $border-radius;
    }
    img{
      border-radius: $border-radius;
    }

    .rating {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9;

      a {
        color: $white;
        background-color: $yellow;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: bold;
        border-radius: $border-radius-sm;
        i{
          font-size: 10px;
        }

        &:hover {
          color: $dark;
        }

      }

    }
    .destination-info {
      position: absolute;
      z-index: 9;
      padding: 25px;
      border-radius: $border-radius;
      background-color: $white;
      box-shadow:$box-shadow;
      transition: all 0.3s ease-in-out;
      width: 90%;
      left: 5%;
      bottom: 20px;

      .destination-title {
        font-size: 22px;
        color: $secondary;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        &:hover {
          color: $yellow;
        }

      }

      .destination-price{
        font-size: 18px;
        color: $gray-3;
        &:hover {
          color: $primary;
        }
      }

      .loaction {
        position: absolute;
        opacity: 0;
        width: 80%;
        transition: all 0.5s ease-in-out;
        a {
          color: $secondary;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0 0;
          display: inline-block;
          max-width: 100%;
          font-size: 16px;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  &:hover {
    .destination-img{
      &:before {
        height: 100%;
      }
      .destination-info {
        padding-bottom: 60px;
        .loaction {
          opacity: 1;

        }
      }
    }
  }
}

/*Destination Trips*/
.destination-trips-item {
  overflow: hidden;

  .trips-img {
    position: relative;

    &:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba($dark, 0) 0, rgba($dark, 0.6) 100%);
      top: auto;
      height: 75%;
      transition: all .35s;
    }

    img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(.25, .46, .45, .94);
      min-height: 100%;
      object-fit: cover;
    }

    .trips-content {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 30px 25px;
      width: 100%;
      z-index: 9;

      .trips-title {
        color: $white;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex;

        &:hover {
          color: $yellow;
        }

      }

      .trips-info {
        font-size: 14px;
        color: $white;
        display: flex;

        &:hover {
          color: $yellow;
        }

      }

    }

  }

  &:hover {
    .trips-img {
      img {
        transform: scale(1.1);
      }
      &:before {
        height: 100%;
        background: linear-gradient(to bottom, rgba($dark, 0) 0, rgba($dark, 0.8) 100%);
      }
    }

  }

}

/*Destination Trips*/
.destination-trips-main {
  position: relative;

  .destination-trips-content {
    position: absolute;
    top: 0;
    left: 16.66%;
    width: 16.66%;
    z-index: 99;
    height: 100%;
    background-color: $gray-2;
    color: $white;
    padding: 50px 50px;
    text-align: center;
    .content{
      img{
        height: 50px;
      }
      h5{
        font-size: 20px;
        line-height: 40px;
        font-weight: bold;
      }
    }
  }

  .destination-trips-item.trips-style-2 {
    overflow: hidden;
    border-radius: $border-radius;

    .trips-img {
      position: relative;
      border-radius: $border-radius;

      &:before {
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: linear-gradient(to bottom, rgba($black, 0) 0, rgba($black, 1) 100%);
        top: auto;
        height: 50%;
        transition: all .35s;
      }

      img {
        transform: scale(1);
        transition: transform 7s cubic-bezier(.25, .46, .45, .94);
        min-height: 100%;
        object-fit: cover;
        border-radius: $border-radius;
      }

      .video {
        a {
          color: $gray-2;
          position: absolute;
          top: 15px;
          right: 15px;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          background-color: $white;
          border-radius: $border-radius-lg;
          z-index: 99;
          display: flex;
          opacity: 0;
          transition: all 0.3s ease-in-out;

          &:before {
            content: "";
            position: absolute;
            z-index: -9;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 50px;
            height: 50px;
            background: $white;
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
          }

        }

      }

      .trips-content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px 25px 20px;
        width: 100%;
        z-index: 9;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        .rating {
          ul {
            li {
              a {
                font-size: 14px;
                color: $yellow;
              }

            }

          }

        }

        .trips-title {
          color: $white;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 10px;
          display: flex;

          &:hover {
            color: $yellow;
          }

        }

        .trips-price {
          font-size: 16px;
          color: $white;
          display: flex;

          &:hover {
            color: $yellow;
          }

        }

      }



    }

    &:hover {
      .trips-img {
        img {
          transform: scale(1.1);
        }

        &:before {
          height: 100%;
        }
      }

      .video {
        a {
          opacity: 1;
        }

      }

      .trips-content {
        opacity: 1;
      }

    }


    .destination-trips-content {
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color:rgba($primary,0.9);
      color: $white;
      padding: 50px 50px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .content{
        img{
          height: 50px;
          transform: scale(1);
        }
        h5{
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;
        }
        .btn.btn-link{
          color: $white;
          &:hover{
            color:$yellow ;
          }
        }
      }
    }

  }

  .destination-trips-item.trips-style-2.active {
    .trips-content {
      opacity: 1;
    }

    .video {
      a {
        opacity: 1;
      }

    }

  }

  .owl-carousel {
    z-index: inherit;

    .owl-nav {
      position: absolute;
      left: 22%;
      bottom: 15%;
      z-index: 99;
      display: flex;

      button {
        margin: 0 5px;
      }

    }

  }

}
/*Destination-plan-2*/

.destination-plan-2 {
  max-height: 630px;
  overflow-y: auto;

  .nav-link {
    width: 50%;

    .listing-details {
      padding: 200px 40px 40px;
      transition: all 0.5s ease-in-out;
      background-color: $secondary;
       background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      z-index: 2;
      &:before{
        position:absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary,0.3);
        z-index: -1;
        transition: all 0.5s ease-in-out;
      }
      .listing-loaction {
       span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
       }
      }

      .icon-location {
        width: 15px;
        filter: invert(100%) sepia(0%) saturate(23%) hue-rotate(4deg) brightness(105%) contrast(108%);
        transition: all 0.5s ease-in-out;
      }

      span {
        font-size: 12px;

      }

      .listing-title {
        .title{
          font-size: 24px;
          font-weight: bold;
          color: $white;
        }
        .country-flags {
          h6 {
            font-family: $font-hedding;
          }

        }

      }

      &:hover {
        color: $white;
        background-color: $primary;

        .icon-location {
          filter: invert(98%) sepia(94%) saturate(2535%) hue-rotate(319deg) brightness(102%) contrast(103%);
        }

      }
    }
    &:hover {
      .listing-details {
        .listing-title {
          .title{
            color: $yellow;
          }
        }
        &:before{
          background-color: rgba($dark,0.8);
        }

      }
    }

  }

  .nav-link.active{
    .listing-details{
      background-color: $primary;
      .listing-title {
          .title{
            color: $yellow;
          }
        }
      &:before{
        background-color:rgba($dark,0.8);
        z-index: -1;
      }
    }
     .icon-location {
        filter: invert(98%) sepia(94%) saturate(2535%) hue-rotate(319deg) brightness(102%) contrast(103%);
     }
  }

  .united-states{
    .listing-details {
      background-image: url(../images/destination/24.jpg);
    }
  }

  .united-kingdom{
    .listing-details {
      background-image: url(../images/destination/25.jpg);
    }
  }

  .india{
    .listing-details {
      background-image: url(../images/destination/26.jpg);
    }
  }


  .mexico{
    .listing-details {
      background-image: url(../images/destination/28.jpg);
    }
  }

  .malaysia{
    .listing-details {
      background-image: url(../images/destination/30.jpg);
    }
  }

   .qatar{
    .listing-details {
      background-image: url(../images/destination/33.jpg);
    }
  }

  .sri-lanka{
    .listing-details {
      background-image: url(../images/destination/34.jpg);
    }
  }

  .switzerland{
    .listing-details {
      background-image: url(../images/destination/35.jpg);
    }
  }


  .france{
    .listing-details {
      background-image: url(../images/destination/37.jpg);
    }
  }
}


.upcomming-plan {
  position: relative;
  .upcomming {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 20px;
    .upcomming-box {
      padding: 20px;
      background: rgba($white, 0.85);
      height: 100%;
      .upcomming-info {
        height: 100%;
        border: 3px dashed $primary;
        background-color: rgba($white, 0.60);
        padding: 30px;
        .upcomming-logo{
          width: 200px;
        }
        .title{
          font-weight: 600;
          margin: 25px 0px;
        }
      }
    }
  }

}

/*Destination Details*/
.destinations-details {
  .img-details{
     position: relative;
     overflow: hidden;
     border-radius: $border-radius;
    img{
        transform: scale(1);
        transition: -webkit-transform 7s;
        transition: transform 7s;
        transition: transform 7s, -webkit-transform 7s;
        min-height: 100%;
        object-fit: cover;
    }
  }

  .destinations-content{
    position: relative;
    z-index: 2;
    margin: -50px 20px 30px;
    padding: 30px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color: $white;
    .title{
      font-weight: 600;
    }
  }

  .country-info {
    padding: 30px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color: $white;
    margin-bottom: 30px;
    .country-item {
      display: flex;
      padding: 20px 0;

      .country-name {
        width: 45%;

        .icon {
          font-size: 16px;
          margin-right: 20px;
          color: $primary;
        }

      }

      .description {
        width: 45%;
      }

      .flags {
        width: 10%;
        text-align: end;
      }

    }

  }
  &:hover{
    .img-details{
      img{
         transform: scale(1.1);
      }
    }
  }
}

/*Booking 03*/
.booking-03 {
  background-color: $white;
  padding: 40px;

  .success-message {
    padding: 10px;
    background-color: rgba($success, 0.15);
    border-radius: $border-radius;
  }

  .booking-info {
    .booking-info-contant {
      padding: 40px;
      margin: -50px 40px 0;
      position: relative;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      background-color: $white;

      object.icon {
        width: 22px;
        filter: invert(52%) sepia(62%) saturate(510%) hue-rotate(179deg) brightness(98%) contrast(95%);
        margin-right: 10px;
      }

      .coupon-form {
        width: 75%;

        .btn {
              padding: 12px 40px;
              display: flex;
              align-items: center;
          }
      }

    }

  }

}

.destination-find-area {
  display: flex;
  background: rgba($white,0.3);
  border-radius: 50px;
  padding: 10px;
  >div {
    width: 25%;
    box-shadow: 0 3px 15px rgba($black,0.04);;
  }
  .form-btn-are {
    a {
      white-space: nowrap;
      width: 100%;
    }
  }
}

/*Home 03*/
.destination-find-section {
  z-index: 9;
  position: relative;
  margin-top: -50px;

  .home-search{
    background-color: $white;
    padding: 50px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }
  .destination-find-area {
    display: flex;
    background: inherit;
    border-radius: $border-radius-md;
    padding: 0px;
    >div {
      width: 25%;
      box-shadow:inherit;
    }
    .form-btn-are {
      a {
        white-space: nowrap;
        width: 100%;
      }
    }

    .form-select.select-fast {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
      height: 60px;
    }

    .select-fast {
      .select2-container--default {
        .select2-selection--single {
          border-bottom-left-radius: $border-radius-md;
          border-top-left-radius: $border-radius-md;
        }

      }

    }

    .select2-container--default {
      .select2-selection--single {
        border-radius: 0;
        height: 60px !important;
        line-height: 30px;

        .select2-selection__rendered {
          color: $body-color;
        }

        .select2-selection__arrow {
          &:before {
            color: $body-color;
          }

        }

      }

    }
    .input-group.date{
      .input-group-text{
        border-radius: 0;
      }
    } 

    .btn {
      height: 60px;
      line-height: 30px;
      border-radius: 0;
      border-bottom-right-radius: $border-radius-md;
      border-top-right-radius: $border-radius-md;

      &:before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}


/*****************************
    Responsive
*****************************/
@media (max-width:1600px) {
  .destination-trips-main {
    .owl-carousel {
      .owl-nav {
        position: absolute;
        left: 21%;
        bottom: 13%;

      }

    }

    .destination-trips-content {
      padding: 20px;
    }

  }

}

@media (max-width:1399px) {
  .destination-trips-main {
    .destination-trips-content {
      width: 20%;
      left: 20%;
    }

    .owl-carousel {
      .owl-nav {
        left: 25%;
      }

    }

  }

  .upcomming-plan {
    .upcomming {
      .upcomming-box{
        .upcomming-info {
          .upcomming-logo{
            width: 200px;
          }
        }
      }
      

    }

  }

}

@media (max-width:1299px) {
  .destination-trips-main {
    .destination-trips-content {
      width: 25%;
      left: 25%;
    }

    .owl-carousel {
      .owl-nav {
        left: 32%;
      }

    }

  }

}

@media (max-width:1199px) {
  .destination-trips-main {
    .destination-trips-content {
      width: 33.33%;
      left: 0;
    }

    .owl-carousel {
      .owl-nav {
        left: 11%;
      }

    }

  }

  .destination-plan-2 {
    .listing-details {
      padding: 20px 25px;
    }

  }

}

@media (max-width:991px) {
  .destination-trips-main {
    .destination-trips-content {
      padding: 40px;
    }

    .owl-carousel {
      .owl-nav {
        button i {
          width: 40px;
          height: 40px;
        }

      }

    }

  }

  .booking-03 {
    padding: 30px;

    .booking-info {
      .booking-info-contant {
        padding: 30px;
        margin: -40px 30px 0;
      }

    }

  }

  .destination-find-section{
    .destination-find-area {
      flex-wrap: wrap;
      background: none;
      border-radius: inherit;
      grid-gap: 15px 0;
      >div {
        width: 50%;
      }
      .select-secon{
        .select2-container--default{
          .select2-selection--single{
            border-bottom-right-radius:$border-radius-md;
            border-top-right-radius:$border-radius-md;
          }
        }
      }

      .form-location {
        #datetimepicker-01{
          .form-control{
            border-bottom-left-radius:$border-radius-md;
            border-top-left-radius:$border-radius-md;
          }
        }

        #datetimepicker-02{
          .input-group-text{
             border-bottom-right-radius:$border-radius-md;
            border-top-right-radius:$border-radius-md;
          }
        }
      }
      .btn{
        border-radius: $border-radius-md;
      }
    }
  }

}

@media (max-width:767px) {
  .destination-trips-main {
    .destination-trips-content {
      width: 50%;
      left: 0;
      padding: 15px;
    }

    .owl-carousel {
      .owl-nav {
        left: 17%;

        button i {
          width: 35px;
          height: 35px;
        }

      }

    }

  }

  .destination-trips-item {
    .trips-img {
      .trips-content {
        padding: 15px;
      }

    }

  }

  .destination-plan-2 {
    .nav-link {
      width: 100%;

      .listing-details{
        padding: 100px 40px 40px;
      }
    }
    .tab-content.map {
      max-height: 450px;
    }



  }

  .booking-03 {
    padding: 20px;

    .booking-info {
      .booking-info-contant {
        padding: 20px;
        margin: -30px 20px 0;
      }

    }

  }

    .destination-find-area {
      flex-wrap: wrap;
      background: none;
      border-radius: inherit;
      grid-gap: 15px 0;
      >div {
        width: 50%;
      }
      .select-secon{
        .select2-container--default{
          .select2-selection--single{
            border-bottom-right-radius:$border-radius-md;
            border-top-right-radius:$border-radius-md;
          }
        }
      }

      .form-location {
        #datetimepicker-01{
          .form-control{
            border-bottom-left-radius:$border-radius-md;
            border-top-left-radius:$border-radius-md;
          }
        }

        #datetimepicker-02{
          .input-group-text{
             border-bottom-right-radius:$border-radius-md;
            border-top-right-radius:$border-radius-md;
          }
        }
      }
      .btn{
        border-radius: $border-radius-md;
      }
    }

  
  .categories-tab{
    .nav-tabs{
      .nav-item{
        width: 25%;
        margin-right: 3%;
        margin-bottom: 10px;
      }
    }
  }
  .destination-find-section{
    margin-top: 50px;
    margin-bottom: 50px;
  }

}

@media (max-width:575px) {
  .booking-03 {
    .booking-info {
      .booking-info-contant {
        .coupon-form {
          width: 100%;
        }

      }

    }

  }

  .destination-find-area {
    >div {
      width: 100%;
    }
  }

  .upcomming-plan{
    .upcomming{
      position: inherit;
    }
  } 

  .categories-tab{
    .nav-tabs{
      .nav-item{
        width: 30%;
        margin-right: 3%;
        margin-bottom: 10px;
      }
    }
  }

  .destination-find-section {
    margin-top: 40px;
    margin-bottom: 0px;
  }

}

@media (max-width:480px) {
  .destination-trips-main {
    .destination-trips-content {
      display: none;
    }

    .owl-carousel {
      .owl-nav {
        display: none;
      }

    }

  }

  .categories-tab{
  .nav-tabs{
    .nav-item{
      width: 47%;
      margin-right: 3%;
      margin-bottom: 10px;
    }
  }
}

  .destination-find-section{
    .home-search{
      padding: 40px 20px;
    }
    .destination-find-area {
      >div {
        width: 100%;
      }

      .select-fast{
        .select2-container--default{
          .select2-selection--single{
            border-radius: $border-radius-md;
          }
        }
      }
      .select-secon{
        .select2-container--default{
          .select2-selection--single{
            border-radius: $border-radius-md;
          }
        }
      }

      .form-location {
        #datetimepicker-01{
          .input-group-text{
             border-bottom-right-radius:$border-radius-md;
            border-top-right-radius:$border-radius-md;
          }
          .form-control{
            border-bottom-left-radius:$border-radius-md;
            border-top-left-radius:$border-radius-md;
          }
        }

        #datetimepicker-02{
          .form-control{
            border-bottom-left-radius:$border-radius-md;
            border-top-left-radius:$border-radius-md;
          }
        }
      }
      .btn{
        border-radius: $border-radius-md;
      }
    }
  }


}
