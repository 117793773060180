// Font family
$font-base: 	'Nunito', sans-serif;
$font-hedding:	'Jost', sans-serif;

// Colors
$body-color:		#858585;
$primary:			#14357b;
$secondary:			#3664d1;
$white:				#ffffff;
$dark: 				#061036; 
$gray-1: 			#f3f9ff; 	// bg light
$gray-2: 			#091c3a; 	// Hedding color
$gray-3:			#484848;
$gray-4:			#f1f8fe;
$black:  			#000000;
$red:  				#ff0000;
$yellow:  			#f9d448;
$blue:  			#052c52;
$success:			#0abb0a;
$danger:			#f52626;

// SVG color
$filter-1: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%); // white color

$border-color: 	#ededed;

// For button and input border radius
$border-radius: 5px;
$border-radius-sm: 3px;
$border-radius-md: 30px;
$border-radius-lg: 100%;


// box-shadow
$box-shadow:	  0px 7px 16px 0px rgba($gray-2, .07);
$box-shadow-sm:	0px 2px 5px 0px rgba($gray-2, .10);
$box-shadow-lg:  0 1rem 3rem rgba($gray-2, .175);
$box-shadow-inset: 0px 0px 15px 0px rgba($gray-2, .10) inset;



