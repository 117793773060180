/*****************************
  Footer
*****************************/
.footer {
	.min-footer {
		padding: 80px 0 70px 0;

		.social-icon {
			ul {
				li {
					a {
						margin-right: 10px;
						height: 40px;
						width: 40px;
						background: $white;
						box-shadow: $box-shadow;
						border-radius: $border-radius-lg;
						transition: all 0.5s ease;
						font-size: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						&:hover {
							background-color: $primary;
							color: $yellow;
						}

					}

				}

			}

		}

	}

	.footer-title {
		margin-bottom: 40px;
	}

	.footer-logo {
		width: 150px;
		display: block;
	}

	.footer-contact-info {
		ul {
			width: 100%;

			li {
				display: flex;
				margin-bottom: 30px;
				align-items: center;

				i {
					font-size: 22px;
					min-width: 25px;
					margin-right: 10px;
					color: $secondary;
				}

				img {
					height: 36px;
				}

				span {
					color: $gray-2;
					margin-left: 20px;
				}

				span.phone {
					font-size: 16px;
					color: $body-color;
				}

				span.address {
					color: $body-color;
				}

				span.email {
					color: $body-color;
				}

			}

		}

	}

	.footer-link {
		ul {
			li {
				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: $gray-2;

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.footer-bottom {
		padding: 25px 0;

		.copyright {
			p {
				font-size: 15px;
				margin-bottom: 0;
				color: $white;

				a {
					color: $gray-2;

					&:hover {
						color: $secondary;
					}

				}

			}

			.copyright-menu {
				ul {
					padding: 0;

					li {
						a {
							color: $white;
							padding: 0px 0px 0px 30px;

							&:hover {
								color: $secondary;
							}

						}

						&:first-child {
							a {
								padding-left: 0;
							}

						}

					}

				}

			}

		}

	}

	.footer-gallery {
		display: flex;
		flex-wrap: wrap;

		li {
			margin: 0px 4px 10px 4px;

			a {
				display: inline-block;
				border-radius: $border-radius;
				position: relative;
				z-index: 2;
				transition: all 0.5s ease;

				&:before {
					position: absolute;
					content: "";
					left: 0;
					width: 100%;
					height: 0;
					bottom: 0;
					background-color: rgba($dark, 0.5);
					border-radius: $border-radius-sm;
					transition: all 0.5s ease;
				}

				&:hover {
					&:before {
						height: 100%;
						top: 0;
					}

				}

				img {
					width: 90px;
					border-radius: $border-radius-sm;
				}

			}

		}

	}

	.footer-menu {
		ul {
			display: flex;
			justify-content: center;
			list-style: none;
			flex-wrap: wrap;

			li {
				display: flex;

				a {
					color: $white;
					padding: 10px 35px 10px 0;
					font-size: 16px;

					&:hover {
						color: $primary;
					}

				}

				&:last-child {
					padding-right: 0px;
				}

			}

		}

	}

	.footer-menu.dark-text {
		ul {
			display: inline-block;
			padding: 0;
			margin-bottom: 0;

			li {
				a {
					color: $body-color;
					padding: 0 0 15px;

					&:hover {
						color: $primary;
					}

				}

				&:last-child {
					a {
						padding-bottom: 0;
					}

				}

			}

		}

	}

}

.footer.footer-style-01 {
	.min-footer {
		background-color: $primary;

		p {
			color: $white;
		}

		.social-icon {
			ul {
				li {
					a {
						&:hover {
							background-color: $dark;
							color: $yellow;
						}

					}

				}

			}

		}

	}

	.footer-title {
		color: $white;
	}

	.footer-contact-info {
		ul {
			li {
				i {
					color: $yellow;
				}

				span {
					color: $white;
				}

			}

		}

	}

	.footer-menu.dark-text {
		ul {
			li {
				a {
					color: $white;

					&:hover {
						color: $yellow;
					}

				}

			}

		}

	}

	.footer-bottom {
		background-color: $dark;

		.copyright {
			a {
				color: $yellow;

				&:hover {
					color: $white;
				}

			}

			.copyright-menu {
				ul {
					li {
						a {
							&:hover {
								color: $yellow;
							}

						}

					}

				}

			}

		}

	}

}

/*footer-style-02*/
.footer.footer-style-02 {
	.min-footer {
		background-color: $gray-1;

		.footer-maps {
			height: 220px;
			border-radius: $border-radius;

			iframe {
				border-radius: $border-radius;
			}

		}

		form {
			.form-control {
				border-radius: $border-radius-md;
				margin-bottom: 15px;
			}

		}

	}

	.footer-menu {
		ul {
			padding: 0;
			margin: 0;
			display: block;

			li {
				a {
					color: $body-color;
					padding: 0;
					padding-bottom: 15px;

					&:hover {
						color: $primary;
					}

				}

				&:last-child {
					a {
						padding-bottom: 0;
					}

				}

			}

		}

	}

	.social-icon {
		ul {
			padding: 0;

			li {
				a {
					&:hover {
						color: $yellow;
					}

				}

			}

		}

	}

	.footer-bottom {
		padding: 15px 0;
		background-color: $dark;

		.copyright {
			.copyright-logo {
				.footer-logo {
					display: inline-block;
					img {
						height: 40px;
					}

				}

			}

			p {
				a {
					color: $yellow;

					&:hover {
						color: $white;
					}

				}

			}

		}

	}

	.footer-contact-info {
		ul {
			li {
				span {
					margin-left: 0px;
				}

			}

		}

	}

}

.footer.footer-style-03 {
	.newsletter-top {
		.footer-title {
			margin-bottom: 15px;
		}

		p {
			margin-bottom: 20px;
		}

	}

	.min-footer {
		padding: 100px 0 50px 0;

		.social-icon.space-right {
			ul {
				justify-content: end;
				display: flex;

				li {
					a {
						margin-right: 60px;
					}

					&:last-child {
						a {
							margin-right: 0px;
						}

					}

				}

			}

		}

		.b-bottom {
			border-bottom: 1px solid  rgba($primary, 0.10);
			margin: 30px 0px 50px;
		}

		form {
			.form-control {
				margin-bottom: 20px;
			}

			.btn {
				padding: 13px 40px;
			}

		}

		.copyright {
			a {
				&:hover {
					color: $yellow;
				}

			}

		}

	}

}

.newsletter-form {
	position: relative;

	.form-control {
		border-radius: 50px;
		width: 500px;
		padding-right: 175px;
	}

	button {
		position: absolute;
		top: 0;
		right: 0;
	}

	.btn {
		padding: 13px 40px;
	}

}

@media (max-width:1199px) {
	.newsletter-form {
		.form-control {
			width: 400px;
		}
	}
}

@media (max-width:991px) {
	.footer {
		.min-footer {
			padding: 60px 0 50px 0;
		}

	}

	.footer.footer-style-03 {
		.min-footer {
			padding-top: 60px;

			.social-icon.space-right {
				ul {
					li {
						a {
							margin-right: 30px;
						}

					}

				}

			}

			.b-bottom {
				margin: 40px 0px 40px;
			}

		}

	}

	.newsletter-form {
		.form-control {
			width: 450px;
		}
	}

}

@media (max-width:767px) {
	.footer {
		.footer-title {
			margin-bottom: 20px;
		}

		.footer-contact-info {
			ul {
				li {
					margin-bottom: 15px;
				}

			}

		}

		.min-footer {
			padding: 50px 0 50px 0;

			.newsletter {
				padding-bottom: 30px;
				margin-bottom: 30px;
			}

			.footer-map {
				position: inherit;
				top: inherit;
				left: inherit;
				transform: inherit;
			}

		}

	}

	.footer.footer-style-03 {
		.min-footer {
			padding: 50px 0 25px 0;

			.social-icon.space-right {
				ul {
					justify-content: start;
				}

			}

			.b-bottom {
				margin: 30px 0px 30px;
			}

		}

	}

}

@media (max-width:575px) {
	.footer {
		.newsletter {
			flex-direction: column;
		}

		.min-footer {
			padding: 40px 0 40px 0;
		}

		.footer-menu {
			ul {
				li {
					a {
						padding: 6px 25px 6px 0;
					}

				}

			}

		}

	}

	.footer.footer-style-02 {
		.social-icon.social-rounded {
			ul {
				li {
					a {
						margin: 0px 7px;
					}

				}

			}

		}

	}

	.newsletter-form {
		.form-control {
			width: 290px;
		}
	}

}
