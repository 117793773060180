/*****************************
  Button
*****************************/
.btn-link {
	text-decoration: none;
	color: $primary;
	transition: all 0.3s ease-in-out;
	&:after {
		display: none;
		content: inherit;
	}
	&:hover {
		color: $secondary;
		text-decoration: underline;
	}

}

button {
	outline: medium none !important;
	color: $primary;
}

/* btn */
.btn {
	border: none;
	z-index: 1;
	position: relative;
	font-size: 16px;
	padding: 15px 40px;
	overflow: hidden;
	border-radius: $border-radius-md;
	transition: all 0.3s ease-in-out;
	background-color: $primary;
	&:hover {
		background-color: $dark;
		color: $yellow;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}
}


.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}

	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}

}


.btn.btn-sm {
	padding: 8px 20px;
	font-size: 14px;
}

.btn.btn-md {
	padding: 10px 30px;
	font-size: 14px;
}

.btn.btn-lg {
	padding: 20px 40px;
}

.btn.btn-xl {
	padding: 25px 40px;
}

.btn-app {
	display: flex;

	i {
		font-size: 40px;
	}

}

.btn.btn-link{
	padding: 0;
	background-color: inherit;
  	color: $primary;
  	font-size: 14px;
  	&:before{
  		content:inherit;
  	}

  	&:hover{
		color:$secondary;
		text-decoration: underline;
	}
	&:focus{
		color:$primary;
		text-decoration: underline;
	}

}

.btn.btn-primary{
	background-color:$primary;
	
	&:hover {
		background-color: $dark;
		color: $yellow;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $yellow;
		background-color: $primary;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $yellow;
		background-color: $primary;
	}
}


.bg-secondary{
	.btn.btn-primary{
		background-color:$primary;
		&:before{
			background-color:$white;
		}

		&:hover {
			background-color: $primary;
			color: $primary;
		}
	}
}

.btn.btn-secondary{
	background-color:$secondary;
	
	&:hover {
		background-color: $yellow;
		color: $dark;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
		background-color: $secondary;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
		background-color: $secondary;
	}
}

.bg-primary{
	.btn.btn-secondary{
		background-color:$secondary;
		&:hover {
			background-color: $yellow;
			color: $dark;
		}
	}

}

.btn.btn-light {
	background-color:$white;
	color: $secondary;

	&:hover {
		background-color:$primary;
		color: $yellow;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $secondary;
		background-color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $secondary;
		background-color: $white;
	}
}

.btn.btn-outline-primary {
	border: 2px solid $primary;
	color: $primary;
	background-color: transparent;

	&:hover {
		background-color: $secondary;
		border: 2px solid $secondary;
		color: $yellow;
	}

	&:focus {
		background-color: transparent;
		color: $primary;
		border: 2px solid $primary;
	}

	&:active {
		background-color: transparent;
		color: $primary;
		border: 2px solid $primary;
	}
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
	background: transparent;
	color: $white;
	border: 2px solid $secondary;
}

.btn.btn-outline-secondary {
	border: 2px solid $secondary;
	color: $secondary;
	background-color: transparent;

	&:hover {
		background-color: $primary;
		border: 2px solid $primary;
		color: $yellow;
	}

	&:focus {
		background-color: transparent;
		color: $secondary;
		border: 2px solid $secondary;
	}

	&:active {
		background-color: transparent;
		color: $secondary;
		border: 2px solid $secondary;
	}


}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
	background: transparent;
	color: $white;
	border: 2px solid $primary;
}




/* keyframes */
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }

}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}


/*btn-app*/
.btn-app {
  font-size: 14px;
  padding: 8px 20px;
  background-color: $primary;
  color: $white;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  border-radius: $border-radius;

  i {
    font-size: 30px;
    padding-right: 10px;
  }

  .small-text {
    font-size: 11px;
  }

  span {
    line-height: 14px;
  }

  &:hover {
    background-color: $primary;
    color: $yellow;
  }

}

@media (max-width:575px) {
 
  .btn-app {
    display: inline-flex;
    padding: 8px 14px;

    i {
      font-size: 24px;
      padding-right: 6px;
    }

  }

}



