/*****************************
  Feature Box
*****************************/
.feature-box {
  padding: 40px;

  .icon-box {
    width: 71%;
  }

}

.feature-categories {
  padding: 20px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  color: $secondary;

  .icon {
    margin-bottom: 10px;

    object {
      width: 60px;
      transition: all 0.3s ease-in-out;
    }

  }

  a {
    color: $secondary;
    font-weight: 600;
  }

  &:hover {
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    .icon {
      object {
        transition: all 0.3s ease-in-out;
      }

    }

    a {
      color: $primary;
    }

  }

}

.feature-section {
  a{
    &:hover {
      color:$yellow;
    }
  }
  .newsletter{
    width: 80%;
    .newsletter-form{
      width: 100%;
      .form-control{
        padding-right: 170px;
        width: 100%;
      }
    }
  }
}

/*Feature style 02*/
.feature-box.feature-box-2 {
  text-align: center;
  border: none;
  padding: 0;
  border-radius: $border-radius;


  .feature-info{
    box-shadow: $box-shadow;
    padding: 15px;
    padding-top: 60px;
    padding-bottom: 25px;
    border-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    position: relative;


    .icon-box{
      display: flex;
      flex: 0 0 80px;
      height: 80px;
      width: 80px;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: $white;
      box-shadow: $box-shadow;
      border-radius: $border-radius-lg;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;

      img {
        width: 40px;
        height: 40px;
      }

    }
    .title{
      font-size: 18px;
    }

    h5 {
      padding: 15px 0;
      font-weight: 600;
    }
  }
}

/*Feature Categories*/
.categories-section {
  z-index: 9;
  position: relative;

  .feature-categories-2 {
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    .feature-categories {
      padding: 40px 20px;
      overflow: hidden;
      background: $white;
      position: relative;
      border-bottom: 5px solid transparent;
      z-index: 2;
      &:before{
        content: '';
        background: $primary;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 0%;
        transition: all 490ms ease;
        z-index: -1;
        border-radius: 0;
      }

      &:hover {
        border-bottom: 5px solid $secondary;
        border-radius: 0;
        &:before{
          height: 100%;
          top: 0px;
        }
        .icon {
          object {
            filter: $filter-1;
            transition: all 0.3s ease-in-out;
          }

        }

        a {
          color: $white;
        }

      }

       .title{

        font-size: 18px;

       }

    }

  }

}

.feature-categories.feature-box-1{
  position: relative;
  z-index: 2;
  border-radius: $border-radius;
  padding: 0;

  .feature-img{
    border-radius: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    img{
      border-radius: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    
  }

  .feature-info{
    box-shadow: $box-shadow;
    padding: 15px;
    padding-top: 60px;
    padding-bottom: 25px;
    border-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    position: relative;
    transition: all 0.3s ease-in-out;

    .icon-box{
      display: flex;
      flex: 0 0 80px;
      height: 80px;
      width: 80px;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: $white;
      box-shadow: $box-shadow;
      border-radius: $border-radius-lg;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;

      object{
        width: 30px;
        margin-bottom: 0px;
      }

    }
    .title{
      font-size: 18px;
    }

  }

  &:hover{
    box-shadow: inherit;
    .feature-info{
      padding-top: 25px;
      .icon-box{
        top: -100px;
      }
    }
  }

}



.feature-categories.feature-box-2{
  position: relative;
  z-index: 2;
  border-radius: $border-radius;
  padding: 0;

  .feature-img{
    border-radius: $border-radius;
    img{
      border-radius: $border-radius;
    }
    
  }

  .feature-info{
    box-shadow: $box-shadow;
    padding:15px;
    border-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 100%;
    text-align: center;

    .icon-box{
      margin: 0 auto;
      margin-bottom: 8px;
      object{
        width: 40px;
        margin-bottom: 0px;
      }
    }
    .title{
      font-size: 16px;
    }
  }


  &:before{
    position:absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white,0.6);
    z-index: 1;
    transition: all 0.3s ease-in-out;
    border-radius: $border-radius;
  }
}



.feature-categories.feature-box-3{
  box-shadow: $box-shadow;
  padding: 0;
  position: relative;

  .feature-img{
    position: relative;
    border-radius: $border-radius;

    img{
      border-radius: $border-radius;
    }
    .feature-info{
      box-shadow: none;
      padding:15px;
      border-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 100%;
      text-align: center;

      .icon-box{
        margin: 0 auto;
        margin-bottom: 8px;
        object{
          width: 40px;
          margin-bottom: 0px;
          filter: invert(6%) sepia(24%) saturate(7410%) hue-rotate(220deg) brightness(92%) contrast(102%);
          transition: all 0.3s ease-in-out;
        }
      }
      .title{
        font-size: 18px;
        a{
          color: $secondary;
          transition: all 0.3s ease-in-out;
          &:hover{
            color: $yellow;
          }
        }
      }
    }
  }

  &:before{
    position: absolute;
    content: "";
    background-color: rgba($white,0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: $border-radius;
    transition: all 0.3s ease-in-out;
  }

  &:hover{
    &:before{
      background-color: rgba($primary,0.95);
    }
    .feature-img {
      .feature-info{
        .icon-box{
          object{
           filter: invert(97%) sepia(91%) saturate(7487%) hue-rotate(321deg) brightness(105%) contrast(95%);
          }
        }
        .title{
          a{
            color: $yellow;
            &:hover{
              color: $yellow;
            }
          }
        }
      }
    }
  }

}
 
.feature-box-4{
  padding:40px 50px;
  transition: all 0.3s ease-in-out;
  border-radius: $border-radius;
  .feature-icon{
    margin-bottom: 30px;
  }
  .feature-title{
    font-weight: 600;
  }

  &:hover{
    box-shadow: $box-shadow;
  }
}

.categories-section{
    .feature-categories-2{
    .owl-carousel {
      .owl-stage-outer{
       border-radius:$border-radius;

      }
    }
    .owl-stage-outer {
      border-radius: $border-radius;
     }
  }
}


/*****************************
    Responsive
*****************************/
@media (max-width:1199px) {
  .feature-box {
    padding: 25px;
  }

  .feature-categories.feature-box-3 {
    .feature-img {
      .feature-info {
        .title {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width:991px) {

  .feature-section {
    .newsletter{
      width: 100%;
    }
  }

  .feature-box.feature-box-2{
    margin-bottom: 70px;
  }

  .feature-box-4{
    padding: 30px 20px;
  }

}

@media (max-width:767px) {
  .feature-section{
    img{
      width: 60px;
    }
  }
}
@media (max-width:575px) {
  .feature-box {
    display: block;
    padding: 20px;

    .icon-box {
      display: block;
      width: 100%;
    }

  }

  .feature-categories.feature-box-3{
    .feature-img{
      .feature-info {
        .icon-box {
          object {
            width: 80px;
          }
        }
      }
    }
  }  



}
