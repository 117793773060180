/*****************************
  Portfolio
*****************************/
.filters-group {
  display: table;
  margin: 0 auto 30px;
  text-align: center;

  button {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  font-family: $font-hedding;
  font-weight: 500;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: $border-radius-md;
  transition: all 0.3s ease-in-out;
  background-color: $primary;
  color: $white;
  margin: 0 5px;
  &:hover {
    background-color: $primary;
    color: $yellow;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    color: $white;
  }

  &:active {
    box-shadow: none;
    outline: none;
    color: $white;
  }
}

  button.active {
    background-color: $primary;
    color: $yellow;
  }

}

.my-shuffle-container {
  margin-left: -15px;
  margin-right: -15px;

  .grid-item {
    width: 33.33%;
    padding: 15px;
  }

}

.my-shuffle-container {
  .portfolio {
    margin-bottom: 0;
  }

}

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.columns-1 {
  .grid-item {
    width: 100%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-2 {
  .grid-item {
    width: 50%;
    padding: 15px;
    float: left;
  }

}

.my-shuffle-container.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-4 {
  .grid-item {
    width: 25%;
    padding: 10px;
  }

}

.my-shuffle-container.columns-5 {
  .grid-item {
    width: 20%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-6 {
  .grid-item {
    width: 16.666666%;
    padding: 0px;
  }

}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
  width: 100%;
}

.portfolio-detail{
  .items-info-box{
    background-color:$white;
    padding: 50px;
    margin: 0 25px;
    margin-bottom: 20px;
    margin-top: -50px;
    z-index: 1;
    position: relative;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: flex;
    flex-wrap: wrap;
    .items-info{
      width: 33%;
      margin-bottom: 30px;
      &:last-child{
        margin-bottom:0px;
      }

      .title{
        font-size: 24px;
        font-weight: 600;
      }

      a{
        color: $body-color;
        &:hover{
          color:$primary;
        }
      }
    }
  }

  .owl-carousel{
    .portfolio{
      margin-bottom: 20px;
    }
  }
}


.portfolio-single-bg{
  position: relative;
  border-radius: 5px;
  box-shadow: $box-shadow;
  padding: 10px;
  background-color: $white;
}



// portfolio
.portfolio {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;


  .portfolio-title {
    display: block;
    color: $gray-1;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;

    &:hover {
      color: $primary;
    }

  }

  .portfolio-services {
    display: block;
    margin-bottom: 10px;

    &:hover {
      color: $gray-1;
    }

  }

  .portfolio-info {
    padding: 20px;
    width: calc(100% - 30px);
    position: absolute;
    left: 50%;
    bottom: 15px;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0%);
    background: $white;

    p {
      max-height: 66px;
      overflow: auto;
    }

  }

  &:hover {
    .portfolio-info {
      opacity: 1;
      bottom: 20px;
    }

  }

  .portfolio-lg {
    + {
      .portfolio-info {
        p {
          max-height: 340px;
          overflow: auto;
        }

      }

    }

  }

}



/* style 01 */
.portfolio-style-01 {
  position: relative;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 10px;
  background-color: $white;

  .portfolio-img{
    position: relative;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      display: block;
      z-index: 3;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $dark 100%);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    transition: all 0.4s ease-in-out;
  }
  .portfolio-info {
    background: $white;
    border-radius: $border-radius;
    opacity: 0;
    width: calc(100% - 40px);
    transition: all 0.5s ease-in-out;

    p {
      color: $white;
    }

    .portfolio-services {
     color: $secondary;

      &:hover {
        color: $yellow;
      }

    }

    .portfolio-title {
      color: $primary;

      &:hover {
        color: $yellow;
      }

    }

  }

  .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
  }

  &:hover {
    &:before {
      height: auto;
      transition: all 0.4s ease-in-out;
    }
    .portfolio-img{
      &:before {
        height: 100%;
      }
    }
    .portfolio-info{
      z-index: 9;
    }

  }

}

/*portfolio-style-02*/

.portfolio-style-02{
  .portfolio-info{
    border-radius: $border-radius;
    &:hover{
      .portfolio-title {
        &:hover {
          color: $secondary !important;
        }
      }
    }
  }
}

/*portfolio-style-03*/

.portfolio-style-03 {
  position: relative;
  overflow: inherit;
  border-radius: $border-radius;
  box-shadow: none;
  .portfolio-images {
    position: relative;
    border-radius: $border-radius;
  }
  .portfolio-img{
    border-radius: $border-radius;
    overflow: hidden;
    background-color: $white;
    padding: 10px;
    box-shadow: $box-shadow;
    img{
      transition: all 500ms ease;
    }
  }
  .portfolio-content{
    position: relative;
    width: 90%;
    z-index: 9;
    padding: 25px;
    margin:0 auto;
    margin-top: -50px;
    margin-bottom: 15px;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    .title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;
    }
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

  }

  .portfolio-info-02 {
    background: transparent;

    .popup-icon {
      width: 50px;
      height: 50px;
      flex: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-lg;
      position: absolute;
      top: 40%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      margin: 0 auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.7s ease-in-out;
      background: $white;
      z-index: 9;
    }

  }

  &:hover {
    img{
      transform: scale(1.01);
    }

    .portfolio-info-02 {
      .popup-icon {
        opacity: 1;
        top: 50%;
        visibility: visible;
      }

    }

  }

    img {
      transform: scale(1);
      transition: transform 7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

}


/*Portfolio Style Morden*/
.portfolio-style-morden {
  .portfolio {
    overflow: visible;
    padding: 10px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    .portfolio-img {
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;
      transition: transform 0.28s ease;
      z-index: 2;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: "";
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 15, 15, 0.75) 100%);
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        display: block;
        z-index: 8;
        content: "";
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $dark 100%);
        transition: all 0.5s ease-in-out;
        cursor: pointer;

      }
    }
    &:hover {
      .portfolio-img {
        &:after {
          height:100%;
        }
      }
    }
    .portfolio-info {
      background: none !important;
    }
    .portfolio-title {
      font-size: 24px;
      font-weight: 600;
      font-family: $font-hedding;
      color: $white;
      margin-bottom: 5px;

      &:hover {
        color:$yellow;
      }
    }
    .portfolio-services {
      color: $white;
      opacity: 0.7;
      &:hover {
        color:$yellow;
        opacity: 1;
      }
    }
  }
}

/*Portfolio Style Fancy*/

.portfolio-style-fancy {
  .portfolio {
    overflow: inherit;
    .portfolio-img{
      background-color: $white;
      box-shadow: $box-shadow;
      padding: 10px;
      border-radius: $border-radius;
      margin-bottom: 15px;
    }
    .portfolio-info {
      position: absolute;
      display: block;
      width: 90%;
      height: auto;
      bottom: -20px;
      left: 5%;
      padding: 20px 20px;
      border-radius: $border-radius;
      background-color: $white;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: -webkit-transform .4s ease-in-out;
      transition: -webkit-transform .4s ease-in-out;
      transition: transform .4s ease-in-out;
      .portfolio-info-wrapper {
        opacity: 0;
        -webkit-transform: translateY(-150%);
        -ms-transform: translateY(-150%);
        transform: translateY(-150%);
        -webkit-transition: opacity .4s cubic-bezier(.160,.85,.45,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);
        transition: opacity .4s cubic-bezier(.160,.85,.45,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);
        transition: transform .4s cubic-bezier(.77,0,.175,1),opacity .4s cubic-bezier(.160,.85,.45,1);
      }
      .portfolio-title {
        font-size: 20px;
        font-weight: 600;
        font-family: $font-hedding;
        margin-bottom: 5px;
        color: $primary;
        &:hover{
          color:$secondary;
        }

      }
      .portfolio-services {
        color: $body-color;
        &:hover{
          color:$primary;
        }
      }
    }
    &:hover {
      .portfolio-info {
        bottom: 20px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: -webkit-transform .50s cubic-bezier(.77,0,.175,1);
        transition: -webkit-transform .50s cubic-bezier(.77,0,.175,1);
        transition: transform .50s cubic-bezier(.77,0,.175,1);
        .portfolio-info-wrapper {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          -webkit-transition: .50s cubic-bezier(.160,.85,.45,1) .2s;
          transition: .50s cubic-bezier(.160,.85,.45,1) .2s;
        }
      }
    }
  }
}

/*portfolio-style-gallery*/
.portfolio-style-gallery{
  .portfolio.portfolio-style-03{
    background-color: $white;
    padding: 10px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    .portfolio-images{
      &:before{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        display: block;
        z-index: 8;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $dark 100%);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border-radius: $border-radius;
      }
    }
    &:hover{
      .portfolio-images{
        &:before{
          height:100%;
        }

        img{
          transform: scale(1);
        }
      }
    }
  }
}




/* Responsive */

@media (max-width:1199px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 25%;
  }

}

@media (max-width:991px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%;
  }

  .filters-group button {
    margin: 0 5px 10px;
  }

  .filters-group button:first-child {
    margin-left: 0;
  }

  .filters-group button:last-child {
    margin-right: 0;
  }

}

@media (max-width:767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 50%;
  }

  .portfolio-detail{
    .items-info-box{
      .items-info {
        width: 50%;
      }
    }
  }  

}

@media (max-width:575px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item,
  .my-shuffle-container.columns-6 .grid-item {
    width: 100%;
  }

  .my-shuffle-container {
    margin: 0;
  }

  .portfolio-detail{
    .items-info-box{
      .items-info {
        width: 100%;
      }
    }
  }  

}

