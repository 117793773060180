/*****************************
  counter
*****************************/

.counter{
  position: relative;
 span{
  font-size: 36px;
  line-height: 1;
  color: $primary;
  font-weight: bold;
  margin-bottom: 30px;
 }
  label{
    display: block;
    margin-top: 20px;
  }
  &:before{
    background: $border-color;
    position: absolute;
    width: 3px;
    height: 60px;
    content: "";
    right: 30%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.counter-section {
  .counter-box{
    background-color: $white;
    box-shadow: $box-shadow;
    padding-top:40px;
    padding-bottom: 40px;
    border-radius: $border-radius;
    position: relative;
    z-index: 9;
    margin-top: -90px;

    .counter {
      &:before{
        right:-12px;
        background:rgba($secondary,1);
        width: 2px;
      }
      .counter-number {
        .counter-text {
          display: flex;
          align-items: center;
          justify-content: center;
          h2{
            font-size: 60px;
            line-height: 70px;
            color: $secondary;
            font-weight: 600;
          }

          span {
            font-size: 60px;
            line-height: 70px;
            color: $primary;
            font-weight: 600;
            margin-bottom: 0;
          }

        }
        p{
          text-align: center;
        }
      }
    }

    .last-counter{
       &:before{
        content:inherit;
       }
    }
  }
 
}



@media (max-width:991px) {
  .counter-section {
    .counter-box{
      .counter{
        &:before{
          content:inherit;
          right: 0;
        }
      }
    } 
  }
  
}


