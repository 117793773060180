/*****************************
  Form
*****************************/
.sign-in {
  position: relative;
  .container{
    padding-top: 100px;
    padding-bottom:100px;
  }

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    background-image: url(../images/error/01.jpg);
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    position: absolute;
    content: "";
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba($secondary, 0.8);
  }

  .sign-in-bg {
    position: relative;
    z-index: 3;

    .sing-title {
      .navbar-brand{
        width: 200px;
        display: inline-block;
        margin-bottom: 50px;
      }
      h1 {
        font-size: 48px;
        font-weight: bold;
        padding-bottom: 35px;
      }

      .btn {
        background: $white;
        color: $primary;
        margin-top: 35px;

        &:after {
          background-color: $gray-2;
        }

        &:hover {
          color: $white;
        }

      }

    }

  }

  .sign-in-content {
    padding: 50px;
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    position: relative;
    z-index: 3;

    .section-title {
      h2 {
        font-size: 48px;
        font-weight: bold;
        color: $primary;
      }

    }

    .form-flat-style {
      .from-control {
        background-color: $white;
        border-color: $border-color;
      }

    }

    .login-social-media {
      form {
        .btn {
          padding: 15px 20px;
          font-size: 14px;
        }

      }

    }

    .form-flat-style {
      .form-control {
        background: $white;
      }

    }

  }

}

.sign-in.sign-up {
  position: relative;
  &:before {
    left:inherit;
    right: 0;
  }
  &:after {
    left:inherit;
    right: 0;
  }
}

.sign-up {
  .sign-in-content {
    padding: 50px;
  }

}

.datetimepicker {
  #datetimepicker-02 {
    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

  }

}

@media (max-width:1600px) {
  .sign-in {
    .sign-in-content {
      padding: 50px;
    }

  }

  .sign-up {
    .sign-in-content {
      padding: 50px;
    }

  }

}

@media (max-width:1366px) {
  .sign-in {
    .sign-in-content {
      padding: 50px;
    }

  }

}

@media (max-width:1199px) {
  .sign-in,
  .sign-in.sign-up {
    height: auto !important;
    position: relative;
    .container{
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &:before {
      left:inherit;
      right: 0;
      width: 100%;
    }
    &:after {
      left:inherit;
      right: 0;
      width: 100%;
    }
  }

}
@media (max-width:767px) {
  .sign-in-content {
    .login-social-media {
      form {
        .btn {
          padding: 15px 7px;
          font-size: 14px;
        }

      }

    }

  }

  .sign-in {
    .sign-in-content {
      .section-title {
        h2 {
          font-size: 32px;
        }

      }

      .login-social-media {
        form {
          .btn {
            padding: 13px 22px;
          }

        }

      }

    }

  }

}

@media (max-width:479px) {
  .sign-in {
    .sign-in-bg {
      .sing-title {
        h1 {
          font-size: 32px;
        }

      }

    }

    .sign-in-content {
      padding: 40px;

      .section-title {
        h2 {
          font-size: 32px;
        }

      }

      .login-social-media {
        form {
          .btn {
            padding: 13px 22px;
          }

        }

      }

    }

  }

}
